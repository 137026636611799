import React, {
  InputHTMLAttributes,
  useRef,
  forwardRef,
  useReducer,
} from "react";

import { RiFolderOpenFill } from "react-icons/ri";

import classNames from "classnames";

import LocalImageFileThumbnail, { LocalImageData } from "./LocalFileThumbnail";

type ImageSelectProps = {
  className?: string;
  inputId?: string;
  /**
   * Called with the image element (which can be used as a canvas image source)
   * once the image element has loaded. If a new image is selected, this will be
   * called with `null` first before it loads.
   */
  onChange?: (imageElement: HTMLImageElement | null) => any;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">;

type ActionType =
  | { type: "selectFile"; payload: File }
  | { type: "setPositionMode"; payload: "cover" | "contain" | "custom" }
  | { type: "resetPosition"; payload: null }
  | { type: "setSelectedImageData"; payload: LocalImageData };

type ImageSelectState = {
  file: File | null;
  naturalSize: [number, number];
  MIMEtype: string;
  name: string;
  dataURL: string | null;
  imageElement: HTMLImageElement | null;
  loading: boolean;
};

const initialState: ImageSelectState = {
  file: null,
  naturalSize: [0, 0],
  MIMEtype: "unknown",
  name: "",
  dataURL: null,
  loading: false,
  imageElement: null,
};

const reducer: (
  state: ImageSelectState,
  action: ActionType
) => ImageSelectState = (state = initialState, { type, payload }) => {
  switch (type) {
    case "setPositionMode":
      return {
        ...state,
      };
    case "selectFile":
      // Note we're using `initialState` here to reset the position when we
      // select a new image.
      return {
        file: payload as File,
        MIMEtype: "unknown",
        dataURL: null,
        loading: true,
        naturalSize: [0, 0],
        name: "",
        imageElement: null,
      };
    case "setSelectedImageData":
      return {
        ...state,
        loading: false,
        ...(payload as LocalImageData),
      };
    default:
      return state;
  }
};

const ImageSelect = forwardRef<HTMLInputElement, ImageSelectProps>(
  ({ className, inputId, name, onChange, ...rest }, ref) => {
    const innerRef = useRef<HTMLDivElement>(null);

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
      <div className={classNames("flex flex-col", className)} ref={innerRef}>
        <input
          className="hidden"
          type="file"
          ref={ref}
          id={inputId}
          name={name}
          accept="image/x-png,image/jpeg"
          multiple={false}
          {...rest}
          onChange={(event) => {
            if (event.target.files) {
              dispatch({ type: "selectFile", payload: event.target.files[0] });
              if (onChange) onChange(null);
            }
          }}
        ></input>
        {state.file ? (
          <>
            <LocalImageFileThumbnail
              file={state.file ? state.file : undefined}
              onLoad={(imageData) => {
                dispatch({ type: "setSelectedImageData", payload: imageData });
                if (onChange) onChange(imageData.imageElement);
              }}
            />
            <div className="flex flex-row justify-between text-xs p1 truncate">
              <span className="flex-shrink">{state.name}</span>
              <button
                className="flex-shrink-0 flex flex-row items-center"
                onClick={() => {
                  if (innerRef.current) {
                    (innerRef.current.children[0] as HTMLInputElement).click();
                  }
                }}
              >
                <RiFolderOpenFill />
                <span className="ml-1">Change</span>
              </button>
            </div>
          </>
        ) : (
          // No image selected.
          <>
            <span className="w-full text-xs text-center opacity-50 mb-4">
              No image selected
            </span>
            <button
              className="flex flex-row items-center button-xs self-center"
              onClick={() => {
                if (innerRef.current) {
                  (innerRef.current.children[0] as HTMLInputElement).click();
                }
              }}
            >
              <RiFolderOpenFill />
              <span className="ml-2">Select</span>
            </button>
          </>
        )}
      </div>
    );
  }
);

ImageSelect.displayName = "ImageSelect";

export default ImageSelect;
