import axios from "axios";

import { Resource } from "./generics";
import { processApiError } from "./utility-functions";

export type User = {
  account: string;
  name: string;
  email: string;
  status: {
    enabled: boolean;
    activated: boolean;
    // Azure only
    approved?: boolean;
  };
  credentials: {
    otp: {
      enabled: boolean;
    };
    local?: {};
    azure?: {
      username: string;
    };
  };
  freshchat: {
    restoreId?: string;
  };
} & Resource;

export const changePassword: (passwordChange: {
  currentPassword: string;
  newPassword: string;
}) => Promise<boolean> = async (passwordChange) => {
  try {
    await axios.put("/api/users/self/password", {
      oldPassword: passwordChange.currentPassword,
      newPassword: passwordChange.newPassword,
    });
    return true; // will throw otherwise.
  } catch (e) {
    throw processApiError(e);
  }
};

export const addFreshchatRestoreId: (restoreId: string) => Promise<User> =
  async (restoreId) => {
    try {
      const userResponse = await axios.patch<User>(
        "/api/users/self/freshchat",
        {
          restoreId,
        }
      );
      return userResponse.data;
    } catch (e) {
      throw processApiError(e);
    }
  };

type GenerateOTPSecretResponseType = {
  data: {
    url: string;
  };
};

export const generateOTPSecretUrl: () => Promise<string> = async () => {
  try {
    const otpResponse = await axios.put<GenerateOTPSecretResponseType>(
      "/api/users/self/otp/secret"
    );
    return otpResponse.data.data.url;
  } catch (e) {
    throw processApiError(e);
  }
};

export const setOTPEnabled: (data: {
  enable: boolean;
  token: string;
}) => Promise<void> = async (data) => {
  try {
    await axios.put("/api/users/self/otp/enabled", data);
  } catch (e) {
    throw processApiError(e);
  }
};

export const getUsers: () => Promise<User[]> = async () => {
  try {
    const usersResponse = await axios.get<{
      data: User[];
    }>("/api/users");
    return usersResponse.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};

export const createUser: (userDetails: {
  email: string;
  name: string;
}) => Promise<User> = async (userDetails) => {
  try {
    const createUserResponse = await axios.post<{ data: User }>(
      "api/users",
      userDetails
    );
    return createUserResponse.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};

export const updateUser: (
  id: string,
  updatedDetails: Partial<User>
) => Promise<User> = async (id, updatedUserDetails) => {
  try {
    const updateUserReponse = await axios.patch<{ data: User }>(
      `/api/user/${id}`,
      updatedUserDetails
    );
    return updateUserReponse.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};

export const resendActivationEmail: (id: string) => Promise<void> = async (
  id
) => {
  try {
    await axios.post(`/api/users/${id}/activation-email`);
  } catch (e) {
    throw processApiError(e);
  }
};

export const approveUser: (id: string) => Promise<void> = async (id) => {
  try {
    await axios.post(`/api/users/${id}/approve`);
  } catch (e) {
    throw processApiError(e);
  }
};

export const deleteUser: (id: string) => Promise<void> = async (id) => {
  try {
    await axios.delete(`/api/users/${id}`);
  } catch (e) {
    throw processApiError(e);
  }
};
