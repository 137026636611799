import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

import { ApiError } from "../api/utility-functions";

const useServerErrorToast = () => {
  const { addToast } = useToasts();
  const { t } = useTranslation("server_messages");

  const addServerErrorToast = (serverError: Error) => {
    const typedError: ApiError = serverError as ApiError;
    const key = typedError.message;
    const tid = typedError.tid;
    addToast(
      t("error", {
        context: key,
        details: tid,
      }),
      {
        appearance: "error",
        autoDismiss: false,
      }
    );
  };

  return {
    addServerErrorToast,
  };
};

export default useServerErrorToast;
