import { FC, ReactNode } from "react";

import { useHotkeys } from "react-hotkeys-hook";
import { RiCloseFill } from "react-icons/ri";

import { Backdrop } from "../api/backdrops";

import BackdropPreviewImage from "./BackdropPreviewImage";

const BackdropLightBox: FC<{
  backdrop: Backdrop | null;
  resolution: string;
  metadataSection?: ReactNode;
  onCloseRequest: () => void;
}> = ({ backdrop, onCloseRequest, resolution, metadataSection }) => {
  useHotkeys("esc", onCloseRequest);
  return (
    <div className="fixed inset-0 z-10 bg-black text-white bg-opacity-70 flex flex-row items-center">
      <button
        type="button"
        className="fixed z-20 top-4 right-4 text-4xl p-2"
        onClick={onCloseRequest}
      >
        <RiCloseFill className="text-white" />
      </button>
      <div
        className="p-4 flex max-h-full justify-center"
        style={{ flex: "3 0 0%" }}
      >
        <BackdropPreviewImage
          backdrop={backdrop}
          resolution={resolution}
          alt="Backdrop preview"
          backdropType="lockscreen"
        />
      </div>
      {metadataSection && (
        <>
          <div className="border-r border-white h-1/3 flex-grow-0"></div>
          <div className="flex-1 p-4">{metadataSection}</div>
        </>
      )}
    </div>
  );
};

export default BackdropLightBox;
