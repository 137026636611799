import { FC } from "react";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import useSession from "./Hooks/useSession";

import LoadingSpinner from "./Components/LoadingSpinner";
import TopMenu from "./Components/TopMenu";
import OTPChallengeForm from "./Forms/OTPChallengeForm";
import AppsPage from "./Pages/AppsPage";
import BackdropsPage from "./Pages/BackdropsPage";
import Calendar from "./Pages/Calendar";
import ChangePasswordModal from "./Pages/ChangePasswordModal";
import EditBackdropPage from "./Pages/EditBackdropPage";
import ErrorPage from "./Pages/ErrorPage";
import GroupsPage from "./Pages/GroupsPage";
import LoginPage from "./Pages/LoginPage";
import Logout from "./Pages/Logout";
import ResetPasswordPage from "./Pages/ResetPasswordPage";
import SignupPage from "./Pages/SignupPage";
import UsersPage from "./Pages/UsersPage";

const App: FC<{}> = () => {
  const { loading, session } = useSession(true);

  const needsOTP = session && session.otp.required && !session.otp.verified;

  if (needsOTP) {
    return <OTPChallengeForm />;
  }

  return loading ? (
    <LoadingSpinner className="w-full h-full" />
  ) : session ? (
    <>
      {(session.flags.changePassword || session.flags.resetPassword) && (
        <ChangePasswordModal isMandatory={true} />
      )}
      <Router>
        {/* This route matches everything except paths listed */}
        <Route
          render={({ location }) => {
            if (
              !["/backdrops/editor"].find((path) => {
                return location.pathname.startsWith(path);
              })
            ) {
              return <TopMenu />;
            } else {
              return <></>;
            }
          }}
        ></Route>
        <Switch>
          <Route path="/calendar" exact>
            <Calendar />
          </Route>
          <Route path="/logout" exact>
            <Logout />
          </Route>
          <Route path="/groups" exact>
            <GroupsPage />
          </Route>
          <Route path="/backdrops" exact>
            <BackdropsPage />
          </Route>
          <Route path="/users" exact>
            <UsersPage />
          </Route>
          <Route path="/apps" exact>
            <AppsPage />
          </Route>
          <Route path="/backdrops/editor/:id?">
            <EditBackdropPage />
          </Route>
          <Route path="/error">
            <ErrorPage />
          </Route>
          <Route path={["/", "/login"]} exact>
            <Redirect to="/groups" />
          </Route>
          <Route>Matched no routes</Route>
        </Switch>
      </Router>
    </>
  ) : (
    <Router>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/reset-password">
          <ResetPasswordPage />
        </Route>
        <Route path="/signup">
          <SignupPage />
        </Route>
        <Route path="/error">
          <ErrorPage />
        </Route>
        <Route>
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
