import axios from "axios";

import { processApiError } from "./utility-functions";

export const selfServeAccount: (payload: {
  name: string;
  user: { name: string; email: string };
  recaptcha: string;
}) => Promise<void> = async (payload) => {
  try {
    await axios.post("/api/accounts/self-serve", payload);
  } catch (e) {
    throw processApiError(e);
  }
};

export const getClientSecret = async () => {
  try {
    const secretResponse = await axios.get<{ data: { secret: string } }>(
      "/api/accounts/self/client-secret"
    );
    return secretResponse.data.data.secret;
  } catch (e) {
    throw processApiError(e);
  }
};
