import { FC, useState } from "react";

import Modal from "react-modal";

import { Installer, uploadFile } from "../api/installers";

import Button, { ButtonTypes } from "./Button";
import FileSelect from "./FileSelect";
import { centeredAndSizedByContent } from "./modalCustomStyles";

export type SemVer = {
  major: number;
  minor: number;
  patch: number;
};

const UploadAppsModal: FC<{ onCancel: () => void; onSuccess: () => void }> = ({
  onCancel,
  onSuccess,
}) => {
  const [files, setFiles] = useState<File[] | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [results, setResults] = useState<Installer[] | null>(null);

  const doUpload = async (files: File[]) => {
    setLoading(true);
    const uploadPromises = files.map((file, i) => {
      return uploadFile(file, (progress) => {
        setUploadProgress((current) =>
          current.map((oldProgress, index) => {
            return index === i ? progress : oldProgress;
          })
        );
      });
    });

    try {
      const results = await Promise.all(uploadPromises);
      setResults(results);
      setComplete(true);
    } catch (e) {
      // TODO: toast probs.
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onCancel}
      style={centeredAndSizedByContent}
    >
      <form className="w-96 flex flex-col">
        <h2 className="font-bold text-lg mb-2">Upload installers</h2>
        {!complete ? (
          <FileSelect
            uploadProgress={uploadProgress}
            inputId="installerFiles"
            onFilesSelected={(files) => {
              setFiles(files);
              if (files) {
                setUploadProgress(Array(files.length).fill(0));
              } else {
                setUploadProgress([]);
              }
            }}
            name="installerFiles"
            accept=".msi"
            multiple={true}
          />
        ) : (
          <div>
            Successfully uploaded {results!.length} installers.
            <ul>
              {results!.map((installer) => (
                <li>{`${installer.platform}/${installer.arch} - v${installer.semVer.major}.${installer.semVer.minor}.${installer.semVer.patch}`}</li>
              ))}
            </ul>
          </div>
        )}
        <div className="flex flex-row justify-end mt-4">
          <Button
            className="mr-2"
            type="button"
            onClick={onCancel}
            disabled={complete}
          >
            Cancel
          </Button>
          {!complete ? (
            <Button
              buttonType={ButtonTypes.positive}
              type="button"
              disabled={!files?.length}
              loading={loading}
              onClick={() => {
                doUpload(files!);
              }}
            >
              Upload
            </Button>
          ) : (
            <Button
              buttonType={ButtonTypes.positive}
              type="button"
              onClick={() => {
                onSuccess();
              }}
            >
              Done
            </Button>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default UploadAppsModal;
