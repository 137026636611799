import { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { AiOutlineUserAdd } from "react-icons/ai";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";
import Modal from "react-modal";
import { useToasts } from "react-toast-notifications";

import classNames from "classnames";

import {
  approveUser,
  deleteUser,
  getUsers,
  resendActivationEmail,
  updateUser,
  User,
} from "../api/users";

import useServerErrorToast from "../Hooks/useServerErrorToast";
import useSession from "../Hooks/useSession";

import Badge from "../Components/Badge";
import Button, { ButtonTypes } from "../Components/Button";
import { CalloutTypes } from "../Components/Callout";
import ConfirmationModal from "../Components/ConfirmationModal";
import DropDownMenu from "../Components/DropdownMenu";
import EventLogModal from "../Components/EventLogModal";
import LoadingSpinner from "../Components/LoadingSpinner";
import UserAvatar from "../Components/UserAvatar";
import { centeredAndSizedByContent } from "../Components/modalCustomStyles";
import CreateEditUserForm from "../Forms/CreateEditUserForm";

type UserAction =
  | "approve"
  | "deactivate"
  | "activate"
  | "delete"
  | "resendActivationEmail";

const UsersPage: FC<{}> = () => {
  const [users, setUsers] = useState<User[] | null>(null);
  // False not to show, null to show create user.
  const [editingUserModal, setEditingUserModal] = useState<User | null | false>(
    false
  );
  const [eventLogModal, setEventLogModal] = useState<User | null>(null);

  const { session } = useSession();

  const [confirmAction, setConfirmAction] = useState<{
    action: UserAction;
    user: User;
  } | null>(null);

  const { t } = useTranslation([
    "common_user",
    "common_objects",
    "common_actions",
    "common_toasts",
    "page_users",
    "server_messages",
  ]);

  useEffect(() => {
    getUsers().then((users) => setUsers(users));
  }, []);

  const { addToast } = useToasts();
  const { addServerErrorToast } = useServerErrorToast();

  return (
    <>
      {users === null ? (
        <LoadingSpinner />
      ) : (
        <>
          {confirmAction !== null && (
            <ConfirmationModal
              isOpen={true}
              buttonType={
                confirmAction.action === "delete"
                  ? ButtonTypes.destructive
                  : ButtonTypes.positive
              }
              onCancel={() => setConfirmAction(null)}
              onConfirm={async () => {
                try {
                  switch (confirmAction.action) {
                    case "activate":
                      await updateUser(confirmAction.user._id, {
                        status: {
                          ...confirmAction.user.status,
                          enabled: true,
                        },
                      });
                      break;
                    case "deactivate":
                      await updateUser(confirmAction.user._id, {
                        status: {
                          ...confirmAction.user.status,
                          enabled: false,
                        },
                      });
                      break;
                    case "approve":
                      await approveUser(confirmAction.user._id);
                      addToast(
                        t("page_users:userApprovalConfirmationToast", {
                          name: confirmAction.user.name,
                        }),
                        { autoDismiss: true, appearance: "success" }
                      );
                      break;
                    case "resendActivationEmail":
                      await resendActivationEmail(confirmAction.user._id);
                      addToast(
                        t("page_users:activationEmailConfirmationToast", {
                          name: confirmAction.user.name,
                          email: confirmAction.user.email,
                        }),
                        {
                          autoDismiss: true,
                          appearance: "success",
                        }
                      );
                      break;
                    case "delete":
                      await deleteUser(confirmAction.user._id);
                      setUsers((userList) =>
                        userList!.filter(
                          (u) => u._id !== confirmAction.user._id
                        )
                      );
                      addToast(
                        t("page_users:deleteConfirmationToast", {
                          userName: confirmAction.user.name,
                        }),
                        {
                          autoDismiss: true,
                          appearance: "success",
                        }
                      );
                      break;
                  }
                } catch (e) {
                  addServerErrorToast(e);
                }
                // await promise here
                setConfirmAction(null);
              }}
              // t("page_users:confirmActionModalTitle", {context: "delete"})
              // t("page_users:confirmActionModalTitle", {context: "deactivate"})
              // t("page_users:confirmActionModalTitle", {context: "activate"})
              // t("page_users:confirmActionModalTitle", {context: "resendActivationEmail"})
              heading={t("page_users:confirmActionModalTitle", {
                context: confirmAction.action,
                name: confirmAction.user.name,
              })}
              // t("page_users:confirmActionModalMessage", {context: "delete"})
              // t("page_users:confirmActionModalMessage", {context: "deactivate"})
              // t("page_users:confirmActionModalMessage", {context: "activate"})
              // t("page_users:confirmActionModalMessage", {context: "resendActivationEmail"})
              message={t("page_users:confirmActionModalMessage", {
                context: confirmAction.action,
                name: confirmAction.user.name,
              })}
              isIrreversible={confirmAction.action === "delete"}
            />
          )}
          {editingUserModal !== false && (
            <Modal
              isOpen={true}
              style={centeredAndSizedByContent}
              onRequestClose={() => {
                setEditingUserModal(false);
              }}
            >
              <CreateEditUserForm
                user={editingUserModal}
                onCancel={() => setEditingUserModal(false)}
                onSuccess={(user) => {
                  if (editingUserModal) {
                    // successful edit
                    setUsers((previous) =>
                      previous!.map((oldUser) => {
                        if (oldUser._id === user._id) return user;
                        return oldUser;
                      })
                    );
                    addToast(
                      t("common_toasts:successfulEdit", {
                        name: user.name,
                        type: t("common_objects:user"),
                      }),
                      { appearance: "success", autoDismiss: true }
                    );
                  } else {
                    // successful create
                    setUsers((previous) => [...previous!, user]);
                    addToast(
                      t("common_toasts:successfulCreation", {
                        context: "userAccount",
                        name: user.name,
                      }),
                      { appearance: "success", autoDismiss: true }
                    );
                  }
                  setEditingUserModal(false);
                }}
              />
            </Modal>
          )}
          {eventLogModal && (
            <EventLogModal
              id={eventLogModal._id}
              type="document"
              onRequestClose={() => {
                setEventLogModal(null);
              }}
            />
          )}
          <div
            className="p-6 grid items-center gap-x-4"
            style={{ gridTemplateColumns: "repeat(4, auto)" }}
          >
            <div className="contents font-bold">
              <div className="pl-12">{t("common_objects:user")}</div>
              <div>{t("common_user:email")}</div>
              <div className="justify-self-center">
                {t("page_users:2FAStatus")}
              </div>
              <div>&nbsp;</div>
              <div className="col-span-full border-b-4 border-gray-200 dark:border-gray-600 mt-2 mb-4"></div>
            </div>
            {users.map((user) => (
              <div key={user._id} className="contents">
                <div className="flex flex-row items-center">
                  <UserAvatar
                    email={user.email}
                    className="mr-2"
                    id={user._id}
                    showMicrosoftLogo={!!user.credentials.azure}
                  />
                  <div
                    className={classNames(
                      "flex flex-col gap-0 items-start",
                      "lg:flex-row lg:gap-2 lg:items-center"
                    )}
                  >
                    <span>{user.name}</span>
                    {!!user.credentials.azure && !user.status.approved && (
                      <Badge type={CalloutTypes.warning}>
                        {t("page_users:unapproved")}
                      </Badge>
                    )}
                  </div>
                </div>
                <div>{user.email}</div>
                <div className="justify-self-center">
                  {user.credentials.otp.enabled ? (
                    <RiCheckFill
                      className="text-green-700 text-lg"
                      aria-label={t("common_actions:enable", {
                        context: "currentState",
                      })}
                    />
                  ) : (
                    <RiCloseFill
                      className="text-red-700 text-lg"
                      aria-label={t("common_actions:disable", {
                        context: "currentState",
                      })}
                    />
                  )}
                </div>
                <div className="justify-self-end">
                  <DropDownMenu
                    menuItems={[
                      {
                        text: t("page_users:editUser"),
                        onClick: () => {
                          setEditingUserModal(user);
                        },
                      },
                      {
                        text: "Activate",
                        onClick: () => {
                          setConfirmAction({ action: "activate", user });
                        },
                        show:
                          user._id !== session!.user._id &&
                          !user.status.enabled,
                      },
                      {
                        text: "Deactivate",
                        onClick: () => {
                          setConfirmAction({ action: "deactivate", user });
                        },
                        show:
                          user._id !== session!.user._id && user.status.enabled,
                      },
                      {
                        text: t("common_actions:viewEventLogs"),
                        onClick: () => {
                          setEventLogModal(user);
                        },
                      },
                      {
                        text: t("common_actions:approve"),
                        onClick: () => {
                          setConfirmAction({ action: "approve", user });
                        },
                        show: !!user.credentials.azure && !user.status.approved,
                      },
                      {
                        text: t("page_users:resendActivationEmail"),
                        show: user.status.activated !== true,
                        onClick: () => {
                          setConfirmAction({
                            action: "resendActivationEmail",
                            user,
                          });
                        },
                      },
                      {
                        text: t("page_users:deleteUser"),
                        onClick: () => {
                          setConfirmAction({ action: "delete", user });
                        },
                        show: user._id !== session!.user._id,
                      },
                    ]}
                  />
                </div>
                <div className="col-span-full border-b border-gray-200 dark:border-gray-600 mt-2 mb-2"></div>
              </div>
            ))}
            <div className="col-span-full p-2 justify-self-center">
              <Button
                buttonType={ButtonTypes.positive}
                onClick={() => setEditingUserModal(null)}
              >
                <div className="flex flex-row items-center">
                  <AiOutlineUserAdd className="text-lg mr-1" />{" "}
                  <span>{t("page_users:addUser")}</span>
                </div>
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UsersPage;
