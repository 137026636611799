// Ref: https://www.robinwieruch.de/react-internationalization#react-internationalization-which-library-should-i-use
// fallback http load
import { initReactI18next } from "react-i18next";

import axios, { AxiosResponse } from "axios";
import i18n from "i18next";
import Backend from "i18next-chained-backend";
// primary use cache
import HttpApi from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";

i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV === "development",

    lng: "en",
    fallbackLng: "en",
    whitelist: ["en"],

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    // This is actually the default but left here in case we change it.
    backend: {
      backends: [
        LocalStorageBackend, // primary
        HttpApi, // fallback
      ],
      backendOptions: [
        // Local storage options
        {
          prefix: "pxnBackdrops_i18next_res_",
          // 4 weeks expiry
          expirationTime: 28 * 24 * 60 * 60 * 1000,
          // Version applied to all languages, can be overriden using versions
          defaultVersion: "",
          // lang specific versions
          versions: {},
          // Alternatively can be window.sessionstorage.
          store: window.localStorage,
        },
        // HTTP options
        {
          // No need to parse as axios does it for us.
          parse: (d: any) => d,
          loadPath: "/locales/{{lng}}/{{ns}}.json",
          request: async (
            options: any,
            url: string,
            payload: any,
            cb: (err: Error | null, res?: AxiosResponse) => void
          ) => {
            try {
              const response = await axios.get(url);
              cb(null, response);
            } catch (err) {
              cb(err);
            }
          },
        },
      ],
    },

    react: {
      // TODO: Review
      useSuspense: true,
    },
  });

export default i18n;
