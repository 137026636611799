import { FC } from "react";

import { IconType } from "react-icons";

import classNames from "classnames";

import { CalloutTypes, defaultIcons, bgColourClasses } from "./Callout";

const Badge: FC<{
  type: CalloutTypes;
  icon?: IconType;
}> = ({ type = CalloutTypes.info, icon, children }) => {
  const IconOrDefault: IconType = icon || defaultIcons[type];
  return (
    <div
      className={classNames(
        "flex flex-row gap-0.5 items-center",
        "px-1 py-0.5",
        "text-xs uppercase font-bold text-white",
        "rounded-sm",
        bgColourClasses[type]
      )}
    >
      {IconOrDefault({})}
      <span>{children}</span>
    </div>
  );
};

export default Badge;
