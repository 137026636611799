import React, { FC } from "react";

export type PositionMode = "cover" | "contain" | "custom";

type ImagePositionSelectProps = {
  name: string;
  mode?: PositionMode;
  onModeChange?: (newMode: PositionMode) => any;
};

const ImagePositionSelect: FC<ImagePositionSelectProps> = ({
  name,
  mode,
  onModeChange,
}) => {
  return (
    <div>
      {/* Cover contain etc. */}
      <div className="flex flex-row items-center justify-between mt-2">
        {["cover", "contain", "custom"].map((position) => {
          return (
            <div key={`${name}-${position}`}>
              <input
                name={name}
                id={`${name}-${position}`}
                value={position}
                checked={mode === position}
                onChange={(e) => {
                  const target = e.target as HTMLInputElement;
                  onModeChange && onModeChange(target.value as PositionMode);
                }}
                type="radio"
              />
              <label htmlFor={`${name}-${position}`} className="ml-1 text-xs">
                {position}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImagePositionSelect;
