import { Backdrop } from "./api/backdrops";
import { uploadImage } from "./api/images";

import { BackdropEditorState } from "./backdropEditorReducer";
import drawBackdrop from "./drawBackdrop";

const exportBackdrops = (
  backdropId: string,
  backdropsData: BackdropEditorState["backdrops"],
  objectLibrary: BackdropEditorState["objectLibrary"],
  onProgress?: (resolution: string, progress: number) => void
): Promise<Backdrop>[] => {
  const promises: Promise<Backdrop>[] = [];
  backdropsData.forEach((ls) => {
    let [stringResX, stringResY] = ls.resolution.split("x");
    let resX = parseInt(stringResX);
    let resY = parseInt(stringResY);
    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");
    canvas.width = resX;
    canvas.height = resY;
    drawBackdrop(
      context!,
      {
        backgroundColor: ls.backgroundColor || "#000",
        objectLibrary,
        objectMetas: ls.objects,
        resolution: [resX, resY],
      },
      {
        includeDecorators: false,
        zoom: 1,
      }
    );
    promises.push(
      new Promise((resolve, reject) => {
        canvas.toBlob(
          async (blob) => {
            try {
              const uploadResult = await uploadImage(
                backdropId,
                ls.resolution,
                blob!,
                (progress) => {
                  onProgress && onProgress(ls.resolution, progress);
                }
              );
              resolve(uploadResult);
            } catch (e) {
              reject(e);
            }
          },
          "image/jpeg",
          0.8
        );
      })
    );
  });
  return promises;
};

export default exportBackdrops;
