import { Reducer } from "react";

import { ColorResult } from "react-color";

import { BackdropObjectMeta } from "./BackdropMockup";
import {
  calculateConstrainedImagePosition,
  CanvasImage,
  CanvasObject,
  CanvasObjectTypes,
  HandlePositions,
  ObjectPosition,
} from "./CanvasObject";
import { PositionMode } from "./ImagePositionSelect";
import {
  getDiagonalComponentRatio,
  resolutionStringToXYArray,
} from "./utility";

export enum BackdropEditorActionTypes {
  ADD_IMAGE,
  REORDER_IMAGE,
  SELECT_OBJECT,
  REMOVE_OBJECT,
  SET_IMAGE_POSITION_MODE,
  SET_IMAGE_DATA,
  SELECT_RESOLUTION,
  SET_ZOOM,
  SET_ZOOM_RANGE,
  ZOOM,
  SET_PAN,
  PAN,
  TRANSLATE_OBJECT,
  SCALE_OBJECT,
  SET_BACKGROUND_COLOR,
  SET_RESOLUTIONS,
  MARK_CHANGES_SAVED,
}

export type BackdropEditorActions =
  | { type: BackdropEditorActionTypes.ADD_IMAGE; payload: null }
  | {
      type: BackdropEditorActionTypes.REORDER_IMAGE;
      payload: {
        id: string;
        newPosition: number;
        oldPosition: number;
      };
    }
  | { type: BackdropEditorActionTypes.SELECT_OBJECT; payload: string | null }
  | {
      type: BackdropEditorActionTypes.REMOVE_OBJECT;
      payload: string /* image id */;
    }
  | {
      type: BackdropEditorActionTypes.SET_IMAGE_POSITION_MODE;
      payload: { id: string; mode: PositionMode };
    }
  | {
      type: BackdropEditorActionTypes.SET_IMAGE_DATA;
      payload: { id: string; imageElement: HTMLImageElement | null };
    }
  | {
      type: BackdropEditorActionTypes.SELECT_RESOLUTION;
      payload: string;
    }
  | { type: BackdropEditorActionTypes.SET_ZOOM; payload: number }
  | {
      type: BackdropEditorActionTypes.SET_ZOOM_RANGE;
      payload: { min: number; max: number };
    }
  | { type: BackdropEditorActionTypes.ZOOM; payload: number }
  | { type: BackdropEditorActionTypes.SET_PAN; payload: [number, number] }
  | { type: BackdropEditorActionTypes.PAN; payload: [number, number] }
  | {
      type: BackdropEditorActionTypes.TRANSLATE_OBJECT;
      payload: { id: string; amount: { dx: number; dy: number } };
    }
  | {
      type: BackdropEditorActionTypes.SCALE_OBJECT;
      payload: {
        id: string;
        amount: { dx: number; dy: number };
        details: {
          handlePosition: HandlePositions | null;
          constrainAspectRatio: boolean;
        };
      };
    }
  | {
      type: BackdropEditorActionTypes.SET_BACKGROUND_COLOR;
      payload: ColorResult;
    }
  | {
      type: BackdropEditorActionTypes.SET_RESOLUTIONS;
      payload: string[];
    }
  | {
      type: BackdropEditorActionTypes.MARK_CHANGES_SAVED;
      payload: null;
    };

type ObjectMetaLib = {
  [id: string]: BackdropObjectMeta & { positionMode: PositionMode };
};

export type BackdropEditorState = {
  backdrops: {
    resolution: string;
    backgroundColor: string | null;
    objects: ObjectMetaLib;
  }[];
  selectedResolution: string;
  objectLibrary: CanvasObject[];
  preview: {
    zoom: {
      current: number | null;
      min: number;
      max: number;
    };
    pan: [number, number];
  };
  hasChangesSinceSave: boolean;
};

export const backdropEditorInitialState: BackdropEditorState = {
  backdrops: [],
  selectedResolution: "640x480",
  objectLibrary: [],
  preview: {
    zoom: {
      current: null,
      min: 0.5,
      max: 2,
    },
    pan: [0, 0],
  },
  hasChangesSinceSave: false,
};

const initialObjectPosition: Omit<ObjectPosition, "zIndex"> = {
  origin: [0, 0],
  scale: [0, 0],
};

const initialPositionMode: PositionMode = "cover";

let nextId = 0;
const getNextId = () => {
  return `${nextId++}`;
};

const getNextAvailableZIndex: (objects: ObjectMetaLib) => number = (
  objects
) => {
  return (
    Object.values(objects).reduce((highestZIndex, currentObjectMeta) => {
      if (currentObjectMeta.position.zIndex > highestZIndex)
        return currentObjectMeta.position.zIndex;
      return highestZIndex;
    }, 0) + 1
  );
};

export const backdropEditorReducer: Reducer<
  BackdropEditorState,
  BackdropEditorActions
> = (state, action) => {
  switch (action.type) {
    case BackdropEditorActionTypes.SET_RESOLUTIONS: {
      const resolutions = action.payload;
      const existingBackdrops = state.backdrops;
      const newBackdrops = existingBackdrops.filter((backdrop) =>
        resolutions.includes(backdrop.resolution)
      );

      resolutions.forEach((res) => {
        if (newBackdrops.find((bd) => bd.resolution === res) == null) {
          newBackdrops.push({
            resolution: res,
            backgroundColor: "#000",
            objects: {},
          });
        }
      });

      const newState = {
        ...state,
        backdrops: newBackdrops,
      };

      const currentlySelected = state.selectedResolution;
      if (resolutions.length && !resolutions.includes(currentlySelected)) {
        newState.selectedResolution = resolutions[0];
      }

      return newState;
    }

    case BackdropEditorActionTypes.SELECT_RESOLUTION: {
      let resolution = action.payload;
      return {
        ...state,
        selectedResolution: resolution,
        preview: {
          ...state.preview,
          zoom: { ...state.preview.zoom, current: 0 },
          pan: [0, 0],
        },
      };
    }
    case BackdropEditorActionTypes.ADD_IMAGE: {
      let id = getNextId();
      let newImage: CanvasImage = {
        id: id,
        imageElement: null,
        type: CanvasObjectTypes.Image,
      };
      return {
        ...state,
        backdrops: [
          ...state.backdrops.map((existingBackdropState) => ({
            ...existingBackdropState,
            objects: {
              ...existingBackdropState.objects,
              [id]: {
                id,
                position: {
                  ...initialObjectPosition,
                  zIndex: getNextAvailableZIndex(existingBackdropState.objects),
                },
                selected: false,
                type: CanvasObjectTypes.Image,
                positionMode: initialPositionMode,
              },
            },
          })),
        ],
        objectLibrary: [...state.objectLibrary, newImage],
        hasChangesSinceSave: true,
      };
    }
    case BackdropEditorActionTypes.REORDER_IMAGE: {
      let { id, newPosition, oldPosition } = action.payload;

      return {
        ...state,
        backdrops: state.backdrops.map((ls) => {
          if (ls.resolution !== state.selectedResolution) return ls;
          return {
            ...ls,
            objects: Object.values(ls.objects).reduce(
              (newObjects: ObjectMetaLib, obj) => {
                let oldZIndex = obj.position.zIndex;
                let newZIndex: number = oldZIndex;
                if (obj.id === id) newZIndex = newPosition;
                else if (newPosition > oldPosition) {
                  if (oldZIndex > oldPosition && oldZIndex <= newPosition)
                    newZIndex = oldZIndex - 1;
                } else if (newPosition < oldPosition) {
                  if (oldZIndex < oldPosition && oldZIndex >= newPosition) {
                    newZIndex = oldZIndex + 1;
                  }
                }
                newObjects[obj.id] = {
                  ...obj,
                  position: {
                    ...obj.position,
                    zIndex: newZIndex,
                  },
                };
                return newObjects;
              },
              {}
            ),
          };
        }),
        hasChangesSinceSave: true,
      };
    }
    case BackdropEditorActionTypes.SELECT_OBJECT: {
      let objectId = action.payload;
      if (objectId) {
        // Exit early if we're already selected on this.
        if (
          state.backdrops.find(
            (ls) => ls.resolution === state.selectedResolution
          )!.objects[objectId].selected
        )
          return state;
      }
      return {
        ...state,
        backdrops: state.backdrops.map((ls) => {
          if (ls.resolution !== state.selectedResolution) return ls;
          return {
            ...ls,
            objects: {
              ...Object.keys(ls.objects).reduce(
                (newObjectsState: typeof state.backdrops[0]["objects"], id) => {
                  if (id === objectId) {
                    newObjectsState[id] = {
                      ...ls.objects[id],
                      selected: true,
                    };
                  } else if (ls.objects[id].selected) {
                    newObjectsState[id] = {
                      ...ls.objects[id],
                      selected: false,
                    };
                  } else {
                    newObjectsState[id] = ls.objects[id];
                  }
                  return newObjectsState;
                },
                {}
              ),
            },
          };
        }),
      };
    }
    case BackdropEditorActionTypes.REMOVE_OBJECT: {
      // As well as removing from the library, we also delete any reference
      // to this object from the backdrops object data.
      return {
        ...state,
        backdrops: state.backdrops.map((ls) => ({
          ...ls,
          objects: Object.keys(ls.objects).reduce(
            (newObjectState: typeof ls.objects, objectId) => {
              if (objectId !== action.payload) {
                newObjectState[objectId] = ls.objects[objectId];
              }
              return newObjectState;
            },
            {}
          ),
        })),
        objectLibrary: state.objectLibrary.filter(
          (object) => object.id !== action.payload
        ),
        hasChangesSinceSave: true,
      };
    }
    case BackdropEditorActionTypes.SET_IMAGE_POSITION_MODE: {
      let { id, mode } = action.payload;
      let selectedBackdrop = state.backdrops.find(
        (ls) => ls.resolution === state.selectedResolution
      );
      if (!selectedBackdrop) return state;
      let newPosition = selectedBackdrop.objects[id].position;
      let image: CanvasImage = state.objectLibrary.find(
        (o) => o.id === id
      ) as CanvasImage;

      // Can't do this if the image hasn't laoded or hasn't been selected.
      if (image.imageElement && mode !== "custom") {
        newPosition = {
          ...newPosition,
          ...calculateConstrainedImagePosition(
            image,
            mode,
            resolutionStringToXYArray(selectedBackdrop.resolution)
          ),
        };
      }
      return {
        ...state,
        backdrops: state.backdrops.map((ls) => {
          if (ls.resolution !== state.selectedResolution) return ls;
          return {
            ...ls,
            objects: {
              ...ls.objects,
              [id]: {
                ...ls.objects[id],
                position: newPosition,
                selected: ls.objects[id].selected,
                positionMode: mode,
              },
            },
          };
        }),
        hasChangesSinceSave: true,
      };
    }
    case BackdropEditorActionTypes.SET_IMAGE_DATA: {
      let { id, imageElement } = action.payload;
      let updatedImage: CanvasImage | null = null;
      let newObjectsState = state.objectLibrary.map((object, i) => {
        if (object.id !== id) return object;
        updatedImage = {
          ...(object as CanvasImage),
          imageElement,
        };
        return updatedImage;
      });
      if (!updatedImage) return state;

      return {
        ...state,
        backdrops: state.backdrops.map((ls) => {
          // If we have just added the image element then we should
          // conver/contain as appropriate.
          let position: ObjectPosition;
          // Note existence of imageElement doesn't necessarily mean
          // new image - could be replacing existing, hence position check
          // If it's already been repositioned we should swap in place.
          if (imageElement && ls.objects[id].positionMode !== "custom") {
            // Set the position to cover/contain
            position = {
              ...calculateConstrainedImagePosition(
                updatedImage!,
                ls.objects[id].positionMode,
                resolutionStringToXYArray(ls.resolution)
              ),
              zIndex: ls.objects[id].position.zIndex,
            };
          } else {
            position = {
              ...initialObjectPosition,
              ...ls.objects[id].position,
            };
          }
          return {
            ...ls,
            objects: {
              ...ls.objects,
              [id]: {
                ...ls.objects[id],
                position,
              },
            },
          };
        }),
        objectLibrary: newObjectsState,
      };
    }

    case BackdropEditorActionTypes.SET_ZOOM:
      return {
        ...state,
        preview: {
          ...state.preview,
          zoom: {
            ...state.preview.zoom,
            current: Math.max(
              state.preview.zoom.min,
              Math.min(state.preview.zoom.max, action.payload)
            ),
          },
        },
      };
    case BackdropEditorActionTypes.SET_ZOOM_RANGE:
      let { min, max } = action.payload;
      return {
        ...state,
        preview: {
          ...state.preview,
          zoom: {
            current: state.preview.zoom.current
              ? Math.max(min, Math.min(max, state.preview.zoom.current))
              : null,
            min,
            max,
          },
        },
      };
    case BackdropEditorActionTypes.ZOOM:
      // Relative zoom
      return {
        ...state,
        preview: {
          ...state.preview,
          zoom: {
            ...state.preview.zoom,
            current: Math.max(
              state.preview.zoom.min,
              Math.min(
                state.preview.zoom.max,
                (state.preview.zoom.current || 1) * (1 + action.payload)
              )
            ),
          },
        },
      };
    case BackdropEditorActionTypes.SET_PAN: {
      let [x, y] = action.payload;
      return {
        ...state,
        preview: { ...state.preview, pan: [x, y] },
      };
    }
    case BackdropEditorActionTypes.PAN: {
      let [x, y] = action.payload;
      return {
        ...state,
        preview: {
          ...state.preview,
          pan: [
            state.preview.pan[0] + x / (state.preview.zoom.current || 1),
            state.preview.pan[1] + y / (state.preview.zoom.current || 1),
          ],
        },
      };
    }
    case BackdropEditorActionTypes.TRANSLATE_OBJECT: {
      let { id, amount } = action.payload;
      return {
        ...state,
        backdrops: state.backdrops.map((ls) => {
          if (ls.resolution !== state.selectedResolution) return ls;
          return {
            ...ls,
            objects: {
              ...ls.objects,
              [id]: {
                ...ls.objects[id],
                positionMode: "custom",
                position: {
                  ...ls.objects[id].position,
                  origin: [
                    ls.objects[id].position.origin[0] + amount.dx,
                    ls.objects[id].position.origin[1] + amount.dy,
                  ],
                },
              },
            },
          };
        }),
        hasChangesSinceSave: true,
      };
    }
    case BackdropEditorActionTypes.SCALE_OBJECT: {
      let { id, amount, details } = action.payload;
      let object = state.objectLibrary.find(
        (obj) => obj.id === id
      ) as CanvasImage;
      return {
        ...state,
        backdrops: state.backdrops.map((ls) => {
          if (ls.resolution !== state.selectedResolution) return ls;
          let oldObjMeta = ls.objects[id];

          let newScale: [number, number] = [...oldObjMeta.position.scale];
          let newOrigin: [number, number] = [...oldObjMeta.position.origin];
          let currentSize = {
            width:
              oldObjMeta.position.scale[0] * object.imageElement!.naturalWidth,
            height:
              oldObjMeta.position.scale[1] * object.imageElement!.naturalHeight,
          };
          let scaleChange: number = 0;
          let sizeChange = {
            height: 0,
            width: 0,
          };

          if (details.constrainAspectRatio) {
            ({ scaleChange, sizeChange } = getDiagonalComponentRatio(
              amount,
              currentSize,
              details.handlePosition!
            ));
          }
          switch (details.handlePosition) {
            case HandlePositions.TOP_LEFT:
              if (!details.constrainAspectRatio) {
                newOrigin[0] += amount.dx;
                newScale[0] =
                  -1 * (amount.dx / object.imageElement!.naturalWidth) +
                  newScale[0];
                newOrigin[1] += amount.dy;
                newScale[1] =
                  -1 * (amount.dy / object.imageElement!.naturalHeight) +
                  newScale[1];
              } else {
                newOrigin[0] -= sizeChange.width;
                newOrigin[1] -= sizeChange.height;
                newScale = [
                  (1 + scaleChange) * newScale[0],
                  (1 + scaleChange) * newScale[1],
                ];
              }
              break;
            case HandlePositions.TOP_MID:
              newOrigin[1] += amount.dy;
              newScale[1] =
                -1 * (amount.dy / object.imageElement!.naturalHeight) +
                newScale[1];
              break;
            case HandlePositions.TOP_RIGHT:
              if (!details.constrainAspectRatio) {
                newScale[0] =
                  amount.dx / object.imageElement!.naturalWidth + newScale[0];
                newOrigin[1] += amount.dy;
                newScale[1] =
                  -1 * (amount.dy / object.imageElement!.naturalHeight) +
                  newScale[1];
              } else {
                newScale = [
                  (1 + scaleChange) * newScale[0],
                  (1 + scaleChange) * newScale[1],
                ];
                newOrigin[1] -= sizeChange.height;
              }
              break;
            case HandlePositions.BOTTOM_MID:
              newScale[1] =
                amount.dy / object.imageElement!.naturalHeight + newScale[1];
              break;
            case HandlePositions.LEFT_MID:
              newOrigin[0] += amount.dx;
              newScale[0] =
                -1 * (amount.dx / object.imageElement!.naturalWidth) +
                newScale[0];
              break;
            case HandlePositions.RIGHT_MID:
              newScale[0] =
                amount.dx / object.imageElement!.naturalWidth + newScale[0];
              break;
            case HandlePositions.BOTTOM_RIGHT:
              if (!details.constrainAspectRatio) {
                newScale[0] =
                  amount.dx / object.imageElement!.naturalWidth + newScale[0];
                newScale[1] =
                  amount.dy / object.imageElement!.naturalHeight + newScale[1];
              } else {
                newScale = [
                  (1 + scaleChange) * newScale[0],
                  (1 + scaleChange) * newScale[1],
                ];
              }
              break;
            case HandlePositions.BOTTOM_LEFT:
              if (!details.constrainAspectRatio) {
                newOrigin[0] += amount.dx;
                newScale[0] =
                  -1 * (amount.dx / object.imageElement!.naturalWidth) +
                  newScale[0];
                newScale[1] =
                  amount.dy / object.imageElement!.naturalHeight + newScale[1];
              } else {
                newOrigin[0] -= sizeChange.width;
                newScale = [
                  (1 + scaleChange) * newScale[0],
                  (1 + scaleChange) * newScale[1],
                ];
              }
          }
          return {
            ...ls,
            objects: {
              ...ls.objects,
              [id]: {
                ...oldObjMeta,
                positionMode: "custom",
                position: {
                  ...oldObjMeta.position,
                  origin: newOrigin,
                  scale: newScale,
                },
              },
            },
          };
        }),
        hasChangesSinceSave: true,
      };
    }
    case BackdropEditorActionTypes.SET_BACKGROUND_COLOR: {
      let newColor = action.payload;
      return {
        ...state,
        backdrops: [
          ...state.backdrops.map((existingBackdropState) => ({
            ...existingBackdropState,
            backgroundColor:
              existingBackdropState.resolution === state.selectedResolution
                ? newColor.hex
                : existingBackdropState.backgroundColor || newColor.hex,
          })),
        ],
        hasChangesSinceSave: true,
      };
    }
    case BackdropEditorActionTypes.MARK_CHANGES_SAVED: {
      return {
        ...state,
        hasChangesSinceSave: false,
      };
    }
  }
};
