import axios from "axios";

import { Resource } from "./generics";
import { processApiError } from "./utility-functions";

export type BackdropMeta = {
  name: string;
};

export type BackdropFormat = {
  resolution: string;
  md5?: string;
};

export type Backdrop = {
  formats: BackdropFormat[];
} & Resource &
  BackdropMeta;

/** This should be used to generate a backdrop ID before uploading images
 * that target it.
 */
export const createBackdrop: (meta: BackdropMeta) => Promise<Backdrop> = async (
  meta
) => {
  try {
    const newBackdropResponse = await axios.post<{ data: Backdrop }>(
      "/api/backdrops",
      meta
    );
    return newBackdropResponse.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};

export const getBackdrops: () => Promise<Backdrop[]> = async () => {
  try {
    const backdropsResponse = await axios.get<{
      data: Backdrop[];
    }>("/api/backdrops");
    return backdropsResponse.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};

export const deleteBackdrop: (id: string) => Promise<void> = async (id) => {
  try {
    await axios.delete(`/api/backdrops/${id}`);
  } catch (e) {
    throw processApiError(e);
  }
};
