import { FC, Fragment } from "react";

import { useTranslation } from "react-i18next";

import { format, formatDistance } from "date-fns";

import { EventLog as EventLogType } from "../api/eventLogs";
import { Resource } from "../api/generics";

const EventLog: FC<{
  log: EventLogType;
  document?: Resource;
  user?: Resource;
}> = ({ log, document, user }) => {
  const hasDiff = !!log.document?.diff.length;
  const { t } = useTranslation("events");
  return (
    <>
      <div className="py-2 justify-self-end text-xs flex flex-col items-end justify-center">
        <div>{format(new Date(log.createdAt), "dd-MM-yyyy, HH:mm:ss")}</div>
        <div className="italic">
          ({formatDistance(new Date(), new Date(log.createdAt))} ago)
        </div>
      </div>
      <div className="flex flex-col py-2 justify-center">
        {/* @ts-ignore dynamic key */}
        <div>{t(log.event, { name: "User" })}</div>
        {hasDiff && (
          <div
            className="col-start-2 grid grid-cols-4 gap-1 text-sm mt-1 px-2 py-1 border border-gray-200 bg-gray-100"
            style={{
              gridTemplateColumns: "repeat(4, auto)",
            }}
          >
            {log.document!.diff.map((diff, i) => (
              <Fragment key={`${log._id}-diff-${i}`}>
                <span className="relative top-px font-bold mr-2">
                  {diff.prop}
                </span>
                <span className="text-red-400">{diff.pre.toString()}</span>
                <span>&rarr;</span>
                <span className="text-green-600">{diff.post.toString()}</span>
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default EventLog;
