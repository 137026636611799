import { FC, useEffect, useState } from "react";

import Modal from "react-modal";

import {
  EventLog,
  getEventLogsForDocument,
  getEventLogsForUser,
} from "../api/eventLogs";
import { Resource } from "../api/generics";

import EventLogComponent from "./EventLog";
import LoadingSpinner from "./LoadingSpinner";
import { centeredAndSizedByContent } from "./modalCustomStyles";

const EventLogModal: FC<{
  type: "user" | "document";
  id: string;
  onRequestClose: () => void;
  resources?: Resource[];
}> = ({ onRequestClose, type, id, resources }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [logs, setLogs] = useState<EventLog[]>([]);

  useEffect(() => {
    const getLogs = async () => {
      const apiCall =
        type === "user" ? getEventLogsForUser : getEventLogsForDocument;
      try {
        const logs = await apiCall(id);
        setLogs(logs);
      } catch (e) {
        // TODO: add error to modal.
      }
      setLoading(false);
    };

    getLogs();
  }, [type, id]);
  return (
    <Modal
      isOpen={true}
      onRequestClose={onRequestClose}
      style={centeredAndSizedByContent}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="flex flex-col">
          <h2 className="font-bold text-lg mb-2">User history</h2>
          <div
            className="grid grid-cols-2 gap-x-4 divide-y divide-gray-200"
            style={{
              gridTemplateColumns: "repeat(2, auto)",
            }}
          >
            {logs.map((log) => {
              const docAndSelf: any = {};
              if (!!resources) {
                docAndSelf.self = resources.find((r) => r._id === log.user);
                if (log.document?.id) {
                  docAndSelf.doc = resources.find(
                    (r) => r._id === log.document!.id
                  );
                }
              }
              return <EventLogComponent log={log} key={log._id} />;
            })}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default EventLogModal;
