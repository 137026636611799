import { Styles } from "react-modal";

const centeredAndSizedByContent: Styles = {
  overlay: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingTop: "4rem",
    paddingBottom: "4rem",
    maxHeight: "100vh",
  },
  content: {
    position: "static",
    overflow: "auto",
    maxHeight: "100%",
  },
};

export { centeredAndSizedByContent };
