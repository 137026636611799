import { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  getSessionMessage,
  SessionMessage as SessionMessageType,
} from "../api/auth";

import Callout, { CalloutTypes } from "./Callout";

const SessionMessage: FC<{}> = () => {
  const [sessionMessage, setSessionMessage] =
    useState<SessionMessageType | null>();
  const { t } = useTranslation(["server_messages"]);

  let messageText = "";

  if (sessionMessage) {
    messageText = t(
      // @ts-ignore (dynamically built key)
      `server_messages:${sessionMessage.type}_${sessionMessage.message}`
    );
  }

  useEffect(() => {
    getSessionMessage()
      .then((msg) => {
        setSessionMessage(msg);
      })
      .catch((e) => setSessionMessage(undefined));
  }, []);

  return sessionMessage ? (
    <div className="absolute top-10 w-64 mx-auto justify-self-center self-center">
      <Callout
        type={
          sessionMessage.type === "error"
            ? CalloutTypes.error
            : CalloutTypes.info
        }
        dismissable={true}
        onDismiss={() => setSessionMessage(null)}
      >
        {messageText}
      </Callout>
    </div>
  ) : null;
};

export default SessionMessage;
