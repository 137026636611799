const clearSessionData = () => {
  caches.keys().then((cacheNames) => {
    cacheNames.forEach((cacheName) => {
      if (cacheName.startsWith("session")) {
        caches.delete(cacheName);
      }
    });
  });
};

export { clearSessionData };
