import React, { FC, useState } from "react";

import capitalize from "capitalize";
import classNames from "classnames";

import { Backdrop } from "../api/backdrops";
import { EndpointSummary } from "../api/endpoints";
import { Group } from "../api/groups";

import BackdropPreviewImage from "./BackdropPreviewImage";
import GroupActionMenu from "./GroupActionMenu";

const GroupTile: FC<{
  group: Group;
  endpointSummary: EndpointSummary;
  backdrops: Backdrop[];
  onGroupDeleted: (groupId: string) => void;
  onGroupModified: (group: Group) => void;
  allResolutions: string[];
}> = ({
  group,
  endpointSummary,
  backdrops,
  onGroupDeleted,
  onGroupModified,
  allResolutions,
}) => {
  const [selectedResolution, setSelectedResolution] = useState<string>(
    Object.keys(endpointSummary.resolutions).reduce(
      (mostPopular, resolution) => {
        if (!mostPopular) return resolution;
        if (
          endpointSummary.resolutions[resolution].total >
          endpointSummary.resolutions[mostPopular].total
        )
          return resolution;
        else return mostPopular;
      },
      ""
    )
  );

  return (
    <div
      key={group._id}
      className="border border-black dark:border-gray-600 rounded-sm p-4 m-4 flex flex-col relative"
    >
      <h2 className="font-bold text-xl">
        {group.identifier} - {endpointSummary?.total} computers
      </h2>
      <GroupActionMenu
        className="absolute right-4 top-4"
        group={group}
        onGroupDeleted={onGroupDeleted}
        onGroupModified={onGroupModified}
        endpointSummary={endpointSummary}
        allResolutions={allResolutions}
        selectedResolution={selectedResolution}
      />

      <div className="flex flex-row mb-2">
        {(["desktop", "lockscreen"] as Array<"desktop" | "lockscreen">).map(
          (backdropType) => (
            <div className="flex flex-col flex-1" key={backdropType}>
              <span>{capitalize(backdropType)}</span>
              <ul>
                <li>
                  {endpointSummary?.downloads[backdropType].current} current
                </li>
                <li>
                  {endpointSummary?.downloads[backdropType].previous} previous
                </li>
                <li>{endpointSummary?.downloads[backdropType].older} older</li>
              </ul>
            </div>
          )
        )}
      </div>
      <div>
        {/* TODO: this should prob be the res listed in grp */}
        {Object.keys(endpointSummary?.resolutions || [])
          .sort((resA, resB) => {
            return (
              endpointSummary!.resolutions[resB].total -
              endpointSummary!.resolutions[resA].total
            );
          })
          .map((resolution, i) => {
            const resolutionData = endpointSummary!.resolutions[resolution];
            return (
              <span
                key={resolution}
                onClick={() => {
                  if (selectedResolution !== resolution) {
                    setSelectedResolution(resolution);
                  }
                }}
                className={classNames(
                  "text-xs p-1 text-white rounded-sm m-1 ml-0 cursor-pointer inline-block",
                  // darker background if selected
                  selectedResolution === resolution
                    ? "bg-blue-800"
                    : "bg-blue-300"
                )}
              >
                {resolution} ({resolutionData.total})
              </span>
            );
          })}
      </div>
      <div className="grid grid-cols-2 gap-x-1">
        {(["desktop", "lockscreen"] as ("desktop" | "lockscreen")[]).map(
          (type) => (
            <div className="flex flex-col" key={type}>
              <BackdropPreviewImage
                backdrop={backdrops.find(
                  (bd) => bd._id === group.backdrops[type]
                )}
                resolution={selectedResolution}
                className="mt-2"
                alt={type}
              />
              <figcaption className="text-xs italic">
                {/* TODO: i18n */}
                {type} @ {selectedResolution}
              </figcaption>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default GroupTile;
