/* eslint react-hooks/rules-of-hooks: 0 */
import { CSSObject } from "@emotion/serialize";

import useIsDarkMode from "../Hooks/useIsDarkMode";

const selectCustomStyles = {
  control: (provided: CSSObject) => {
    return {
      ...provided,
      borderRadius: "0.125rem",
      backgroundColor: "rgba(31, 41, 55, 1)",
      borderColor: "rgba(75, 85, 99, 1)", //gray-600
      minHeight: "32px",
    };
  },
  menu: (provided: CSSObject) => {
    return {
      ...provided,
      backgroundColor: useIsDarkMode()
        ? "rgb(17, 24, 39)" //gray-900
        : "white",
      borderColor: useIsDarkMode()
        ? "rgb(75, 85, 99)" // gray-600
        : "rgb(209, 213, 219)", // gray-300
      borderWidth: "1px",
    };
  },
  option: (
    provided: CSSObject,
    state: { isSelected: boolean; isFocused: boolean; isDisabled: boolean }
  ) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? useIsDarkMode()
        ? "rgb(75, 85, 99)" // gray-600
        : "rgb(209, 213, 219)" // gray-300
      : "transparent",
  }),
  valueContainer: (provided: CSSObject) => ({
    ...provided,
    padding: "0 4px",
    color: "white",
  }),
  multiValue: (provided: CSSObject) => ({
    ...provided,
    backgroundColor: useIsDarkMode()
      ? "rgb(75, 85, 99)"
      : provided.backgroundColor,
  }),
  multiValueLabel: (provided: CSSObject) => ({
    ...provided,
    color: useIsDarkMode() ? "white" : provided.color,
  }),
  multiValueRemove: (provided: CSSObject) => ({
    ...provided,
    color: useIsDarkMode() ? provided.color : "rgba(0,0,0,0.8)",
  }),
  indicatorsContainer: (provided: CSSObject) => ({
    ...provided,
    margin: "-2px",
  }),
  placeholder: (provided: CSSObject) => ({
    ...provided,
    color: "#ccc",
  }),
  input: (provided: CSSObject) => ({ ...provided, color: "white" }),
};

export default selectCustomStyles;
