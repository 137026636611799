import { useEffect, useRef, useState } from "react";

function usePointerPosition(): { x: number; y: number } {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const currentRafRef = useRef<number | null>(null);

  useEffect(() => {
    const onPointerMove = (e: PointerEvent) => {
      if (currentRafRef.current) cancelAnimationFrame(currentRafRef.current);
      currentRafRef.current = requestAnimationFrame(() => {
        currentRafRef.current = null;
        setPosition({ x: e.clientX, y: e.clientY });
      });
    };
    window.addEventListener("pointermove", onPointerMove);
    return () => {
      window.removeEventListener("pointermove", onPointerMove);
    };
  });
  return position;
}

export function useCanvasPointerPosition(
  canvasElement: HTMLCanvasElement | null
) {
  const [pos, setPos] = useState<{ x: number; y: number } | null>(null);

  useEffect(() => {
    if (!canvasElement) return;
    let canvas = canvasElement!;
    function onPointerIn(e: PointerEvent) {}

    function onPointerOut(e: PointerEvent) {
      setPos(null);
    }

    function onPointerMove(e: PointerEvent) {
      let {
        x: canvasX,
        y: canvasY,
        width: canvasWidthPx,
        height: canvasHeightPx,
      } = canvas.getBoundingClientRect();
      let canvasWidthCanvasPx = canvas.width;
      let canvasHeightCanvasPx = canvas.height;
      let canvasZoomX = canvasWidthPx / canvasWidthCanvasPx;
      let canvasZoomY = canvasHeightPx / canvasHeightCanvasPx;
      setPos({
        x: (e.clientX - canvasX) / canvasZoomX,
        y: (e.clientY - canvasY) / canvasZoomY,
      });
    }
    canvas.addEventListener("pointerenter", onPointerIn);
    canvas.addEventListener("pointerleave", onPointerOut);
    canvas.addEventListener("pointermove", onPointerMove);
    return () => {
      canvas.removeEventListener("pointerenter", onPointerIn);
      canvas.removeEventListener("pointerleave", onPointerOut);
      canvas.removeEventListener("pointermove", onPointerMove);
    };
  }, [canvasElement]);

  return pos;
}

export default usePointerPosition;
