import axios from "axios";

import { Resource } from "./generics";
import { processApiError } from "./utility-functions";

type Architechture = "x64" | "x86";

export type Installer = {
  platform: "windows";
  arch: Architechture;
  extension: "msi";
  semVer: {
    major: number;
    minor: number;
    patch: number;
  };
  md5: string;
} & Resource;

export const semVerToString: (semVer: Installer["semVer"]) => string = (v) => {
  return `v${v.major}.${v.minor}.${v.patch}`;
};

export const semVerToValue: (semVer: Installer["semVer"]) => number = (v) => {
  return v.major * 1000000 + v.minor * 1000 + v.patch;
};

const uploadFile = async (
  file: File,
  progressCallback?: (progress: number) => void
) => {
  const formData = new FormData();
  formData.append("installer", file);
  try {
    const result = await axios.post<{ data: Installer }>(
      `/api/installers`,
      formData,
      {
        onUploadProgress: progressCallback
          ? (progress: ProgressEvent) => {
              progressCallback(progress.loaded / progress.total);
            }
          : undefined,
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    return result.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};

const getInstallers = async () => {
  try {
    const installersResponse = await axios.get<{ data: Installer[] }>(
      "/api/installers"
    );
    return installersResponse.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};

const deleteInstaller = async (id: string) => {
  try {
    await axios.delete(`/api/installers/${id}`);
  } catch (e) {
    throw processApiError(e);
  }
};

export { uploadFile, getInstallers, deleteInstaller };
