import { FC, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { EndpointSummary } from "../api/endpoints";
import { deleteGroup, Group } from "../api/groups";

import AddResolutionModal from "./AddResolutionModal";
import ClearBackdropModal from "./ClearBackdropModal";
import ConfirmationModal from "./ConfirmationModal";
import DropdownMenu from "./DropdownMenu";
import SelectExistingBackdropModal from "./SelectExistingBackdropModal";

const GroupActionMenu: FC<{
  group: Group;
  className?: string;
  endpointSummary: EndpointSummary;
  allResolutions: string[];
  selectedResolution: string;
  onGroupDeleted: (groupId: string) => void;
  onGroupModified: (group: Group) => void;
}> = ({
  group,
  onGroupDeleted,
  onGroupModified,
  endpointSummary,
  allResolutions,
  className,
  selectedResolution,
}) => {
  // These states are used to toggle modals.
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showClearBackdropModalForGroup, setShowClearBackdropModalForGroup] =
    useState<Group | null>(null);

  const [showAddResolutionModal, setShowAddResolutionModal] =
    useState<boolean>(false);

  const [showChangeBackdropModalForGroup, setShowChangeBackdropModalForGroup] =
    useState<Group | null>(null);

  const { addToast } = useToasts();
  const { t } = useTranslation(["page_groups", "common_resources"]);
  const history = useHistory();

  return (
    <>
      {showDeleteModal && (
        <ConfirmationModal
          isOpen={true}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={async () => {
            await deleteGroup(group._id!);
            addToast(
              t("page_groups:deleteConfirmationToast", {
                groupName: group.identifier,
              }),
              { appearance: "success", autoDismiss: true }
            );
            onGroupDeleted(group._id);
            setShowDeleteModal(false);
          }}
          isIrreversible={true}
          heading={t("page_groups:deleteConfirmationModalHeading")}
          message={t("page_groups:deleteConfirmationModalBody", {
            groupName: group.identifier,
          })}
        />
      )}
      {!!showChangeBackdropModalForGroup && (
        <SelectExistingBackdropModal
          group={showChangeBackdropModalForGroup}
          onCancel={() => setShowChangeBackdropModalForGroup(null)}
          onSuccess={(group) => {
            setShowChangeBackdropModalForGroup(null);
            addToast(
              t("page_groups:updateConfirmationToast", {
                groupName: group.identifier,
              }),
              { appearance: "success", autoDismiss: true }
            );
            onGroupModified(group);
          }}
          selectedResolution={selectedResolution}
          allResolutions={allResolutions}
        />
      )}
      {!!showClearBackdropModalForGroup && (
        <ClearBackdropModal
          group={showClearBackdropModalForGroup}
          onRequestClose={() => setShowClearBackdropModalForGroup(null)}
          onGroupModified={onGroupModified}
        />
      )}
      {showAddResolutionModal && (
        <AddResolutionModal
          onRequestClose={(addedResolutions) => {
            setShowAddResolutionModal(false);
            if (addedResolutions) {
              addedResolutions.forEach((res) => {
                group.formats.push({
                  resolution: res,
                  firstSeenAt: "",
                  lastSeenAt: "",
                });
              });
              onGroupModified(group);
            }
          }}
          allResolutions={allResolutions}
          groupId={group._id}
          groupName={group.identifier || "unknown group"}
          alreadyAddedResolutions={Object.keys(
            endpointSummary.resolutions || []
          )}
        />
      )}
      <DropdownMenu
        className={className}
        menuItems={[
          {
            text: t("page_groups:changeBackdrop_new"),
            onClick: () => {
              history.push(
                `/backdrops/editor/new/?groupIdentifier=${group.identifier}`
              );
            },
          },
          {
            text: t("page_groups:changeBackdrop_existing"),
            onClick: () => {
              setShowChangeBackdropModalForGroup(group);
            },
          },
          {
            text: t("page_groups:clearBackdrop"),
            onClick: () => {
              setShowClearBackdropModalForGroup(group);
            },
            disabled: !group.backdrops.desktop && !group.backdrops.lockscreen,
          },
          {
            text: t("page_groups:addResolution"),
            onClick: () => {
              setShowAddResolutionModal(true);
            },
          },
          {
            text: t("common_resources:delete", {
              context: "group",
            }),
            onClick: () => {
              setShowDeleteModal(true);
            },
          },
        ]}
      />
    </>
  );
};

export default GroupActionMenu;
