import React, { useEffect, useRef, useState } from "react";

export type LocalImageData = {
  dataURL: string;
  naturalSize: [number, number];
  MIMEtype: string;
  name: string;
  imageElement: HTMLImageElement;
};

type LocalImageFileThumbnailProps = {
  file?: File;
  onLoad?: (data: LocalImageData) => any;
};

// TODO: deal with non jpeg / non image.
const LocalImageFileThumbnail = ({
  file,
  onLoad,
}: LocalImageFileThumbnailProps) => {
  const [src, setSrc] = useState("");
  const imgElement = useRef<HTMLImageElement>(null);
  useEffect(() => {
    if (!file) return;
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      (loadEvent) => {
        let dataURL = loadEvent.target?.result as string;
        setSrc(dataURL);
      },
      { once: true }
    );
    reader.readAsDataURL(file);
    return () => {
      reader.abort();
    };
  }, [file]);

  return src ? (
    <img
      src={src}
      alt={file?.name}
      ref={imgElement}
      onLoad={(event) => {
        let elem = event.target as HTMLImageElement;
        if (onLoad)
          onLoad({
            MIMEtype: file!.type,
            name: file!.name,
            dataURL: src,
            imageElement: elem,
            naturalSize: [elem.naturalWidth, elem.naturalHeight],
          });
      }}
    />
  ) : (
    <div />
  );
};

export default LocalImageFileThumbnail;
