import React, { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { createBackdrop } from "../api/backdrops";
import { Group, updateGroup } from "../api/groups";

import useTemporaryCssRule from "../Hooks/useTemporaryCssRule";

import { NewBackdropMeta } from "../Forms/NewBackdropForm";

import BackdropMockup, { TranslationTypes } from "../BackdropMockup";
import { BackdropEditorState } from "../backdropEditorReducer";
import exportBackdrops from "../exportBackdrops";
import Button, { ButtonTypes } from "./Button";
import Callout from "./Callout";
import Checkbox from "./Checkbox";

const SaveAndPublishForm: FC<{
  newBackdropMeta: NewBackdropMeta;
  backdropsData: BackdropEditorState["backdrops"];
  objectLibrary: BackdropEditorState["objectLibrary"];
  onCancel: () => void;
  onComplete: () => void;
}> = ({
  newBackdropMeta,
  backdropsData,
  objectLibrary,
  onCancel,
  onComplete,
}) => {
  const [summaryZooms, setSummaryZooms] = useState<{
    [resolution: string]: number;
  }>({});

  const [uploadProgress, setUploadProgress] = useState<{
    [resolution: string]: number;
  }>({});

  useTemporaryCssRule(`
    .pxn-fc-widget-customisation {
      transform: translateY(1rem) translateX(1rem);
    }
  `);

  const [targetDesktopGroups, setTargetBackgroundGroups] = useState<string[]>(
    newBackdropMeta.groups.map((g) => g._id)
  );
  const [targetLockscreenGroups, setTargetLockscreenGroups] = useState<
    string[]
  >(newBackdropMeta.groups.map((g) => g._id));

  const { t } = useTranslation([
    "modal_publishbackdrop",
    "common_objects",
    "common_actions",
  ]);

  const saveBackdrops = async () => {
    const newBackdrop = await createBackdrop(newBackdropMeta!);
    const progressObject = backdropsData.reduce(
      (progress: { [resolution: string]: number }, backdrop) => {
        progress[backdrop.resolution] = 0;
        return progress;
      },
      {}
    );
    setUploadProgress(progressObject);
    // TODO: try/catch. / perhaps not all settled.
    await Promise.all(
      exportBackdrops(
        newBackdrop._id,
        backdropsData,
        objectLibrary,
        (resolution, progress) => {
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [resolution]: progress,
          }));
        }
      )
    );
    return newBackdrop;
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex flex-row flex-1">
        <div className="flex flex-col mr-2 w-80">
          <h1 className="font-bold text-xl mb-2">
            {t("modal_publishbackdrop:saveAndPublishTitle")}
          </h1>
          <p className="mb-2">
            {t("modal_publishbackdrop:selectPublishGroups")}
          </p>
          <div className="grid grid-cols-3 gap-y-2 mt-2">
            <div className="contents font-bold">
              <div>{t("common_objects:group")}</div>
              <div>{t("common_objects:desktop")}</div>
              <div>{t("common_objects:lockscreen")}</div>
            </div>
            {newBackdropMeta?.groups.map((group) => (
              <React.Fragment key={`checkboxes-${group._id}`}>
                <div className="leading-4">{group.identifier}</div>
                <Checkbox
                  checked={targetDesktopGroups.includes(group._id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTargetBackgroundGroups((prev) => [...prev, group._id]);
                    } else {
                      setTargetBackgroundGroups((prev) =>
                        prev.filter((gId) => gId !== group._id)
                      );
                    }
                  }}
                ></Checkbox>
                <Checkbox
                  checked={targetLockscreenGroups.includes(group._id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTargetLockscreenGroups((prev) => [...prev, group._id]);
                    } else {
                      setTargetLockscreenGroups((prev) =>
                        prev.filter((gId) => gId !== group._id)
                      );
                    }
                  }}
                ></Checkbox>
              </React.Fragment>
            ))}
          </div>
          <Callout className="mt-6 bg-gray-700 ">
            {t("modal_publishbackdrop:instantLiveNotification")}
          </Callout>
        </div>
        <div className="grid grid-cols-2 gap-2 w-100 h-full flex-1">
          {backdropsData.map((backdrop) => {
            return (
              <div
                className="flex flex-col border border-black relative"
                key={backdrop.resolution}
              >
                <BackdropMockup
                  isEditable={false}
                  className="flex-1"
                  key={backdrop.resolution}
                  objectLibrary={objectLibrary}
                  objects={backdrop.objects}
                  backgroundColor={backdrop.backgroundColor || "#000"}
                  resolution={backdrop.resolution}
                  zoom={summaryZooms[backdrop.resolution] || 0}
                  pan={[0, 0]}
                  initialZoomPadding={5}
                  onZoom={(z) => {
                    if (z.type === TranslationTypes.relative) return;
                    setSummaryZooms((prev) => {
                      return {
                        ...prev,
                        [backdrop.resolution]: z.amount,
                      };
                    });
                  }}
                />
                <span>{backdrop.resolution}</span>
                {uploadProgress.hasOwnProperty(backdrop.resolution) && (
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <span className="text-xl text-white">
                      {Math.floor(uploadProgress[backdrop.resolution] * 100)}%
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex justify-end mt-2 gap-2">
        <Button type="button" onClick={onCancel}>
          {t("common_actions:cancel")}
        </Button>
        <Button
          type="button"
          buttonType={ButtonTypes.neutral}
          onClick={async () => {
            await saveBackdrops();
            onComplete();
          }}
        >
          {t("common_actions:saveWithoutPublish")}
        </Button>
        <Button
          buttonType={ButtonTypes.positive}
          type="button"
          onClick={async () => {
            const newBackdrop = await saveBackdrops();
            let groupUpdatePromises: Promise<Group>[] = [];
            // Add the backdrop to the group.
            newBackdropMeta?.groups.forEach((group) => {
              const setDesktop = targetDesktopGroups.includes(group._id);
              const setLockscreen = targetLockscreenGroups.includes(group._id);
              if (setDesktop || setLockscreen) {
                const backdropsPayload: {
                  desktop?: string;
                  lockscreen?: string;
                } = {};
                if (setDesktop) backdropsPayload.desktop = newBackdrop._id;
                if (setLockscreen)
                  backdropsPayload.lockscreen = newBackdrop._id;
                groupUpdatePromises.push(
                  updateGroup(group._id, {
                    backdrops: backdropsPayload,
                  })
                );
              }
            });
            await Promise.all(groupUpdatePromises);
            onComplete();
          }}
        >
          {t("common_actions:saveAndPublish")}
        </Button>
      </div>
    </div>
  );
};

export default SaveAndPublishForm;
