import axios from "axios";

import { processApiError } from "./utility-functions";

export type DownloadStats = {
  current: number;
  previous: number;
  older: number;
  none: number;
};

export type EndpointSummary = {
  groupId: string;
  total: number;
  downloads: {
    desktop: DownloadStats;
    lockscreen: DownloadStats;
  };

  resolutions: {
    [resolution: string]: {
      total: number;
      /** ISO Date String */
      lastCheck: string;
    };
  };
};

type GetEndPointSummaryResponseType = {
  data: EndpointSummary[];
};

export const getEndpointSummary = async () => {
  try {
    const endpointSummaryResponse =
      await axios.get<GetEndPointSummaryResponseType>("/api/endpoints/summary");
    return endpointSummaryResponse.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};
