import React, { FC, useRef, useState } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import capitalize from "capitalize";
import classNames from "classnames";

import { selfServeAccount } from "../api/accounts";
import { ApiError } from "../api/utility-functions";

import useIsDarkMode from "../Hooks/useIsDarkMode";

import Button from "../Components/Button";
import Callout, { CalloutTypes } from "../Components/Callout";
import FormFieldError from "../Forms/FormFieldError";

type SignupFormDataType = {
  name?: string;
  email?: string;
  companyname: string;
  server: string;
  azure: "true" | undefined;
};

const SignupPage: FC<{}> = () => {
  const {
    handleSubmit,
    clearErrors,
    register,
    errors,
    watch,
    formState,
    setValue,
    setError,
  } = useForm<SignupFormDataType>({
    mode: "onSubmit",
  });
  const isDarkMode = useIsDarkMode();
  const { t } = useTranslation([
    "common_user",
    "page_signup",
    "server_messages",
  ]);
  const [useMicrosoft, setUseMicrosoft] = useState<boolean | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>();

  const recaptchaRef = useRef<ReCAPTCHA>();

  const { dirtyFields } = formState;

  const emailValue = watch("email");
  const companyName = watch("companyname");
  if (!useMicrosoft && !dirtyFields.companyname) {
    const emailDomainMatchResult = /@([a-zA-Z-]+)\./.exec(emailValue!);
    if (emailDomainMatchResult) {
      const derivedCompany = capitalize.words(
        emailDomainMatchResult[1].replace("-", " ")
      );
      if (derivedCompany !== companyName) {
        setValue("companyname", derivedCompany, { shouldDirty: false });
      }
    }
  }

  const onSubmit = handleSubmit(async (data) => {
    if (loading) return;
    const recaptcha = await recaptchaRef.current?.executeAsync();
    if (!recaptcha) return;
    setLoading(true);
    try {
      if (data.azure !== "true") {
        const payload: Parameters<typeof selfServeAccount>[0] = {
          name: data.companyname,
          recaptcha,
          user: {
            email: data.email!,
            name: data.name!,
          },
        };
        await selfServeAccount(payload);
        setSuccess(true);
      }
    } catch (e) {
      let error = e as ApiError;
      console.dir(e);
      setError("server", {
        message: t("server_messages:error", {
          context: error.message,
          details: e.tid,
        }),
      });
      recaptchaRef.current?.reset();
    } finally {
      setLoading(false);
    }
  });

  return (
    <div className="w-full h-full flex items-center justify-center text-base">
      <div className="flex flex-col w-72 p-4 border border-black dark:border-gray-600 rounded-sm relative">
        {useMicrosoft !== null && (
          <button
            className="text-xs mb-1 absolute top-2 left-2 cursor-pointer"
            type="button"
            onClick={() => setUseMicrosoft(null)}
          >
            ‹ {t("page_signup:back")}
          </button>
        )}
        <h1 className="mt-2 mb-4 text-center font-bold text-lg">
          {t("page_signup:welcome")}
        </h1>

        {!success ? (
          <form
            className="flex flex-col"
            onSubmit={onSubmit}
            // Prevent the browser validating the form - we'll do it.
            noValidate
          >
            <ReCAPTCHA
              sitekey="6LepuFAaAAAAAMNbE4TSjxjwgcEcetp1g6d9yf2L"
              size="invisible"
              badge="bottomleft"
              // @ts-ignore
              ref={recaptchaRef}
              theme={isDarkMode ? "dark" : "light"}
            />
            {useMicrosoft === null ? (
              <div className="flex flex-col">
                <div className="mb-4">
                  {t("page_signup:howWouldYouLikeToSignUp")}
                </div>
                <Button
                  className="flex-1 mb-2"
                  onClick={() => {
                    setUseMicrosoft(false);
                  }}
                >
                  {t("page_signup:usingEmail")}
                </Button>
                <Button
                  className="flex-1"
                  onClick={() => {
                    setUseMicrosoft(true);
                  }}
                >
                  {t("page_signup:usingMicrosoft")}
                </Button>
              </div>
            ) : (
              <>
                {!useMicrosoft ? (
                  /* Fields when not using MS */
                  <>
                    <div className="flex flex-row justify-between items-end">
                      <label htmlFor="signup-name">
                        {t("common_user:name")}
                      </label>
                    </div>
                    <input
                      className="input mb-2 px-2 py-1"
                      id="signup-name"
                      name="name"
                      autoComplete="name"
                      type="text"
                      onChange={() => clearErrors("server")}
                      ref={register({ required: true })}
                    />
                    <FormFieldError
                      condition={!!errors.name}
                      i18nOptions={{ context: "requiredField" }}
                    />
                    <label htmlFor="signup-email">
                      {t("common_user:email")}
                    </label>
                    <input
                      type="email"
                      className="input mb-2 px-2 py-1"
                      autoComplete="email"
                      id="signup-email"
                      name="email"
                      onChange={() => clearErrors("server")}
                      ref={register({ required: true })}
                    />
                    <FormFieldError
                      condition={!!errors.email}
                      i18nOptions={{ context: "requiredField" }}
                    />
                  </>
                ) : (
                  <input
                    type="hidden"
                    value={"true"}
                    name="azure"
                    id="azure"
                    ref={register({})}
                  />
                )}

                <label htmlFor="signup-company-name">
                  {t("common_user:companyName")}
                </label>
                <input
                  type="text"
                  className="input mb-2 px-2 py-1"
                  autoComplete="companyname"
                  id="signup-company-name"
                  name="companyname"
                  onChange={() => clearErrors("server")}
                  ref={register({ required: true })}
                />
                <FormFieldError
                  condition={!!errors.companyname}
                  i18nOptions={{ context: "requiredField" }}
                />
                {!!errors.server && (
                  <Callout
                    className="mb-4"
                    type={CalloutTypes.error}
                    content={errors.server.message}
                  />
                )}

                {!useMicrosoft ? (
                  <Button
                    type="submit"
                    className="button mt-4 mb-1 disabled:bg-red-100"
                    loading={loading}
                    disabled={!!Object.keys(errors).length}
                  >
                    {t("page_signup:signUpActionButton")}
                  </Button>
                ) : (
                  <a
                    href={
                      companyName?.length <= 1
                        ? ""
                        : `/api/auth/openid/signup?companyName=${encodeURIComponent(
                            companyName
                          )}`
                    }
                    className={classNames(
                      "self-center mt-2",
                      companyName?.length > 1
                        ? "opacity-100"
                        : "opacity-50 cursor-not-allowed"
                    )}
                  >
                    <img
                      className="cursor-pointer"
                      style={{ height: "41px" }}
                      src={`microsoft/ms-symbollockup_signin_${
                        isDarkMode ? "dark" : "light"
                      }.svg`}
                      alt="Sign in with microsoft"
                    />
                  </a>
                )}
              </>
            )}
          </form>
        ) : (
          <div>{t("page_signup:successCheckEmail")}</div>
        )}
      </div>
    </div>
  );
};

export default SignupPage;
