import axios from "axios";

import { Resource } from "./generics";
import { processApiError } from "./utility-functions";

export type Group = {
  identifier: string;
  backdrops: {
    desktop?: string | null;
    lockscreen?: string | null;
  };
  formats: {
    resolution: string;
    firstSeenAt: string;
    lastSeenAt: string;
  }[];
} & Resource;

type AllGroupsResponse = { data: Group[] };
type GroupUpdateResponse = { data: Group };

export const getGroups = async () => {
  try {
    const groupsResponse = await axios.get<AllGroupsResponse>("/api/groups");
    return groupsResponse.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};

export const updateGroup = async (
  id: string,
  changedMetadata: Partial<Group>
) => {
  try {
    const groupUpdateResponse = await axios.patch<GroupUpdateResponse>(
      `/api/groups/${id}`,
      changedMetadata
    );
    return groupUpdateResponse.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};

export const addResolution: (data: {
  id: string;
  resolution: string;
}) => Promise<Group> = async ({ id, resolution }) => {
  try {
    const addResolutionResponse = await axios.post<GroupUpdateResponse>(
      `/api/groups/${id}/resolutions/${resolution}`
    );
    return addResolutionResponse.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};

export const deleteGroup = async (id: string) => {
  try {
    await axios.delete(`/api/groups/${id}`);
    return true;
  } catch (e) {
    throw processApiError(e);
  }
};
