import { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

import classNames from "classnames";
import QRCode from "qrcode.react";

import { generateOTPSecretUrl, setOTPEnabled } from "../api/users";

import useIsDarkMode from "../Hooks/useIsDarkMode";
import useServerErrorToast from "../Hooks/useServerErrorToast";

import Button, { ButtonTypes } from "../Components/Button";
import LoadingSpinner from "../Components/LoadingSpinner";

const EnableOTPForm: FC<{
  otpIsEnabled: boolean;
  onCloseRequest: (result: {
    otpEnabled?: boolean;
    changeMade: boolean;
  }) => void;
}> = ({ onCloseRequest, otpIsEnabled }) => {
  const { t } = useTranslation([
    "common_forms",
    "common_user",
    "server_messages",
  ]);
  const { addToast } = useToasts();
  const { addServerErrorToast } = useServerErrorToast();
  const isDarkMode = useIsDarkMode();

  const [OTPSecretUrl, setOTPSecretUrl] = useState<string | null>(null);
  const [OTPCode, setOTPCode] = useState<string>("");
  const [checkingCode, setCheckingCode] = useState<boolean>(false);

  useEffect(() => {
    if (!otpIsEnabled) {
      generateOTPSecretUrl()
        .then((url) => {
          setOTPSecretUrl(url);
        })
        .catch((e) => {
          setOTPSecretUrl("already enabled.");
        });
    }
  }, [otpIsEnabled]);

  return (
    <>
      {!!OTPSecretUrl || otpIsEnabled ? (
        <form
          className="flex flex-col p-4 w-96"
          onSubmit={(e) => e.preventDefault()}
        >
          <h2 className="text-lg font-bold mb-2">
            {otpIsEnabled
              ? t("common_user:disable2fa")
              : t("common_user:enable2fa")}
          </h2>
          {otpIsEnabled ? (
            <p>{t("common_user:disable2faExplainer")}</p>
          ) : (
            <>
              <p>{t("common_user:enable2faExplainer")}</p>
              {OTPSecretUrl && (
                <QRCode
                  value={OTPSecretUrl}
                  className={classNames(
                    "mt-2 self-center",
                    isDarkMode && "border-white border-4"
                  )}
                />
              )}
            </>
          )}
          <label htmlFor="otp-code" className="mt-2">
            {t("common_user:OTPCode")}
          </label>
          <input
            type="text"
            inputMode="numeric"
            autoComplete="none"
            className="input mb-4 px-2 py-1"
            id="otp-code"
            name="otpCode"
            tabIndex={1}
            value={OTPCode || ""}
            onChange={(e) => setOTPCode(e.target.value)}
            style={{
              appearance: "textfield",
            }}
          />
          <div className="flex self-end">
            <Button
              type="button"
              className="mr-2"
              onClick={() => {
                onCloseRequest({ changeMade: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={OTPCode.length !== 6}
              buttonType={ButtonTypes.positive}
              loading={checkingCode}
              onClick={async (e) => {
                e.preventDefault();
                setCheckingCode(true);
                try {
                  await setOTPEnabled({
                    enable: !otpIsEnabled,
                    token: OTPCode!,
                  });
                  addToast(
                    otpIsEnabled
                      ? t("common_user:2faDisableSuccessToast")
                      : t("common_user:2faEnableSuccessToast"),
                    { appearance: "success", autoDismiss: true }
                  );
                  onCloseRequest({
                    otpEnabled: !otpIsEnabled,
                    changeMade: true,
                  });
                } catch (e) {
                  // t("server_messages:error", {context: "USER_OTP_INVALID_TOKEN"});
                  addServerErrorToast(e);
                } finally {
                  setCheckingCode(false);
                }
              }}
            >
              {otpIsEnabled
                ? t("common_user:disable2fa_short")
                : t("common_user:enable2fa_short")}
            </Button>
          </div>
        </form>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default EnableOTPForm;
