import React, { FC, useEffect, useState } from "react";

import { ArrowContainer, Popover } from "react-tiny-popover";

import classNames from "classnames";

import LoadingSpinner from "./LoadingSpinner";

export enum ButtonTypes {
  neutral,
  positive,
  destructive,
}

const colours = {
  [ButtonTypes.neutral]: "bg-gray-800",
  [ButtonTypes.positive]: "bg-green-800",
  [ButtonTypes.destructive]: "bg-red-800",
};

const Button: FC<
  {
    loading?: boolean;
    buttonType?: ButtonTypes;
    disabledReason?: string;
  } & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({
  buttonType = ButtonTypes.neutral,
  disabledReason,
  disabled,
  loading = false,
  onClick,
  children,
  className,
  ...rest
}) => {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  useEffect(() => {
    if (!disabledReason) setPopoverOpen(false);
  }, [disabledReason]);
  return disabledReason ? (
    <Popover
      isOpen={popoverOpen}
      positions={["bottom", "top", "left", "right"]}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={"#d1d5db"}
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div className="bg-gray-300 p-1 text-xs rounded-sm">
            {disabledReason}
          </div>
        </ArrowContainer>
      )}
    >
      <button
        // If we supply a disabledReason we should not disable the button so we can
        // still mouseover to see the reason.
        disabled={!disabledReason && disabled}
        aria-disabled={disabled || !!disabledReason}
        onMouseMove={() => {
          if (disabledReason && !popoverOpen) setPopoverOpen(true);
        }}
        onMouseLeave={() => {
          if (popoverOpen) setPopoverOpen(false);
        }}
        className={classNames(
          "px-2 py-1 rounded-sm text-white",
          colours[buttonType],
          className,
          loading && "cursor-wait",
          (!!disabledReason || disabled) && "opacity-50 cursor-not-allowed"
        )}
        onClick={(e) => {
          !loading && onClick && onClick(e);
        }}
        {...rest}
      >
        {!loading ? (
          children
        ) : (
          // Note the spaces are here to make the loading spinner take up the
          // same height as one line of text.
          <div className="flex flex-row items-center justify-center">
            <div className="invisible">{children}</div>
            <LoadingSpinner className="absolute" />
          </div>
        )}
      </button>
    </Popover>
  ) : (
    <button
      // If we supply a disabledReason we should not disable the button so we can
      // still mouseover to see the reason.
      disabled={disabled}
      aria-disabled={disabled}
      className={classNames(
        "px-2 py-1 rounded-sm text-white",
        colours[buttonType],
        className,
        loading && "cursor-wait",
        disabled && "opacity-50 cursor-not-allowed"
      )}
      onClick={(e) => {
        !loading && onClick && onClick(e);
      }}
      {...rest}
    >
      {!loading ? (
        children
      ) : (
        // Note the spaces are here to make the loading spinner take up the
        // same height as one line of text.
        <div className="flex flex-row items-center justify-center">
          <div className="invisible">{children}</div>
          <LoadingSpinner className="absolute" />
        </div>
      )}
    </button>
  );
};

export default Button;
