import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { FiGrid, FiList } from "react-icons/fi";
import { MdRefresh, MdSwapVert } from "react-icons/md";

import classNames from "classnames";
import isEqual from "lodash/isEqual";

import { Backdrop, getBackdrops } from "../api/backdrops";
import { getEndpointSummary, EndpointSummary } from "../api/endpoints";
import { getGroups, Group } from "../api/groups";

import useIntervalWhenVisible from "../Hooks/useIntervalWhenVisible";

import BackdropLightBox from "../Components/BackdropLightBox";
import GroupListItem from "../Components/GroupListItem";
import GroupTile from "../Components/GroupTile";
import GroupsPageLightBoxMetaSection from "../Components/GroupsPageLightBoxMetaSection";
import LoadingSpinner from "../Components/LoadingSpinner";

const GroupsPage: FC<{}> = () => {
  const [backdrops, setBackdrops] = useState<Backdrop[] | null>(null);
  const [groups, setGroups] = useState<Group[] | null>(null);
  const [endpointSummaries, setEndpointSummaries] = useState<
    EndpointSummary[] | null
  >(null);
  const [useGridView, setUseGridView] = useState<boolean>(false);
  const [statusRefreshing, setStatusRefreshing] = useState<boolean>(false);
  const [
    swapDesktopAndLockscreenPreviews,
    setSwapDesktopAndLockscreenPreviews,
  ] = useState<boolean>(false);
  const [previewingBackdropDetails, setPreviewingBackdropDetails] = useState<{
    backdrop: Backdrop | null;
    group: Group;
    resolution: string;
  } | null>(null);

  const { t } = useTranslation(["common_objects", "page_groups"]);

  const getSummary = useCallback(() => {
    setStatusRefreshing(true);
    getEndpointSummary()
      .then((eps) => {
        setStatusRefreshing(false);
        setEndpointSummaries((prevSummaries) => {
          if (!prevSummaries) return eps;
          const newSummaries = prevSummaries.map((previous) => {
            let newSummary = eps.find((s) => s.groupId === previous.groupId);
            if (
              !newSummary ||
              newSummary.total !== previous.total ||
              !isEqual(
                newSummary.downloads.desktop,
                previous.downloads.desktop
              ) ||
              !isEqual(
                newSummary.downloads.lockscreen,
                previous.downloads.lockscreen
              )
            ) {
              return newSummary;
            }
            return previous;
          });

          // It's possible that an old summary gets removed (will be undefined)
          // so filter them out
          return newSummaries.filter((s) => !!s) as EndpointSummary[];
        });
      })
      .catch((e) => {
        // Do nothing.
      });
  }, []);

  // Refresh summary data every 30s.
  useIntervalWhenVisible(getSummary, 30000, true);

  useEffect(() => {
    // TODO: error handling.
    getBackdrops().then((bds) => {
      setBackdrops(bds);
    });
    getGroups().then((groups) => {
      setGroups(groups);
    });
  }, [getSummary]);

  // Get a list of all resolutions across all groups (for add res modal).
  const allResolutions = useMemo(() => {
    return (endpointSummaries || []).reduce((allResolutions: string[], eps) => {
      Object.keys(eps.resolutions).forEach((res) => {
        if (!allResolutions.includes(res)) allResolutions.push(res);
      });
      return allResolutions;
    }, []);
  }, [endpointSummaries]);

  // Wait for all data to load before displaying anything on the page.
  const isLoading =
    backdrops === null || groups === null || endpointSummaries == null;

  const onGroupDeleted = useCallback(
    (groupId: string) =>
      setGroups((groups) => groups!.filter((g) => g._id !== groupId)),
    []
  );

  const onGroupModified = useCallback(
    (group: Group) => {
      setGroups((groups) =>
        groups!.map((g) => (g._id === group._id ? group : g))
      );
      console.log("group modified, let`s get a new summary");
      getSummary();
    },
    [getSummary]
  );

  const onThumbnailClick = useCallback(
    (backdropId, resolution, groupId) =>
      setPreviewingBackdropDetails({
        backdrop: backdrops?.find((bd) => bd._id === backdropId) || null,
        resolution: resolution,
        group: groups?.find((group) => group._id === groupId)!,
      }),
    [backdrops, groups]
  );

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {!!previewingBackdropDetails && (
            <BackdropLightBox
              metadataSection={
                <GroupsPageLightBoxMetaSection
                  {...previewingBackdropDetails}
                  onChangeBackdropType={(newType) => {
                    setPreviewingBackdropDetails((prev) => ({
                      group: prev!.group,
                      backdrop: backdrops!.find(
                        (bd) => bd._id === prev!.group.backdrops[newType]
                      )!,
                      resolution: prev!.resolution,
                    }));
                  }}
                  onChangeResolution={(newRes) => {
                    setPreviewingBackdropDetails((prev) => ({
                      ...prev!,
                      resolution: newRes,
                    }));
                  }}
                />
              }
              backdrop={previewingBackdropDetails.backdrop || null}
              resolution={previewingBackdropDetails.resolution}
              onCloseRequest={() => {
                setPreviewingBackdropDetails(null);
              }}
            />
          )}
          {/* CHANGE 'hidden' to 'flex' to display switch again */}
          <div className="flex-row text-xl mx-6 mt-2 -mb-4 justify-end hidden">
            <button
              type="button"
              className={classNames(
                "p-2 cursor-pointer font-black",
                !useGridView ? "opacity-50" : "opacity-100"
              )}
              onClick={() => setUseGridView(true)}
            >
              <FiGrid />
            </button>
            <button
              type="button"
              className={classNames(
                "p-2 cursor-pointer font-black",
                useGridView ? "opacity-50" : "opacity-100"
              )}
              onClick={() => setUseGridView(false)}
            >
              <FiList />
            </button>
          </div>

          {useGridView ? (
            <div className="grid grid-cols-3">
              {groups &&
                groups.map((group) => {
                  return (
                    <GroupTile
                      backdrops={backdrops || []}
                      allResolutions={allResolutions || []}
                      key={group._id + "tile"}
                      group={group}
                      endpointSummary={
                        endpointSummaries!.find(
                          (eps) => eps.groupId === group._id
                        )!
                      }
                      onGroupDeleted={onGroupDeleted}
                      onGroupModified={onGroupModified}
                    />
                  );
                })}
            </div>
          ) : (
            <div
              className="grid grid-cols-7 p-6 gap-x-2 w-100 items-center"
              style={{
                gridTemplateColumns: "10rem repeat(6, auto)",
              }}
            >
              <div className="contents font-bold">
                <div className="flex flex-row items-center">
                  <span>{t("common_objects:backdrop")}</span>
                  <button
                    className="inline-block p-1 cursor-pointer"
                    onClick={(e) => {
                      setSwapDesktopAndLockscreenPreviews((p) => !p);
                    }}
                  >
                    <MdSwapVert className="transform rotate-45" />
                  </button>
                </div>
                <div>{t("page_groups:GroupNameHeader")}</div>
                <div>{t("page_groups:NumComputersHeader")}</div>
                <div className="flex flex-row items-center">
                  {t("page_groups:DesktopStatusHeader")}
                  <button
                    className={classNames(
                      "inline-block p-1 cursor-pointer",
                      statusRefreshing &&
                        "animate-spin text-gray-500 cursor-not-allowed"
                    )}
                    onClick={statusRefreshing ? () => {} : getSummary}
                  >
                    <MdRefresh />
                  </button>
                </div>
                <div className="flex flex-row items-center">
                  {t("page_groups:LockscreenStatusHeader")}
                  <span
                    className={classNames(
                      "inline-block p-1 cursor-pointer",
                      statusRefreshing &&
                        "animate-spin text-gray-500 cursor-not-allowed"
                    )}
                    onClick={statusRefreshing ? () => {} : getSummary}
                  >
                    <MdRefresh />
                  </span>
                </div>
                <div>{t("page_groups:ResolutionsHeader")}</div>
                <div>&nbsp;</div>
                <div className="col-span-7 border-b-4 border-gray-200 dark:border-gray-600 mt-2 mb-4"></div>
              </div>
              {groups &&
                groups.map((group) => {
                  return (
                    <GroupListItem
                      backdrops={backdrops || []}
                      swapDesktopAndLockscreen={
                        swapDesktopAndLockscreenPreviews
                      }
                      onThumbnailClick={onThumbnailClick}
                      allResolutions={allResolutions || []}
                      key={group._id + "tile"}
                      group={group}
                      endpointSummary={
                        endpointSummaries!.find(
                          (eps) => eps.groupId === group._id
                        )!
                      }
                      onGroupDeleted={onGroupDeleted}
                      onGroupModified={onGroupModified}
                    />
                  );
                })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default GroupsPage;
