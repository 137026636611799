import { AxiosError } from "axios";

import { clearSession } from "../Hooks/useSession";

export class ApiError extends Error {
  constructor(message: string, public code: number, public tid: string) {
    super(message);
  }
}

type ApiErrorFormat = {
  result: string;
  tid: string;
};

export const processApiError: (caughtError: any) => ApiError = (
  caughtError
) => {
  // First assert if the error we've caught is a server error...
  if (!!caughtError.request && !!caughtError.response) {
    // This is an axios error.
    const axiosError: AxiosError<ApiErrorFormat> = caughtError;
    if (caughtError.response.status === 401) {
      clearSession();
    }
    return new ApiError(
      axiosError.response!.data.result,
      axiosError.response!.status,
      axiosError.response!.data.tid
    );
  } else {
    return new ApiError(caughtError.message, caughtError.code || -1, "");
  }
};
