import { FC, FormEvent, useRef, useState } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { resetPassword } from "../api/auth";

import useIsDarkMode from "../Hooks/useIsDarkMode";

import Button from "../Components/Button";

const ResetPasswordPage: FC<{}> = () => {
  const { t } = useTranslation(["common_forms", "common_user", "page_login"]);
  const { push } = useHistory();
  const isDarkMode = useIsDarkMode();
  const recaptchaRef = useRef<ReCAPTCHA>();

  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const recaptcha = await recaptchaRef.current?.executeAsync();
      if (!recaptcha) throw new Error("Recaptcha not found");
      await resetPassword({ email, recaptcha });
    } catch (e) {
      setErrored(true);
    } finally {
      setSubmitted(true);
    }
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center text-base">
      <form
        onSubmit={onSubmit}
        className="flex flex-col w-72 p-4 border border-black dark:border-gray-600 rounded-sm"
        // Prevent the browser validating the form - we'll do it.
      >
        {submitted ? (
          <div className="flex flex-col">
            {errored ? (
              <>
                <p className="mb-2">{t("page_login:passwordResetError")}</p>
                <Button type="button" onClick={() => window.location.reload()}>
                  {t("page_login:clickToRefresh")}
                </Button>
              </>
            ) : (
              <>
                <p className="mb-2">{t("page_login:passwordResetEmailSent")}</p>
                <Button type="button" onClick={() => push("/login")}>
                  {t("common_forms:continune")}
                </Button>
              </>
            )}
          </div>
        ) : (
          <>
            <h1 className="mb-2 text-lg font-bold">
              {t("page_login:requestPasswordReset")}
            </h1>
            <p className="leading-tight mb-2">
              {t("page_login:passwordResetExplainer")}
            </p>
            <label htmlFor="login-email">{t("common_user:email")}</label>
            <input
              type="email"
              className="input mb-4 px-2 py-1"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              autoComplete="email"
              id="login-email"
              name="username"
              tabIndex={1}
            />
            <ReCAPTCHA
              sitekey="6LepuFAaAAAAAMNbE4TSjxjwgcEcetp1g6d9yf2L"
              size="invisible"
              badge="bottomleft"
              // @ts-ignore
              ref={recaptchaRef}
              theme={isDarkMode ? "dark" : "light"}
            />
            <Button type="submit" loading={loading}>
              {t("common_forms:submit")}
            </Button>
          </>
        )}
      </form>
    </div>
  );
};

export default ResetPasswordPage;
