import { FC, Reducer, useCallback, useEffect, useReducer } from "react";

import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { default as CreatableSelect } from "react-select/creatable";

import { getGroups, Group } from "../api/groups";

import Button, { ButtonTypes } from "../Components/Button";
import Callout, { CalloutTypes } from "../Components/Callout";
import selectCustomStyles from "../Components/selectCustomStyles";

type NewBackdropPageState = {
  groups: Group[] | null;
  resolutions: string[];
};
type NewBackdropFormData = {
  name: string;
  targetGroups: { label: string; value: string; __isNew__?: boolean }[];
  resolutions: { label: string; value: string; __isNew__?: boolean }[];
};

export type NewBackdropMeta = {
  name: string;
  groups: Group[];
  resolutions: string[];
};
const newBackdropPageDefaultState: NewBackdropPageState = {
  groups: null,
  resolutions: [],
};

const NewBackdropPageReducer: Reducer<
  NewBackdropPageState,
  { type: string; payload: any }
> = (state, action) => {
  switch (action.type) {
    case "SET_GROUPS": {
      const allResolutions = (action.payload as Group[]).reduce(
        (resolutions: string[], nextGroup) => {
          nextGroup.formats
            .map((format) => format.resolution)
            .forEach((resolution) => {
              if (!resolutions.includes(resolution))
                resolutions.push(resolution);
            });
          return resolutions;
        },
        []
      );
      return {
        ...state,
        groups: action.payload,
        resolutions: allResolutions,
      };
    }
  }
  return state;
};

const NewBackdropForm: FC<{
  onComplete: (newBackdropMeta: NewBackdropMeta) => void;
  initialTargetGroupIdentifier: string | null;
}> = ({ onComplete, initialTargetGroupIdentifier }) => {
  const history = useHistory();
  // Get groups.
  useEffect(() => {
    getGroups()
      .then((groups) => {
        dispatch({ type: "SET_GROUPS", payload: groups });
      })
      .catch((e) => {
        // TODO: Error handling on get groups for new backdrop.
      });
  }, []);

  const { t } = useTranslation([
    "page_backdrops",
    "common_forms",
    "common_actions",
    "server_messages",
  ]);

  const [state, dispatch] = useReducer(
    NewBackdropPageReducer,
    newBackdropPageDefaultState
  );

  const { register, handleSubmit, control, setValue, getValues, errors } =
    useForm<NewBackdropFormData>();

  const groupsLoading = state.groups === null;
  const noGroups = state.groups?.length === 0;

  const onSubmit = handleSubmit((data) => {
    onComplete({
      name: data.name,
      groups: data.targetGroups.map(
        (group) => (state.groups || []).find((g) => g._id === group.value)!
      ),
      resolutions: data.resolutions.map((res) => res.value),
    });
  });

  const setTargetResolutionsFromGroups = useCallback(() => {
    if (noGroups) return [];
    const targetGroups = getValues().targetGroups;
    if (!targetGroups) return [];
    const selectedGroupIds = targetGroups.map((g) => g.value);
    const selectedGroups = state.groups!.filter((group) => {
      return selectedGroupIds.includes(group._id);
    });
    // This will be an array of arrays of resolutions
    const resolutions = selectedGroups.map((group) =>
      group.formats.map((format) => format.resolution)
    );
    const uniqueResolutions = resolutions.reduce(
      (resolutionsArray, nextGroupResoltions) => {
        nextGroupResoltions.forEach((res) => {
          if (!resolutionsArray.includes(res)) {
            resolutionsArray.push(res);
          }
        });
        return resolutionsArray;
      },
      []
    );

    const selectedResolutions = getValues().resolutions || [];
    const toAdd: string[] = [];
    uniqueResolutions.forEach((res) => {
      if (
        !selectedResolutions.find((selectedRes) => selectedRes.value === res)
      ) {
        if (!toAdd.includes(res)) toAdd.push(res);
      }
    });
    const arrayWithoutDeselected = selectedResolutions.filter((selectedRes) => {
      return uniqueResolutions.includes(selectedRes.value);
    });
    toAdd.forEach((res) => {
      arrayWithoutDeselected.push({ label: res, value: res });
    });
    setValue("resolutions", arrayWithoutDeselected);
  }, [getValues, state.groups, setValue, noGroups]);

  useEffect(() => {
    if (!state.groups || !initialTargetGroupIdentifier) return;
    const group = state.groups.find(
      (g) => g.identifier === initialTargetGroupIdentifier
    );
    if (!group) return;
    setValue("targetGroups", [
      {
        label: group.identifier,
        value: group._id,
      },
    ]);
    setTargetResolutionsFromGroups();
  }, [
    initialTargetGroupIdentifier,
    state.groups,
    setValue,
    setTargetResolutionsFromGroups,
  ]);

  return (
    <div style={{ width: "32rem", maxWidth: "80vw" }}>
      <form className="flex flex-col p-2" onSubmit={onSubmit} noValidate>
        <label htmlFor="backdrop-name">{t("common_forms:name")}</label>
        <input
          type="text"
          className="input mb-4 px-2 py-1"
          id="backdrop-name"
          name="name"
          ref={register({ required: true })}
        />

        <label htmlFor="backdrop-targets">
          {t("page_backdrops:targetGroups")}
        </label>
        <Controller
          control={control}
          name="targetGroups"
          defaultValue={[]}
          render={(props, meta) => (
            <CreatableSelect
              styles={selectCustomStyles}
              className="mb-4"
              isLoading={groupsLoading}
              menuPortalTarget={document.body}
              options={
                !noGroups && !groupsLoading
                  ? state.groups!.map((group) => ({
                      label: group.identifier,
                      value: group._id,
                    }))
                  : []
              }
              isValidNewOption={(inputValue) => {
                return !!/^\d+x\d+$/.exec(inputValue);
              }}
              id="backdrop-targets"
              {...props}
              isMulti={true}
              onChange={(value, action) => {
                props.onChange(value, action);
                setTargetResolutionsFromGroups();
              }}
            />
          )}
        ></Controller>

        <label htmlFor="backdrop-targets">
          {t("page_backdrops:targetResolutions")}
        </label>
        <div className="flex w-100 mb-4">
          <Controller
            control={control}
            name="resolutions"
            defaultValue={[]}
            rules={{
              validate: (value: any[]) => {
                return !!value.length;
              },
            }}
            render={(props) => (
              <CreatableSelect
                styles={selectCustomStyles}
                className="flex-1"
                isLoading={groupsLoading}
                options={
                  !noGroups && !groupsLoading
                    ? state.resolutions!.map((res) => ({
                        label: res,
                        value: res,
                      }))
                    : []
                }
                menuPortalTarget={document.body}
                id="backdrop-resolutions"
                {...props}
                isMulti={true}
              />
            )}
          ></Controller>
          <button
            className="button ml-2"
            type="button"
            onClick={setTargetResolutionsFromGroups}
          >
            {t("page_backdrops:resetToResolutionsInGroups")}
          </button>
        </div>
        {!!errors.name && (
          <Callout
            type={CalloutTypes.error}
            className="mb-4"
            content={t("common_forms:error", {
              context: "namedRequiredField",
              fieldName: t("common_forms:name", {
                context: "backdrop",
              }),
            })}
          />
        )}
        {!!errors.resolutions && (
          <Callout
            type={CalloutTypes.error}
            className="mb-4"
            content={t("page_backdrops:atLeastOneResolutionRequired")}
          />
        )}
        <div className="flex flex-row justify-end">
          <Button
            type="button"
            className="mr-2"
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            {t("common_actions:cancel")}
          </Button>
          <Button buttonType={ButtonTypes.positive}>
            {t("page_backdrops:createNewBackdrop")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NewBackdropForm;
