import { FC, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { RiFileCopyLine, RiCheckFill } from "react-icons/ri";
import { Link } from "react-router-dom";

import classNames from "classnames";

import useQuery from "../Hooks/useQuery";
import useSession from "../Hooks/useSession";

const ErrorPage: FC<{}> = () => {
  const queryParams = useQuery();
  const errorKey = queryParams.get("error");
  const tid = queryParams.get("tid");

  const { session } = useSession();

  const timestamp = useRef(Date.now());
  const { t } = useTranslation([
    "page_error",
    "common_actions",
    "server_messages",
  ]);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);

  return (
    <div className="w-full h-full flex flex-col items-center text-base p-6">
      <div className="max-w-sm">
        <h2 className="text-lg font-bold mb-2">
          {t("server_messages:somethingWentWrong")}
        </h2>
        <p className="mb-4">
          {t("server_messages:error", { context: errorKey })}
        </p>
        <p className="mb-2 text-sm">{t("page_error:raiseTicketForHelp")}</p>
        <pre className="px-2 py-1 font-mono rounded-sm border bg-gray-600 border-gray-500 dark:bg-gray-800 dark:border-gray-700 text-white text-xs relative">
          <span className="font-bold mr-1">ERR:</span>
          <span>{errorKey}</span>
          <br />
          <span className="font-bold mr-1">TID:</span>
          <span>{tid}</span>
          <br />
          <span className="font-bold mr-1">&nbsp;TS:</span>
          <span>{timestamp.current}</span>
          <span
            className={classNames(
              "absolute bottom-0 font-sans text-green-500 font-semibold flex items-center transition-opacity duration-300 cursor-pointer",
              copySuccess ? "opacity-100" : "opacity-0"
            )}
            style={{
              right: "2.5em",
              bottom: "0.25em",
            }}
          >
            <RiCheckFill className="text-lg mr-1" />
            {t("common_actions:clipboard", { context: "pastTense" })}
          </span>
          <button
            className="absolute bottom-0 right-0 px-2 py-1 text-base focus:outline-none focus:bg-gray-200 focus:bg-opacity-20 rounded"
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(
                  `ERR: ${errorKey}\nTID: ${tid}\nTS: ${timestamp.current}`
                );
                setCopySuccess(true);
                setTimeout(() => {
                  setCopySuccess(false);
                }, 2500);
              } catch {
                // Failed to copy to clipboard.
              }
            }}
          >
            <RiFileCopyLine />
          </button>
        </pre>
      </div>
      {!session && (
        <Link
          to="/login"
          className="underline text-blue-800 dark:text-blue-300 mt-4 text-sm"
        >
          {t("page_error:goToLogin")}
        </Link>
      )}
    </div>
  );
};

export default ErrorPage;
