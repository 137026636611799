import { FC } from "react";

import { useTranslation } from "react-i18next";
import { RiExternalLinkLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

import classNames from "classnames";

import useSession from "../Hooks/useSession";

import DarkModeSwitch from "./DarkModeSwitch";
import LoggedInUserDetails from "./LoggedInUserDetails";

const ADMIN_ONLY_ROUTES = ["user"];

const TopMenu: FC<{}> = () => {
  const location = useLocation();
  const { t } = useTranslation(["common_objects"]);
  const { session } = useSession();

  return (
    <div className="w-100 px-4 py-2 border-b border-gray-200 dark:border-gray-600 flex flex-row justify-between items-center">
      <div className="flex flex-row">
        <img
          className="h-10 mr-6"
          src="/pixelnebula/icon-square-transparent.png"
          alt="pixelnebula logo"
        />
        <div className="flex flex-row text-lg items-center">
          {["group", "backdrop", "user", "app"]
            .filter(
              // Filter out admin only routes for non-admins.
              (route) =>
                !ADMIN_ONLY_ROUTES.includes(route) ||
                session?.user._id === session?.account.administrator
            )
            .map((route) => (
              <Link
                key={route}
                to={route + "s"} // (routes are plural)
                className={classNames(
                  "mr-6",
                  location.pathname.includes(route) ? "font-bold" : "opacity-50"
                )}
              >
                {/* t("common_objects:group", {count: 5}) */}
                {/* t("common_objects:backdrop", {count: 5}) */}
                {/* t("common_objects:user", {count: 5}) */}
                {/* t("common_objects:app", {count: 5}) */}
                {/* @ts-ignore */}
                {t(`common_objects:${route}`, { count: 5 })}
              </Link>
            ))}
          <a
            href="/docs"
            className="flex items-center opacity-50"
            target="_blank"
          >
            <span>{t("common_objects:userGuide")}</span>
            <RiExternalLinkLine className="ml-1 text-sm" />
          </a>
        </div>
      </div>

      <div className="flex flex-row">
        <DarkModeSwitch className="mr-2" />
        <LoggedInUserDetails />
      </div>
    </div>
  );
};

export default TopMenu;
