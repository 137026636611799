import { FC, useEffect } from "react";

import { Redirect } from "react-router-dom";

import { logout } from "../api/auth";

import useSession from "../Hooks/useSession";

const Logout: FC<{}> = () => {
  const { clearSession } = useSession();

  useEffect(() => {
    const doLogout = async () => {
      try {
        await logout();
        clearSession();
      } catch (e) {
        // TODO: error logging out, toast suggesting clearing cookies instead.
      }
    };
    doLogout();
  }, [clearSession]);

  return <Redirect to="/login" />;
};

export default Logout;
