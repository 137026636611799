import React, {
  FC,
  MouseEvent,
  Reducer,
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import combineReducers from "react-combine-reducers";
import { useHotkeys } from "react-hotkeys-hook";

import classNames from "classnames";

import useIsDarkMode from "./Hooks/useIsDarkMode";
import { useCanvasPointerPosition } from "./Hooks/usePointerPosition";

import { TransformTypes } from "./BackdropEditor";
import {
  CanvasObject,
  CanvasObjectHandle,
  CanvasObjectTypes,
  getObjectBounds,
  getObjectHandles,
  HandlePositions,
  ObjectPosition,
} from "./CanvasObject";
import drawBackdrop from "./drawBackdrop";

/**
 * IMPORTANT NOTE: the canvas will draw the backdrop ACTUAL SIZE. Canvas width
 * (*not* canvasContainerWidth) is the number of pixels wide that the canvas is.
 * CSS then fits the canvas into the container. So a canvas that is wider than
 * the backdrop will result in a zoomed out view of the backdrop with a
 * blank work area around it, and when narrower, it'll be a zoomed in and
 * cropped view of the backdrop.
 */

type ActionTypes = {
  type: "CONTAINER_RESIZED";
  payload: { width: number; height: number };
};

export type BackdropObjectMeta = {
  id: string;
  type: CanvasObjectTypes;
  position: ObjectPosition;
  selected: boolean;
};

const getZoomToFitBackdropInCanvas: (options: {
  canvasContainerSize: [number, number];
  backdropSize: [number, number];
  paddingPx?: number;
}) => number = ({ canvasContainerSize, backdropSize, paddingPx = 40 }) => {
  let backdropAspectRatio = backdropSize[0] / backdropSize[1];
  let canvasAspectRatio = canvasContainerSize[0] / canvasContainerSize[1];

  if (backdropAspectRatio > canvasAspectRatio) {
    // e.g. landscape backdrop in portrait container -> zoom out to 100% with
    let targetCanvasWidth = backdropSize[0];
    // containerWidth / zoom = cavnasPx
    // zoom = containerWidth / canvasWidth;
    return (canvasContainerSize[0] - paddingPx * 2) / targetCanvasWidth;
  } else {
    let targetCanvasHeight = backdropSize[1];
    return (canvasContainerSize[1] - paddingPx * 2) / targetCanvasHeight;
  }
};

const pointIsWithinBox = (
  point: { x: number; y: number },
  box: {
    x: number;
    y: number;
    width: number;
    height: number;
  }
) => {
  if (point.x < box.x || point.y < box.y) return false;
  if (point.x > box.x + box.width) return false;
  if (point.y > box.y + box.height) return false;
  return true;
};

type BackdropMockupState = {
  canvas: {
    containerWidth: number;
    containerHeight: number;
  };
  backdrop: {
    backdropResolutionX: number;
    backdropResolutionY: number;
    aspectRatio: number;
  };
};

const canvasInitialState = {
  containerWidth: 0,
  containerHeight: 0,
  objectUnderCursor: null,
};

const backdropInitialState = {
  backdropResolutionX: 1920,
  backdropResolutionY: 1080,
  aspectRatio: 16 / 9,
};

export enum TranslationTypes {
  relative,
  absolute,
}

const [backdropMockupReducer, backdropMockupInitialState]: [
  Reducer<BackdropMockupState, ActionTypes>,
  BackdropMockupState
] = combineReducers({
  canvas: [
    (state, action: ActionTypes) => {
      switch (action.type) {
        case "CONTAINER_RESIZED": {
          let payload = action.payload;
          return {
            ...state,
            containerWidth: payload.width,
            containerHeight: payload.height,
          };
        }
        default:
          return state;
      }
    },
    canvasInitialState,
  ],
  backdrop: [
    (state, action: ActionTypes) => {
      switch (action.type) {
        default:
          return state;
      }
    },
    backdropInitialState,
  ],
});

const BackdropMockup: FC<{
  backgroundColor?: string;
  resolution: string;
  isEditable?: boolean;
  className?: string;
  zoom?: number | null;
  pan?: [number, number];
  initialZoomPadding?: number;
  objectLibrary: CanvasObject[];
  objects: {
    [id: string]: BackdropObjectMeta;
  };
  onZoom: (zoomRequest: { type: TranslationTypes; amount: number }) => any;
  onPan?: (panRequest: {
    type: TranslationTypes;
    amount: [number, number];
  }) => any;
  onObjectSelected?: (objectId: string | null) => void;
  onSetZoomRange?: (range: { min: number; max: number }) => void;
  onObjectTransform?: (
    objectId: string,
    transformType: TransformTypes,
    amount: { dx: number; dy: number },
    details?: {
      handlePosition: HandlePositions | null;
      constrainAspectRatio: boolean;
    }
  ) => void;
}> = ({
  backgroundColor = "#000",
  isEditable = true,
  className,
  resolution,
  initialZoomPadding,
  zoom = 1,
  pan = [0, 0],
  onZoom,
  onSetZoomRange,
  onPan,
  onObjectSelected,
  onObjectTransform,
  objectLibrary,
  objects,
}) => {
  const [state, dispatch] = useReducer(
    backdropMockupReducer,
    backdropMockupInitialState
  );

  // Keep state to know if spacebar is pressed for initiating panning.
  const [spacebarPressed, setSpacebarPressed] = useState<boolean>(false);
  useHotkeys(
    "space",
    (event) => {
      // This prevents holding down the key spamming renders.
      if (event.repeat) return;
      setSpacebarPressed(event.type !== "keyup");
    },
    { keyup: true, keydown: true }
  );

  const isDarkMode = useIsDarkMode();
  const rafRef = useRef<number | null>(null);
  const [lastClickWasUsedToDrag, setLastClickWasUsedToDrag] =
    useState<boolean>(false);

  const [fontLoaded, setFontLoaded] = useState<boolean>(false);

  const [xRes, yRes] = resolution.split("x");
  const backdropResolutionY = parseInt(yRes);
  const backdropResolutionX = parseInt(xRes);

  // Managing the canvas size & refs.
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const pointerCanvasCoords = useCanvasPointerPosition(canvasRef.current);

  let centerCanvasX: number,
    centerCanvasY: number,
    backdropOrigin: { x: number; y: number } | undefined;
  let objectUnderCursor: BackdropObjectMeta | CanvasObjectHandle | null = null;
  let objectHandles: CanvasObjectHandle[] = [];
  if (canvasRef.current && zoom) {
    centerCanvasX = state.canvas.containerWidth / zoom / 2;
    centerCanvasY = state.canvas.containerHeight / zoom / 2;

    // Move to top left of where we will draw backdrop.
    backdropOrigin = {
      x: centerCanvasX - backdropResolutionX / 2 + pan[0],
      y: centerCanvasY - backdropResolutionY / 2 + pan[1],
    };

    let objectIds = Object.keys(objects);
    if (objectIds.length && pointerCanvasCoords && isEditable) {
      let selectedObject = Object.values(objects).find((o) => o.selected);
      let objectsAndHandles: (BackdropObjectMeta | CanvasObjectHandle)[] = [
        ...Object.values(objects),
      ];
      if (selectedObject) {
        // If there's an object selected we also want to add in the handles.
        objectHandles = getObjectHandles(
          objectLibrary.find((o) => o.id === selectedObject!.id)!,
          selectedObject.position.origin,
          selectedObject.position.scale,
          //  NOTE: handle hitbox size is bigger than the actual handle.
          //  TODO: make these two consts.
          15 / zoom
        );
        objectsAndHandles = objectsAndHandles.concat(objectHandles);
      }
      objectsAndHandles
        // First sort by reverse z index (highest first)
        .sort((a, b) => b.position.zIndex - a.position.zIndex)
        .forEach((backdropObject, index, array) => {
          // If we've already consumed this click we can just exit early.
          if (objectUnderCursor) return;
          let obj;
          if (backdropObject.type === CanvasObjectTypes.ObjectHandle) {
            obj = backdropObject;
          } else {
            obj = objectLibrary.find((o) => o.id === backdropObject.id)!;
          }
          let objectBounds = getObjectBounds(
            obj as CanvasObject,
            backdropObject.position.origin,
            backdropObject.position.scale
          );
          // Some objects may not have bounds yet (e.g. images that haven't loaded)
          if (!objectBounds) return;
          // Object position is relative to the backdrop, not the canvas, so we
          // need to correct it:
          objectBounds.x += backdropOrigin!.x;
          objectBounds.y += backdropOrigin!.y;
          if (pointIsWithinBox(pointerCanvasCoords, objectBounds)) {
            objectUnderCursor = backdropObject;
          }
        });
    }
  }

  // Load the font first time before rendering
  useEffect(() => {
    const loadFont = async () => {
      const fontFace = new FontFace(
        "SegoeUI",
        [
          'local("Segoe UI Light"),',
          ' url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff2) format("woff2"),',
          ' url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.woff) format("woff"),',
          ' url(//c.s-microsoft.com/static/fonts/segoe-ui/west-european/light/latest.ttf) format("truetype")',
        ].join(""),
        { weight: "100" }
      );
      try {
        await fontFace.load();
        // add font to document
        document.fonts.add(fontFace);
      } catch (e) {
        // TODO: report font loading error.
        console.error("Error loading Segoe UI: " + e.message);
      }
      setFontLoaded(true);
    };
    loadFont();
  }, []);

  useEffect(() => {
    let zoomToFitBackdrop = getZoomToFitBackdropInCanvas({
      canvasContainerSize: [
        state.canvas.containerWidth,
        state.canvas.containerHeight,
      ],
      backdropSize: [backdropResolutionX, backdropResolutionY],
      paddingPx: initialZoomPadding,
    });
    if (onSetZoomRange) {
      // Update the zoomrange to allow this zoom
      onSetZoomRange({
        min: Math.min(0.5, zoomToFitBackdrop * 0.5),
        // max: Math.min(2, Math.max(1, zoomToFitBackdrop * 1.5)),
        max: 2,
      });
    }
  }, [
    backdropResolutionX,
    backdropResolutionY,
    onSetZoomRange,
    initialZoomPadding,
    state.canvas.containerHeight,
    state.canvas.containerWidth,
  ]);

  // Canvas resize observer TODO: cover missing resizeobserver.
  // Important to set canvas width via html/javascript and not css to avoid
  // stretching the image.
  useEffect(() => {
    const container = containerRef.current;
    let observer: ResizeObserver | null = null;
    if (!container) return;

    if (window.ResizeObserver) {
      observer = new ResizeObserver((entries) => {
        dispatch({
          type: "CONTAINER_RESIZED",
          payload: {
            width: Math.ceil(entries[0].contentRect.width),
            height: Math.ceil(entries[0].contentRect.height),
          },
        });
      });

      observer.observe(container, { box: "content-box" });
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [containerRef]);

  // set up a scroll listener to enable panning and zooming the canvas.
  useEffect(() => {
    if (!canvasRef.current || !onPan) return;

    let canvasElement = canvasRef.current;

    const wheelListener = (e: WheelEvent) => {
      // @ts-ignore Note that this is a deprecated property but it still exists
      // in chrome and is a way to check if we're using a trackpad or not.
      if (e.wheelDeltaY === e.deltaY * -3) {
        // Trackpad scroll (Pan!)
        if (e.ctrlKey) {
          // zoom
          e.preventDefault();
          onZoom({ type: TranslationTypes.relative, amount: e.deltaY * 0.01 });
        } else {
          // pan
          e.preventDefault();
          onPan({
            type: TranslationTypes.relative,
            amount: [e.deltaX * -1, e.deltaY * -1],
          });
        }
      } else {
        // regular mousewheel, zoom OR a pinch

        // Mousewheel events are typically much larger than pinches.
        const isProbablyPinch = Math.abs(e.deltaY) < 50;

        const mouseWheelSensitivity = -0.001;
        const pinchSensitivity = -0.0075;

        e.preventDefault();
        onZoom({
          type: TranslationTypes.relative,
          amount:
            e.deltaY *
            (isProbablyPinch ? pinchSensitivity : mouseWheelSensitivity),
        });
      }
    };
    // This is panning with a touchpad.
    canvasElement.addEventListener("wheel", wheelListener, { passive: false });

    return () => {
      if (canvasElement) {
        canvasElement.removeEventListener("wheel", wheelListener);
      }
    };
  }, [onZoom, onPan]);

  // const mouseMoveRafRef = useRef<number | null>(null);
  type DraggingData = {
    isPan: boolean;
    object: BackdropObjectMeta | CanvasObjectHandle | null;
    cumulativeMoveSinceLastCallback: { dx: number; dy: number };
    lastPosition: { x: number; y: number };
    callbackRafRef: number | null;
    mouseStillDown: boolean;
    aspectRatioIsConstrained: boolean;
  } | null;

  const draggingData = useRef<DraggingData>(null);

  const onMouseDown = (event: MouseEvent) => {
    if (draggingData.current || !isEditable) return;
    if (
      !spacebarPressed &&
      (!objectUnderCursor ||
        (objectUnderCursor.type === CanvasObjectTypes.Image &&
          !objectUnderCursor.selected))
    ) {
      // Nothing underneath or we mouse downed on an unselected image.
      // (in which case click handler takes it.)
      return;
    }
    // We've just started dragging something (or panning the screen)
    draggingData.current = {
      isPan: spacebarPressed,
      object: spacebarPressed ? null : objectUnderCursor,
      cumulativeMoveSinceLastCallback: { dx: 0, dy: 0 },
      lastPosition: { x: event.pageX, y: event.pageY },
      callbackRafRef: null,
      mouseStillDown: true,
      aspectRatioIsConstrained: !event.ctrlKey,
    };
    // Don't let this go through to the click handler:
    setLastClickWasUsedToDrag(true);
  };

  const onMouseMove = useCallback(
    (event: globalThis.MouseEvent) => {
      // Do nothing if we're not dragging anything.
      if (!draggingData.current || !draggingData.current.mouseStillDown) return;
      // No need to listen if we can't do anything as a result.
      if (!onObjectTransform && !onPan) return;
      const { lastPosition } = draggingData.current;
      let dx = event.pageX - lastPosition.x;
      let dy = event.pageY - lastPosition.y;
      draggingData.current.lastPosition = { x: event.pageX, y: event.pageY };
      draggingData.current.cumulativeMoveSinceLastCallback.dx +=
        dx / (draggingData.current.isPan ? 1 : zoom!);
      draggingData.current.cumulativeMoveSinceLastCallback.dy +=
        dy / (draggingData.current.isPan ? 1 : zoom!);
      if (draggingData.current.callbackRafRef) {
        cancelAnimationFrame(draggingData.current.callbackRafRef);
      }
      draggingData.current.callbackRafRef = requestAnimationFrame(() => {
        if (!draggingData.current) return; // This shouldn't happen.
        draggingData.current.callbackRafRef = null;
        if (!draggingData.current.isPan && onObjectTransform) {
          // We're not panning - object definitely exists.
          let obj = draggingData.current.object!;
          onObjectTransform(
            obj.type === CanvasObjectTypes.ObjectHandle
              ? (obj as CanvasObjectHandle).forObjectId
              : obj.id,
            obj.type === CanvasObjectTypes.ObjectHandle
              ? TransformTypes.scale
              : TransformTypes.translate,
            draggingData.current.cumulativeMoveSinceLastCallback,
            {
              handlePosition:
                obj.type === CanvasObjectTypes.ObjectHandle
                  ? (obj as CanvasObjectHandle).handlePosition
                  : null,
              constrainAspectRatio:
                draggingData.current.aspectRatioIsConstrained,
            }
          );
        } else if (onPan) {
          onPan({
            type: TranslationTypes.relative,
            amount: [
              draggingData.current.cumulativeMoveSinceLastCallback.dx,
              draggingData.current.cumulativeMoveSinceLastCallback.dy,
            ],
          });
        }
        if (draggingData.current.mouseStillDown) {
          draggingData.current.cumulativeMoveSinceLastCallback = {
            dx: 0,
            dy: 0,
          };
        } else {
          // drag has ended, remove dragging data.
          draggingData.current = null;
        }
      });
    },
    [onObjectTransform, zoom, onPan]
  );

  const onMouseUp = useCallback(() => {
    if (draggingData.current) {
      if (draggingData.current.callbackRafRef) {
        draggingData.current.mouseStillDown = false;
      } else {
        draggingData.current = null;
      }
    }
  }, []);

  useEffect(() => {
    // TODO: problem is this is window
    window.addEventListener("mouseup", onMouseUp);
    window.addEventListener("mousemove", onMouseMove);
    // do mousedown in the DOM?
    return () => {
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, [onMouseMove, onMouseUp]);

  // This is the bit that actually draws the image.
  useLayoutEffect(() => {
    const canvas = canvasRef.current;
    if (
      !canvas ||
      !state.canvas.containerWidth ||
      !state.canvas.containerHeight ||
      !fontLoaded
    )
      return;

    if (!zoom) {
      // We want to request zoom to fit.
      let zoomToFitBackdrop = getZoomToFitBackdropInCanvas({
        canvasContainerSize: [
          state.canvas.containerWidth,
          state.canvas.containerHeight,
        ],
        backdropSize: [backdropResolutionX, backdropResolutionY],
        paddingPx: initialZoomPadding,
      });
      // Update the zoomrange to allow this zoom
      if (onSetZoomRange) {
        onSetZoomRange({
          min: Math.min(0.5, zoomToFitBackdrop * 0.5),
          // max: Math.min(2, Math.max(1, zoomToFitBackdrop * 1.5)),
          max: 2,
        });
      }

      // Request the new zoom
      onZoom({
        type: TranslationTypes.absolute,
        amount: zoomToFitBackdrop,
      });
      return;
    }

    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }

    rafRef.current = requestAnimationFrame(() => {
      rafRef.current = null;
      const canvasContext = canvas.getContext("2d")!;
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);

      // First fill the canvas with background colour
      canvasContext.save(); // default
      canvasContext.fillStyle = isDarkMode ? "dimgray" : "whitesmoke";
      canvasContext.fillRect(0, 0, canvas.width, canvas.height);
      canvasContext.restore(); // default

      // Draw lock screen bounds.
      canvasContext.save(); // default
      canvasContext.translate(backdropOrigin!.x, backdropOrigin!.y);
      canvasContext.strokeStyle = "blue";
      canvasContext.strokeRect(0, 0, backdropResolutionX, backdropResolutionY);
      drawBackdrop(
        canvasContext,
        {
          backgroundColor,
          objectLibrary,
          objectMetas: objects,
          resolution: [backdropResolutionX, backdropResolutionY],
        },
        {
          includeDecorators: isEditable,
          zoom,
        }
      );
      // Draw clock.
      canvasContext.save();

      const scaledDimension = (sizeAt1080: number) => {
        return (sizeAt1080 / 1080) * backdropResolutionY;
      };

      canvasContext.fillStyle = "white";
      canvasContext.font = `lighter ${scaledDimension(125)}px SegoeUI`;
      canvasContext.fillText(
        "10:44",
        scaledDimension(30),
        backdropResolutionY - scaledDimension(190)
      );
      canvasContext.font = `lighter ${scaledDimension(55)}px SegoeUI`;
      canvasContext.fillText(
        "Tuesday, November 17",
        scaledDimension(36),
        backdropResolutionY - scaledDimension(112)
      );
      canvasContext.restore();
      canvasContext.restore();
    });

    return () => {
      // TODO: teardown logic?
    };
  }, [
    canvasRef,
    state.canvas.containerWidth,
    state.canvas.containerHeight,
    state.backdrop,
    objectLibrary,
    backdropResolutionX,
    backdropResolutionY,
    zoom,
    onZoom,
    pan,
    objects,
    backdropOrigin,
    backgroundColor,
    onSetZoomRange,
    fontLoaded,
    initialZoomPadding,
    isEditable,
    isDarkMode,
  ]);

  useEffect(() => {
    if (spacebarPressed) {
      canvasRef.current!.style.cursor = "grab";
      return;
    }
    if (!objectUnderCursor) {
      canvasRef.current!.style.cursor = "default";
      return;
    }
    switch (objectUnderCursor.type) {
      case CanvasObjectTypes.Image:
        if (objects[objectUnderCursor.id].selected) {
          canvasRef.current!.style.cursor = "move";
        } else {
          canvasRef.current!.style.cursor = "default";
        }
        break;
      case CanvasObjectTypes.ObjectHandle:
        let handle = objectUnderCursor as CanvasObjectHandle;
        let cursor;
        switch (handle.handlePosition) {
          case HandlePositions.LEFT_MID:
          case HandlePositions.RIGHT_MID:
            cursor = "ew-resize";
            break;
          case HandlePositions.TOP_MID:
          case HandlePositions.BOTTOM_MID:
            cursor = "ns-resize";
            break;
          case HandlePositions.TOP_LEFT:
          case HandlePositions.BOTTOM_RIGHT:
            cursor = "nwse-resize";
            break;
          case HandlePositions.BOTTOM_LEFT:
          case HandlePositions.TOP_RIGHT:
            cursor = "nesw-resize";
            break;
        }
        canvasRef.current!.style.cursor = cursor;
        break;
    }
  }, [objectUnderCursor, objects, spacebarPressed]);

  const onCanvasClick = (e: MouseEvent<HTMLCanvasElement>) => {
    if (lastClickWasUsedToDrag) {
      // We were dragging, this was triggered on release. Unset the state
      // so the next click will work.
      setLastClickWasUsedToDrag(false);
      return;
    }
    // If we're holding space, this is going to be a pan rather than a selection
    if (spacebarPressed) {
      return;
    }
    // Bail if there's no selection function.
    if (!onObjectSelected) return;
    // Check if the click is within the bounds of an object
    if (!objectUnderCursor) {
      onObjectSelected(null);
      return;
    }
    switch (objectUnderCursor.type) {
      case CanvasObjectTypes.Image:
        onObjectSelected(objectUnderCursor.id);
        break;
      default:
        return;
    }
  };

  return (
    <div className={classNames("w-full bg-black relative", className)}>
      <div
        ref={containerRef}
        onMouseDown={onMouseDown}
        className="absolute inset-0"
      >
        <canvas
          onContextMenu={(e) => e.preventDefault()}
          onClick={onCanvasClick}
          className="w-full h-full"
          ref={canvasRef}
          width={zoom ? state.canvas.containerWidth / zoom + "px" : 0}
          height={zoom ? state.canvas.containerHeight / zoom + "px" : 0}
        ></canvas>
      </div>
    </div>
  );
};

export default BackdropMockup;
