import { FC } from "react";

import classNames from "classnames";

const styles = require("./Checkbox.module.css");

const Checkbox: FC<
  { className?: string; multiLine?: boolean } & React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = ({ children, className, multiLine, disabled, ...rest }) => {
  return (
    <label
      className={classNames(
        "grid gap-2",
        className,
        disabled && "opacity-50 cursor-not-allowed"
      )}
      style={{
        gridTemplateColumns: "min-content auto",
      }}
    >
      <span className="checkbox__input flex items-center">
        <input
          disabled={disabled}
          {...rest}
          type="checkbox"
          className={classNames("opacity-0 w-0 h-0", styles.checkboxInput)}
        />
        <span
          className={classNames(
            "grid w-4 h-4 rounded-sm border-gray-800 dark:border-white border-2 place-items-center",
            styles.checkboxControl
          )}
        ></span>
      </span>
      <span
        className={classNames([
          "checkbox__label",
          !multiLine ? "leading-4" : "leading-tight",
        ])}
      >
        {children}
      </span>
    </label>
  );
};

export default Checkbox;
