import React, { FC, Fragment, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useToasts } from "react-toast-notifications";

import classNames from "classnames";

import { changePassword } from "../api/users";

import useSession from "../Hooks/useSession";

import Button, { ButtonTypes } from "../Components/Button";
import Callout, { CalloutTypes } from "../Components/Callout";
import { centeredAndSizedByContent } from "../Components/modalCustomStyles";

type ChangePasswordFormData = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ChangePasswordModal: FC<{
  onRequestClose?: () => void;
  isMandatory?: boolean;
}> = ({ onRequestClose, isMandatory = false }) => {
  const { register, handleSubmit, errors, getValues } =
    useForm<ChangePasswordFormData>();
  const [showPassword, setShowPassword] = useState<{
    current: boolean;
    new: boolean;
    confirm: boolean;
  }>({
    current: false,
    new: false,
    confirm: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { updateSession, session } = useSession();
  const { addToast } = useToasts();
  const { t } = useTranslation([
    "modal_changepassword",
    "common_user",
    "common_forms",
    "common_actions",
  ]);

  // Submit logic.
  const onSubmit = handleSubmit(async (values) => {
    if (loading) return;
    setLoading(true);
    try {
      await changePassword({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });
      updateSession({
        ...session!,
        flags: {
          ...session!.flags,
          changePassword: false,
          resetPassword: false,
        },
      });
      if (onRequestClose) {
        onRequestClose();
        addToast(t("modal_changepassword:changePasswordSuccessToast"), {
          appearance: "success",
          autoDismiss: true,
        });
      }
    } catch (e) {
      // (Required for extracting dynamic keys)
      // t("modal_changepassword:errorDetails")
      // t("modal_changepassword:errorDetails", {context: "USER_PASSWORD_MISMATCH"})
      // t("modal_changepassword:errorDetails", {context: "USER_PASSWORD_TOO_RECENT"})
      addToast(
        t("modal_changepassword:errorChangingPassword", {
          errorDetails: t(`modal_changepassword:errorDetails`, {
            context: e.message as string,
          }),
        }),
        {
          appearance: "error",
          autoDismiss: false,
        }
      );
    } finally {
      setLoading(false);
    }
  });

  const validation: {
    [field: string]: (value: any) => any;
  } = {
    confirmPassword: (value: string) => {
      return (
        value === getValues().newPassword ||
        t("modal_changepassword:passwordsMustMatch")
      );
    },
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onRequestClose}
      style={centeredAndSizedByContent}
    >
      <form
        className="flex flex-col w-96 rounded-sm"
        onSubmit={onSubmit}
        // Prevent the browser validating the form - we'll do it.
        noValidate
      >
        <h2 className="mb-4 text-lg font-bold">
          {/* t("modal_changepassword:header", {context: "mandatory"}) */}
          {t("modal_changepassword:header", {
            context: isMandatory ? "mandatory" : "",
          })}
        </h2>
        {/* The below is for password mamangers. */}
        <input
          className="hidden"
          type="text"
          name="username"
          autoComplete="username"
          readOnly
          value={session?.user.email || "loading"}
        />
        {(
          (!session?.flags.resetPassword
            ? ["current", "new", "confirm"]
            : ["new", "confirm"]) as ("current" | "new" | "confirm")[]
        ).map((passwordType) => (
          <Fragment key={passwordType}>
            <div className="flex flex-row justify-between items-end">
              <label htmlFor={`${passwordType}-password`}>
                {t("common_user:password", { context: passwordType })}
              </label>
              <button
                type="button"
                className="text-xs"
                style={{ marginBottom: "2px" }}
                tabIndex={3}
                onClick={(e) => {
                  e.preventDefault();
                  setShowPassword((prev) => ({
                    ...prev,
                    [passwordType]: !prev[passwordType],
                  }));
                }}
              >
                {showPassword[passwordType]
                  ? t("common_forms:password_conceal")
                  : t("common_forms:password_reveal")}
              </button>
            </div>
            <input
              className={classNames(
                ["input mb-4 px-2 py-1"],
                !!errors[
                  (passwordType + "Password") as keyof ChangePasswordFormData
                ]
                  ? "input-error"
                  : ""
              )}
              id={`${passwordType}-password`}
              name={`${passwordType}Password`}
              autoComplete={`${passwordType}-password`}
              type={showPassword[passwordType] ? "text" : "password"}
              tabIndex={2}
              ref={register({
                required: t("common_forms:error", {
                  context: "namedRequiredField",
                  fieldName: t("common_user:password", {
                    context: passwordType,
                  }),
                }) as string,
                validate: validation[passwordType + "Password"],
              })}
            />
          </Fragment>
        ))}
        {Object.keys(errors).length !== 0 && (
          <Callout
            className="mb-4"
            type={CalloutTypes.error}
            content={
              <ul className="list-disc ml-4">
                {Object.keys(errors).map((errorKey) => {
                  const error =
                    errors[errorKey as keyof ChangePasswordFormData];
                  return (
                    error && (
                      <li key={errorKey} className="mb-1">
                        {error.message}
                      </li>
                    )
                  );
                })}
              </ul>
            }
          />
        )}
        <div className="flex flex-row mb-1 self-end">
          {!isMandatory && onRequestClose && (
            <Button
              type="button"
              className="mr-2"
              disabled={loading}
              onClick={onRequestClose}
            >
              {t("common_actions:cancel")}
            </Button>
          )}
          <Button
            type="submit"
            buttonType={ButtonTypes.positive}
            loading={loading}
          >
            {t("modal_changepassword:changePasswordAction")}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
