import { useEffect } from "react";

const useTemporaryCssRule = (rule: string) => {
  useEffect(() => {
    const styleEl = document.createElement("style");
    document.head.appendChild(styleEl);
    const styleSheet = styleEl.sheet!;
    console.log(styleSheet);
    styleSheet.insertRule(rule, 0);

    return () => {
      styleEl.remove();
    };
  }, [rule]);
};

export default useTemporaryCssRule;
