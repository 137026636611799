import { CSSProperties, FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";

import classNames from "classnames";

import { Backdrop, getBackdrops } from "../api/backdrops";
import { Group, updateGroup } from "../api/groups";

import { remPixels } from "../Hooks/useRem";

import { resolutionStringToXYArray } from "../utility";
import BackdropPreviewImage from "./BackdropPreviewImage";
import Button, { ButtonTypes } from "./Button";
import Checkbox from "./Checkbox";
import LoadingSpinner from "./LoadingSpinner";
import { centeredAndSizedByContent } from "./modalCustomStyles";

// We need to write w-20 (imageWidthInRem * 4) here to avoid tailwind purge.
const imageWidthInRem = 5;
const paddingRem = 0.5;

const modalStyle = {
  ...centeredAndSizedByContent,
  content: {
    ...centeredAndSizedByContent.content,
    height: "100%",
  },
};

type SelectedBackdrops = {
  lockscreen: Backdrop | null;
  desktop: Backdrop | null;
};

const BackdropRow: FC<{
  index: number;
  style: CSSProperties;
  data: {
    backdrops: Backdrop[];
    selectedBackdrops: SelectedBackdrops;
    setSelectedBackdrops: (
      updater: (oldBackdrops: SelectedBackdrops) => SelectedBackdrops
    ) => void;
    resolution: string;
  };
}> = ({ style, index, data }) => {
  const { backdrops, selectedBackdrops, setSelectedBackdrops, resolution } =
    data;
  const backdrop = backdrops[index];
  return (
    <div
      style={style}
      className={classNames(
        `flex flex-row items-center gap-2 p-${paddingRem * 4}`,
        "transition-colors",
        "hover:bg-black hover:bg-opacity-10",
        "dark:hover:bg-white dark:hover:bg-opacity-10",
        (selectedBackdrops.desktop === backdrop ||
          selectedBackdrops.lockscreen === backdrop) &&
          "bg-opacity-20 hover:bg-opacity-20 bg-black dark:bg-white dark:bg-opacity-20 dark:hover:bg-opacity-20"
      )}
    >
      <BackdropPreviewImage
        className={`w-${imageWidthInRem * 4}`}
        backdrop={backdrop}
        alt={`Preview of ${backdrop.name}`}
        resolution={resolution}
        compact={true}
      />
      <div className="flex-1">{backdrop.name}</div>
      <div className="flex flex-row w-28 justify-between pr-2">
        {["desktop", "lockscreen"].map((backdropType) => {
          const type = backdropType as "desktop" | "lockscreen";
          return (
            <Checkbox
              key={`${backdrop._id}-${backdropType}-checkbox`}
              name={backdropType}
              checked={backdrop === selectedBackdrops[type]}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedBackdrops((existingBackdrops) => ({
                    ...existingBackdrops,
                    [type]: backdrop,
                  }));
                } else {
                  setSelectedBackdrops((existingBackdrops) => ({
                    ...existingBackdrops,
                    [type]: null,
                  }));
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const SelectExistingBackdropModal: FC<{
  onCancel: () => void;
  onSuccess: (updatedGroup: Group) => void;
  group: Group;
  selectedResolution: string;
  allResolutions: string[];
}> = ({ onCancel, onSuccess, selectedResolution, allResolutions, group }) => {
  const [backdrops, setBackdrops] = useState<Backdrop[] | null>(null);
  const [selectedBackdrops, setSelectedBackdrops] = useState<SelectedBackdrops>(
    {
      lockscreen: null,
      desktop: null,
    }
  );
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const { t } = useTranslation([
    "page_backdrops",
    "common_objects",
    "common_actions",
  ]);

  useEffect(() => {
    const fetchBackdrops = async () => {
      const fetchedBackdrops = await getBackdrops();
      const selectedLockscreen =
        fetchedBackdrops.find((bd) => group.backdrops.lockscreen === bd._id) ||
        null;
      const selectedDesktop =
        fetchedBackdrops.find((bd) => group.backdrops.desktop === bd._id) ||
        null;
      setSelectedBackdrops({
        lockscreen: selectedLockscreen,
        desktop: selectedDesktop,
      });
      fetchedBackdrops.sort((a, b) => {
        if (a === selectedLockscreen || a === selectedDesktop) return -1;
        else if (b === selectedLockscreen || b === selectedDesktop) return 1;
        return (
          new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf()
        );
      });
      setBackdrops(fetchedBackdrops);
    };
    fetchBackdrops();
  }, [group.backdrops.desktop, group.backdrops.lockscreen]);

  const onSubmit = async () => {
    const hasChanges =
      (selectedBackdrops.lockscreen?._id || null) !==
        group.backdrops.lockscreen ||
      (selectedBackdrops.desktop?._id || null) !== group.backdrops.desktop;
    if (!hasChanges) {
      onCancel();
      return;
    } else {
      setSubmitLoading(true);
      try {
        const updatedGroup = await updateGroup(group._id, {
          backdrops: {
            lockscreen: selectedBackdrops.lockscreen?._id || null,
            desktop: selectedBackdrops.desktop?._id || null,
          },
        });
        onSuccess(updatedGroup);
      } catch (e) {
        // TODO: error handling updating backdrop
        setSubmitLoading(false);
      }
    }
  };

  const listItemSize: () => number = () => {
    const width = remPixels * imageWidthInRem;
    const [x, y] = resolutionStringToXYArray(selectedResolution);
    const height = width * (y / x) + paddingRem * remPixels * 2;
    return height;
  };

  return (
    <Modal isOpen={true} onRequestClose={onCancel} style={modalStyle}>
      <div
        className="h-full flex flex-col"
        style={{
          width: "30rem",
        }}
      >
        <h2 className="text-lg font-bold mb-2">
          {t("page_backdrops:changeBackdropModalTitle", {
            groupName: group.identifier,
          })}
        </h2>
        {!!backdrops ? (
          <div className="flex flex-col flex-1">
            <div className="flex flex-row justify-between w-36 self-end text-sm">
              <span className="relative left-2">
                {t("common_objects:desktop")}
              </span>
              <span>{t("common_objects:lockscreen")}</span>
            </div>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  className="border-2 border-gray-900 rounded-sm"
                  height={height}
                  width={width}
                  itemCount={backdrops.length}
                  itemSize={listItemSize()}
                  itemData={{
                    backdrops: backdrops,
                    selectedBackdrops: selectedBackdrops,
                    setSelectedBackdrops: setSelectedBackdrops,
                    resolution: selectedResolution,
                  }}
                >
                  {BackdropRow}
                </List>
              )}
            </AutoSizer>
          </div>
        ) : (
          <LoadingSpinner />
        )}
        <div className="mt-8 flex flex-col text-sm">
          {(["desktop", "lockscreen"] as ("desktop" | "lockscreen")[]).map(
            (backdropType) => {
              const isUnchanged =
                selectedBackdrops[backdropType]?._id ===
                  group.backdrops[backdropType] ||
                (selectedBackdrops[backdropType] === null &&
                  group.backdrops[backdropType] === null);
              return (
                <div
                  key={`indicator-${backdropType}`}
                  className={isUnchanged ? "italic opacity-50" : ""}
                >
                  <span className="font-bold mr-1">{backdropType}:</span>{" "}
                  <span className="mr-1">
                    {selectedBackdrops[backdropType]?.name || "None"}
                  </span>
                  {isUnchanged ? (
                    <span className="text-xs">(unchanged)</span>
                  ) : (
                    <button
                      className="cursor-pointer text-xs text-blue-800 underline"
                      onClick={() => {
                        setSelectedBackdrops((current) => {
                          return {
                            ...current,
                            [backdropType]:
                              backdrops!.find(
                                (bd) => bd._id === group.backdrops[backdropType]
                              ) || null,
                          };
                        });
                      }}
                    >
                      ({t("common_actions:revert")})
                    </button>
                  )}
                </div>
              );
            }
          )}
        </div>
        <div className="self-end mt-4">
          <Button type="button" className="mr-2" onClick={onCancel}>
            {t("common_actions:cancel")}
          </Button>
          <Button
            type="button"
            buttonType={ButtonTypes.positive}
            loading={submitLoading}
            onClick={onSubmit}
          >
            {t("common_actions:select")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectExistingBackdropModal;
