import { FC } from "react";

import { useTranslation } from "react-i18next";
import { IoIosWarning } from "react-icons/io";

const FormFieldError: FC<{
  condition: boolean;
  text?: string;
  i18nOptions?: any;
}> = ({ condition, text, i18nOptions }) => {
  const { t } = useTranslation("common_forms");
  return (
    (condition && (
      <span className="text-xs text-red-900 dark:text-red-600 -mt-1 mb-1">
        <div className="flex flex-row items-center">
          <IoIosWarning className="text-sm mr-1" /> {/* @ts-ignore */}
          {text ? text : t("common_forms:error", i18nOptions)}
        </div>
      </span>
    )) || <></>
  );
};

export default FormFieldError;
