import React, { FC, useState } from "react";

import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useToasts } from "react-toast-notifications";

import { Group, updateGroup } from "../api/groups";

import Button, { ButtonTypes } from "./Button";
import Checkbox from "./Checkbox";
import { centeredAndSizedByContent } from "./modalCustomStyles";

const ClearBackdropModal: FC<{
  group: Group;
  onRequestClose: () => void;
  onGroupModified: (group: Group) => void;
}> = ({ group, onRequestClose, onGroupModified }) => {
  const { t } = useTranslation([
    "common_actions",
    "page_groups",
    "common_objects",
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [which, setWhich] = useState<{ lockscreen: boolean; desktop: boolean }>(
    { lockscreen: false, desktop: false }
  );
  const { addToast } = useToasts();

  return (
    <Modal isOpen={true} style={centeredAndSizedByContent}>
      <div className="flex flex-col w-96">
        <h3 className="font-bold text-lg mb-2">
          {t("page_groups:clearBackdropForGroup", {
            groupName: group.identifier,
          })}
        </h3>
        <p className="mb-1">{t("page_groups:clearBackdropExplainer")}</p>
        <p>{t("page_groups:clearBackdropSelectHeader")}</p>
        <div className="mt-2 grid grid-cols-2 gap-2">
          <Checkbox
            disabled={!group.backdrops.desktop}
            checked={which.desktop}
            onChange={(e) => {
              setWhich((prev) => ({
                lockscreen: prev.lockscreen,
                desktop: e.target.checked,
              }));
            }}
          >
            {t("common_objects:desktop")}
          </Checkbox>
          <Checkbox
            disabled={!group.backdrops.lockscreen}
            checked={which.lockscreen}
            onChange={(e) => {
              setWhich((prev) => ({
                desktop: prev.desktop,
                lockscreen: e.target.checked,
              }));
            }}
          >
            {t("common_objects:lockscreen")}
          </Checkbox>
        </div>
        <div className="flex flex-row mt-4">
          <Button
            onClick={async () => {
              onRequestClose();
            }}
            className="button flex-1 mr-1"
          >
            {t("common_actions:cancel")}
          </Button>
          <Button
            buttonType={ButtonTypes.destructive}
            className="flex-1 ml-1"
            loading={loading}
            disabled={!which.lockscreen && !which.desktop}
            onClick={async () => {
              setLoading(true);
              const newBackdrops: Group["backdrops"] = {};
              if (which.lockscreen) newBackdrops.lockscreen = null;
              if (which.desktop) newBackdrops.desktop = null;
              try {
                await updateGroup(group._id!, { backdrops: newBackdrops });
                group.backdrops = { ...group.backdrops, ...newBackdrops };
                onGroupModified(group);
                addToast(
                  // t("page_groups:clearBackdropSuccessToast", { context: "lockscreen" })
                  // t("page_groups:clearBackdropSuccessToast", { context: "desktop" })
                  t("page_groups:clearBackdropSuccessToast", {
                    context:
                      newBackdrops.lockscreen === null &&
                      newBackdrops.desktop !== null
                        ? "lockscreen"
                        : newBackdrops.desktop === null &&
                          newBackdrops.lockscreen !== null
                        ? "desktop"
                        : null,
                    groupName: group.identifier,
                  }),
                  {
                    appearance: "success",
                    autoDismiss: true,
                  }
                );
                onRequestClose();
              } catch (e) {
                // TODO: error handling on backdrop clear
              } finally {
                setLoading(false);
              }
            }}
          >
            {t("common_actions:confirm")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ClearBackdropModal;
