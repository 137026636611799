import { FC, InputHTMLAttributes, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { GiCancel } from "react-icons/gi";

import classNames from "classnames";

import Button from "./Button";

const FileSelect: FC<
  {
    className?: string;
    uploadProgress?: number[];
    inputId: string;
    name: string;
    onFilesSelected: (files: File[] | null) => any;
  } & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">
> = ({
  className,
  name,
  onFilesSelected,
  inputId,
  uploadProgress,
  ...rest
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation(["common_forms"]);

  return (
    <div className={classNames("flex flex-col", className)}>
      <input
        className="hidden"
        type="file"
        name={name}
        id={inputId}
        ref={inputRef}
        {...rest}
        onChange={(event) => {
          if (event.target.files) {
            const filesAsArray = Array.from(event.target.files);
            setSelectedFiles(filesAsArray);
            if (onFilesSelected) onFilesSelected(filesAsArray);
          }
        }}
      />
      {!selectedFiles || !selectedFiles.length ? (
        <Button
          type="button"
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.click();
            }
          }}
        >
          {t("common_forms:selectFiles")}
        </Button>
      ) : (
        <div className="flex flex-col">
          {uploadProgress && uploadProgress.some((i) => i !== 0) ? (
            <span className="font-bold">
              {t("common_forms:uploadingFiles", {
                count: selectedFiles.length,
              })}
              :
            </span>
          ) : (
            <span className="font-bold">
              {t("common_forms:selectFiles", { count: selectedFiles.length })}:
            </span>
          )}
          <ul className="text-sm mt-1">
            {selectedFiles.map((file, i) => (
              <li className="my-1">
                <div className="flex items-center relative">
                  {/* progress bar */}
                  {uploadProgress && (
                    <div className="absolute inset-0 flex">
                      <div
                        className="bg-gray-400 z-0 w-full origin-left transition-transform duration-75"
                        style={{
                          transform: `scaleX(${uploadProgress[i]})`,
                        }}
                      ></div>
                    </div>
                  )}
                  <span className="flex-1 z-10 px-1">{file.name}</span>
                  <button
                    className={classNames(
                      "text-xs ml-2 mr-1 z-10",
                      uploadProgress && uploadProgress[i] && "hidden"
                    )}
                    type="button"
                    onClick={() => {
                      const newFiles = selectedFiles.filter(
                        (selectedFile) => selectedFile !== file
                      );
                      setSelectedFiles(newFiles);
                      onFilesSelected(newFiles);
                    }}
                  >
                    <GiCancel />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FileSelect;
