import { BackdropObjectMeta } from "./BackdropMockup";
import {
  CanvasObject,
  drawCanvasObject,
  drawObjectSelectionDecorators,
} from "./CanvasObject";

const drawBackdrop = (
  canvasContext: CanvasRenderingContext2D,
  backdropData: {
    backgroundColor: string;
    resolution: [number, number];
    objectLibrary: CanvasObject[];
    objectMetas: { [id: string]: BackdropObjectMeta };
  },
  options: {
    includeDecorators: boolean;
    zoom: number;
  }
) => {
  const { backgroundColor, resolution, objectLibrary, objectMetas } =
    backdropData;
  const { includeDecorators, zoom } = options;
  // Fill background
  canvasContext.fillStyle = backgroundColor;
  canvasContext.fillRect(0, 0, resolution[0], resolution[1]);

  // Draw objects
  if (objectLibrary.length) {
    // Only draw in the hole we just bored
    Object.values(objectMetas)
      .sort((a, b) => a.position.zIndex - b.position.zIndex)
      .forEach((objectMeta) => {
        let object = objectLibrary.find((o) => o.id === objectMeta.id);
        if (!object) return;
        canvasContext.save(); // Translated to start of backdrop.
        canvasContext.beginPath();
        canvasContext.rect(0, 0, resolution[0], resolution[1]);
        canvasContext.clip();

        const objectData = objectMetas[object.id];
        if (!objectData) {
          // TODO: if this comes up work out what to do.
          console.error("Not meant to happen, deal with");
          return;
        }
        drawCanvasObject(
          object,
          canvasContext,
          objectData.position.origin,
          objectData.position.scale
        );
        // Undo clip
        canvasContext.restore(); // Translated to start of backdrop.

        if (objectData.selected && includeDecorators) {
          drawObjectSelectionDecorators(
            object,
            canvasContext,
            objectData.position.origin,
            objectData.position.scale,
            9 / zoom
          );
        }
      });
  }
};

export default drawBackdrop;
