import { FC, useEffect, useState } from "react";

import { addFreshchatRestoreId } from "./api/users";

import useSession from "./Hooks/useSession";

const {
  REACT_APP_FRESHCHAT_TOKEN: token,
  REACT_APP_FRESHCHAT_HOST: host,
  REACT_APP_FRESHCHAT_SITE_ID: siteId,
} = process.env;

const widgetConfig = {
  config: {
    cssNames: { widget: "pxn-fc-widget-customisation" },
  },
};

// This will allow us to add custom CSS to the widget container if we need to
// move it around (e.g. backdrop editor page).
const freshChatEnabled = !!token && !!host && !!siteId;

const onUserCreated: (resp: {
  success: boolean;
  data: { restoreId: string };
}) => Promise<void> = async (resp) => {
  if (!resp.success) return;
  await addFreshchatRestoreId(resp.data.restoreId);
};

export const FreshChatWidget: FC<{}> = () => {
  const { session, loading } = useSession(true);
  const [destroyInProgress, setDestroyInProgress] = useState<boolean>(false);

  useEffect(() => {
    if (
      !freshChatEnabled ||
      loading ||
      destroyInProgress ||
      fcWidget.destroying
    ) {
      return;
    }

    if (!session?.user) {
      window.fcWidget.init({
        token: token!,
        host: host!,
        siteId,
        ...widgetConfig,
      });
    } else {
      if (!session.user.freshchat.restoreId) {
        window.fcWidget.on("user:created", onUserCreated);
      }
      const userMeta = {
        token: token!,
        host: host!,
        siteId,
        email: session.user.email,
        firstName: session.user.name,
        externalId: session.user._id,
        restoreId: session.user.freshchat.restoreId,
      };
      window.fcWidget.init({ ...userMeta, ...widgetConfig });
    }

    return () => {
      // Already clearing user, will trigger again once clear user promise
      // resolves or rejects.
      if (destroyInProgress) return;
      window.fcWidget.off("user:created", onUserCreated);
      window.fcWidget.destroying = true;
      setDestroyInProgress(true);
      const destroy = () => {
        window.fcWidget.on("widget:destroyed", function onDestroy() {
          // Set because session changes and triggers this clearup and a new
          // useEffect runs before `detroyInProgress` is true, so we can check
          // this variable to avoid initing twice.
          window.fcWidget.destroying = false;
          setDestroyInProgress(false);
          window.fcWidget.off("widget:destroyed", onDestroy);
        });
        window.fcWidget.destroy();
      };
      if (session) {
        window.fcWidget.close();
        window.fcWidget.user
          .clear()
          .then(() => {
            window.fcWidget.destroying = false;
            setDestroyInProgress(false);
          })
          .catch(destroy);
      } else {
        destroy();
      }
    };
  }, [session, loading, destroyInProgress]);

  return null;
};
