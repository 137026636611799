import { FC } from "react";

import classNames from "classnames";

const styles = require("./RadioButton.module.css");

const RadioButton: FC<
  { className?: string } & React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = ({ children, className, ...rest }) => {
  return (
    <label
      className={classNames("grid gap-2", className)}
      style={{
        gridTemplateColumns: "min-content auto",
      }}
    >
      <span className="radio__input flex">
        <input
          {...rest}
          type="radio"
          className={classNames("opacity-0 w-0 h-0", styles.radioInput)}
        />
        <span
          className={classNames(
            "block w-4 h-4 rounded-full border-gray-800 dark:border-white border-2",
            styles.radioControl
          )}
        ></span>
      </span>
      <span className={classNames("radio__label leading-4")}>{children}</span>
    </label>
  );
};

export default RadioButton;
