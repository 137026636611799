import { FC, useRef, useState } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { login } from "../api/auth";
import { ApiError } from "../api/utility-functions";

import useIsDarkMode from "../Hooks/useIsDarkMode";
import useSession from "../Hooks/useSession";

import Button from "../Components/Button";
import Callout, { CalloutTypes } from "../Components/Callout";
import SessionMessage from "../Components/SessionMessage";

type LoginFormData = {
  username: string;
  password: string;
  // This is here so we can add server errors and RHF types line up.
  recaptcha: string;
  server: string;
};

const LoginPage: FC<{}> = (props) => {
  const { register, handleSubmit, errors, setError, clearErrors } =
    useForm<LoginFormData>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { updateSession } = useSession();
  const { addToast } = useToasts();
  const { t } = useTranslation([
    "page_login",
    "common_user",
    "common_forms",
    "server_messages",
  ]);

  const recaptchaRef = useRef<ReCAPTCHA>();

  const isDarkMode = useIsDarkMode();

  const onSubmit = handleSubmit(async (values) => {
    if (loading) return;
    const recaptcha = await recaptchaRef.current?.executeAsync();
    if (!recaptcha) return;
    setLoading(true);
    try {
      const session = await login({ ...values, recaptcha });
      updateSession(session);
      history.replace("/groups");
    } catch (e) {
      setLoading(false);
      recaptchaRef.current?.reset();
      let error: ApiError = e;
      if (error.code === 401) {
        setError("server", {
          message: t("server_messages:error", { context: error.message }),
        });
      } else {
        addToast(
          t("page_login:errorLoggingIn", {
            errorDetails: e.message as string,
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    }
  });
  return (
    <div className="w-full h-full flex flex-col items-center justify-center text-base">
      <SessionMessage />
      <form
        className="flex flex-col w-72 p-4 border border-black dark:border-gray-600 rounded-sm"
        onSubmit={onSubmit}
        // Prevent the browser validating the form - we'll do it.
        noValidate
      >
        <h1 className="mb-4 text-center font-bold">
          {t("page_login:welcome")}
        </h1>
        <label htmlFor="login-email">{t("common_user:email")}</label>
        <input
          type="email"
          className="input mb-4 px-2 py-1"
          autoComplete="email"
          id="login-email"
          name="username"
          onChange={() => clearErrors("server")}
          tabIndex={1}
          ref={register({ required: true })}
        />
        <div className="flex flex-row justify-between items-end">
          <label htmlFor="login-password">{t("common_user:password")}</label>
          <button
            type="button"
            className="text-xs"
            style={{ marginBottom: "2px" }}
            tabIndex={3}
            onClick={(e) => {
              e.preventDefault();
              setShowPassword((prev) => !prev);
            }}
          >
            {showPassword
              ? t("common_forms:password_conceal")
              : t("common_forms:password_reveal")}
          </button>
        </div>
        <input
          className="input mb-6 px-2 py-1"
          id="login-password"
          name="password"
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          onChange={() => clearErrors("server")}
          tabIndex={2}
          ref={register({ required: true })}
        />
        {(!!errors.password || !!errors.username) && (
          <Callout
            className="mb-4"
            type={CalloutTypes.error}
            content={t("page_login:emailAndPasswordBothRequired")}
          />
        )}
        {!!errors.server && (
          <Callout
            className="mb-4"
            type={CalloutTypes.error}
            content={errors.server.message}
          />
        )}
        <ReCAPTCHA
          sitekey="6LepuFAaAAAAAMNbE4TSjxjwgcEcetp1g6d9yf2L"
          size="invisible"
          badge="bottomleft"
          // @ts-ignore
          ref={recaptchaRef}
          theme={isDarkMode ? "dark" : "light"}
        />
        <Button
          type="submit"
          className="mb-1"
          loading={loading}
          disabled={!!Object.values(errors).length}
        >
          {t("page_login:loginAction")}
        </Button>
        <div className="opacity-70 mx-2 mt-6 mb-6 flex flex-col items-center justify-center border-black dark:border-gray-600 border-b">
          <div className="text-sm bg-white dark:bg-gray-900 px-2 absolute">
            OR
          </div>
        </div>
        <a href="/api/auth/openid/login" className="self-center ">
          <img
            className="cursor-pointer"
            style={{ height: "41px" }}
            src={`microsoft/ms-symbollockup_signin_${
              isDarkMode ? "dark" : "light"
            }.svg`}
            alt="Sign in with microsoft"
          />
        </a>
      </form>
      <div className="text-sm mt-1">
        <span>{t("page_login:forgottenPassword")}</span>
        <Link to="/reset-password" className="ml-1 underline text-blue-800">
          {t("page_login:requestPasswordReset")}
        </Link>
      </div>
      <div className="text-sm mt-1">
        <span>{t("page_login:needHelp")}</span>
        <a href="/docs" className="ml-1 underline text-blue-800">
          {t("page_login:checkUserGuide")}
        </a>
      </div>
    </div>
  );
};

export default LoginPage;
