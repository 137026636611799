import axios from "axios";

import { clearSessionData } from "../ServiceWorker/utils";
import { Resource } from "./generics";
import { User } from "./users";
import { processApiError } from "./utility-functions";

export type Session = {
  user: User;
  flags: {
    changePassword: boolean;
    resetPassword: boolean;
  };
  account: {
    name: string;
    administrator: string;
    azure: {
      tenantId?: string;
      groupId?: string;
    };
    client: {
      id: string;
    };
  } & Resource;
  otp: {
    required: boolean;
    verified: boolean;
  };
};

// errors for i18n extraction.
// t("server_messages:error")
// t("server_messages:error", { context: "ACCOUNT_AZURE_TENANT_IN_USE" })
// t("server_messages:error", { context: "ACCOUNT_NAME_IN_USE" })
// t("server_messages:error", { context: "AUTH_AZURE_GROUP_MISMATCH" })
// t("server_messages:error", { context: "AUTH_AZURE_GROUP_NOT_FOUND" })
// t("server_messages:error", { context: "AUTH_AZURE_LOGIN_FAILED" })
// t("server_messages:error", { context: "AUTH_AZURE_TENANT_MISMATCH" })
// t("server_messages:error", { context: "AUTH_AZURE_TENANT_NOT_FOUND" })
// t("server_messages:error", { context: "AUTH_AZURE_TOO_MANY_GROUPS" })
// t("server_messages:error", { context: "AUTH_AZURE_USER_MISMATCH" })
// t("server_messages:error", { context: "AUTH_LOCAL_INVALID_ACTIVATION_LINK" })
// t("server_messages:error", { context: "AUTH_LOCAL_INVALID_CREDENTIALS" })
// t("server_messages:error", { context: "AUTH_LOCAL_INVALID_PASSWORD_RESET_LINK" })
// t("server_messages:error", { context: "AUTH_OTP_NO_USER_SESSION" })
// t("server_messages:error", { context: "AUTH_OTP_TIMEOUT" })
// t("server_messages:error", { context: "AUTH_USER_DISABLED" })
// t("server_messages:error", { context: "INTERNAL_SERVER_ERROR" })
// t("server_messages:error", { context: "RECAPTCHA_VERIFICATION_FAILED" })
// t("server_messages:error", { context: "USER_AZURE_USERNAME_IN_USE" })
// t("server_messages:error", { context: "USER_PASSWORD_MISMATCH" })
// t("server_messages:error", { context: "USER_PASSWORD_TOO_RECENT" })

type LoginResponse = {
  data: Session;
};
type SessionResponse = {
  data: Session;
};
export type SessionMessage = {
  type: "error" | "info";
  message: string;
} | null;

export const login: (credentials: {
  username: string;
  password: string;
  recaptcha: string;
}) => Promise<Session> = async (credentials) => {
  try {
    let result = await axios.post<LoginResponse>(
      "/api/auth/local/login",
      credentials
    );
    return result.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};

export const getSession: () => Promise<Session> = async () => {
  try {
    let result = await axios.get<SessionResponse>("/api/auth/session");
    return result.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};

export const getSessionMessage: () => Promise<SessionMessage> = async () => {
  try {
    const response = await axios.get<{ data: SessionMessage }>(
      "/api/auth/session/message"
    );
    const message = response.data.data;
    if (message?.type && message.message) return message;
    return null;
  } catch (e) {
    throw processApiError(e);
  }
};

export const resetPassword: (data: {
  email: string;
  recaptcha: string;
}) => Promise<void> = async (data) => {
  try {
    await axios.post("api/auth/local/reset-password", data);
  } catch (e) {
    throw processApiError(e);
  }
};

export const logout: () => Promise<void> = async () => {
  try {
    clearSessionData();
    await axios.get("/api/auth/logout");
  } catch (e) {
    throw processApiError(e);
  }
};

export const verifyOTP: (data: {
  token: string;
  recaptcha: string;
}) => Promise<Session> = async (data) => {
  try {
    const response = await axios.post<SessionResponse>(
      "/api/auth/otp/verify",
      data
    );
    return response.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};
