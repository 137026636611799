import React from "react";
import { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { IoIosWarning } from "react-icons/io";

import classNames from "classnames";

import { Backdrop } from "../api/backdrops";

import LoadingSpinner from "./LoadingSpinner";

const BackdropPreviewImage: FC<{
  className?: string;
  resolution: string;
  backdrop?: Backdrop | null;
  compact?: boolean;
  alt: string;
  useThumbnail?: boolean;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void;
  backdropType?: string;
  imgStyle?: React.CSSProperties;
}> = ({
  className,
  alt,
  resolution,
  backdrop,
  imgStyle,
  onClick,
  compact,
  backdropType = "",
  useThumbnail = true,
}) => {
  const hasImageForRes = backdrop?.formats.find(
    (format) => format.resolution === resolution
  );

  const [isLoading, setIsLoading] = useState<boolean | null>(
    !hasImageForRes ? false : null
  );
  const [isMissing, setIsMissing] = useState<boolean>(!hasImageForRes);
  const [hasError, setHasError] = useState<boolean>(false);

  const { t } = useTranslation([
    "component_backdrop_preview_image",
    "common_objects",
  ]);

  useEffect(() => {
    // reset everything when the resolution changes.
    const hasImageForRes = backdrop?.formats.find(
      (format) => format.resolution === resolution
    );
    if (!hasImageForRes) {
      setIsLoading(false);
      setIsMissing(true);
      return;
    }
    setIsLoading(null);
    setTimeout(() => {
      setIsLoading((current) => (current === null ? true : current));
    }, 250);
    setIsMissing(false);
    setHasError(false);
    // No backdrop specified - don't try to load anything.
    if (!backdrop) {
      setIsLoading(false);
      return;
    }
  }, [backdrop, resolution]);

  const aspectRatio = resolution.replace("x", " / ");

  return (
    <div
      className={classNames("flex flex-row justify-center", className)}
      onClick={onClick}
    >
      <div style={{ aspectRatio }} className="bg-gray-800 w-full relative">
        {isLoading === true && (
          <LoadingSpinner className="text-white text-xl absolute inset-0" />
        )}
        {!!backdrop && !hasError && !isMissing && (
          <img
            loading="lazy"
            style={{ ...imgStyle, aspectRatio }}
            src={`/backdrops/${backdrop._id}/images/${resolution}${
              useThumbnail ? "/thumbnail" : ""
            }`}
            onLoad={() => {
              setIsLoading(false);
            }}
            onError={(e: any) => {
              setHasError(true);
              setIsLoading(false);
            }}
            className={classNames(
              "max-h-full w-full transition-opacity opacity-0 duration-75",
              isLoading === false &&
                !hasError &&
                !isMissing &&
                !!backdrop &&
                "opacity-100"
            )}
            alt={alt}
          ></img>
        )}
        {(hasError || isMissing || !backdrop) && (
          <div className="bg-gray-800 text-white flex flex-col items-center justify-center absolute inset-0">
            {(!!backdrop || isMissing || hasError) && (
              <IoIosWarning></IoIosWarning>
            )}
            {!compact && (
              <span className="text-xs text-center mx-2">
                {!!backdrop &&
                  isMissing &&
                  t("component_backdrop_preview_image:noBackdropForRes", {
                    type: t(
                      `common_objects:${backdropType}` as
                        | "common_objects:lockscreen"
                        | "common_objects:desktop"
                    ).toLowerCase(),
                  })}
                {hasError &&
                  t("component_backdrop_preview_image:backdropFetchError")}
                {!backdrop &&
                  t("component_backdrop_preview_image:noBackdropSet", {
                    type: t(
                      `common_objects:${backdropType}` as
                        | "common_objects:lockscreen"
                        | "common_objects:desktop"
                    ).toLowerCase(),
                  })}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(BackdropPreviewImage);
