import axios from "axios";

import { processApiError } from "./utility-functions";

export type DocumentDiff = {
  prop: string;
  pre: string;
  post: string;
};

export type EventDocument = {
  id: string;
  model: string;
  name: string;
  diff: DocumentDiff[];
};

export type EventLog = {
  __v: number;
  _id: string;
  event: string;
  sensitive: boolean;
  account: string;
  user: string;
  origin: string;
  document?: EventDocument;
  createdAt: string;
};

type EventLogsResponse = { data: EventLog[] };

export const getAllEventLogs = async (): Promise<EventLog[]> => {
  try {
    const eventLogsResponse = await axios.get<EventLogsResponse>(
      "/api/eventlogs"
    );
    return eventLogsResponse.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};

export const getEventLogsForDocument = async (
  id: string
): Promise<EventLog[]> => {
  try {
    const eventLogsResponse = await axios.get<EventLogsResponse>(
      `/api/eventlogs/document/${id}`
    );
    return eventLogsResponse.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};

export const getEventLogsForUser = async (id: string): Promise<EventLog[]> => {
  try {
    const eventLogsResponse = await axios.get<EventLogsResponse>(
      `/api/eventlogs/user/${id}`
    );
    return eventLogsResponse.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};
