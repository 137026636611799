import React, { FC, useMemo } from "react";

import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";

import { Backdrop } from "../api/backdrops";
import { Group } from "../api/groups";

import RadioButton from "./RadioButton";

const GroupsPageLightBoxMetaSection: FC<{
  backdrop: Backdrop | null;
  group: Group;
  resolution: string;
  onChangeBackdropType: (newType: "lockscreen" | "desktop") => void;
  onChangeResolution: (newResolution: string) => void;
}> = ({
  backdrop,
  group,
  resolution,
  onChangeBackdropType,
  onChangeResolution,
}) => {
  const { t } = useTranslation([
    "page_groups",
    "common_forms",
    "common_objects",
  ]);
  const resArray = useMemo(
    () => group.formats.map((format) => format.resolution),
    [group]
  );

  useHotkeys(
    "left, right",
    (event, handler) => {
      event.preventDefault();
      const currentIndex = resArray.indexOf(resolution);
      // next resolution to select.
      let newIndex =
        handler.key === "right" ? currentIndex + 1 : currentIndex - 1;

      // check for overflowing
      if (newIndex > resArray.length - 1) {
        newIndex = 0;
      } else if (newIndex < 0) {
        newIndex = resArray.length - 1;
      }
      onChangeResolution(resArray[newIndex]);
    },
    {},
    [resArray, resolution]
  );

  return (
    <>
      <h2 className="text-xl font-bold mb-4">
        {t("page_groups:backdropsLightboxTitle", {
          groupIdentifier: group.identifier,
        })}
      </h2>
      <div className="font-normal">
        <ul>
          <li>
            <span className="inline-block mr-2 font-bold">
              {t("page_groups:backdropName")}
            </span>
            <span>{backdrop?.name || t("common_forms:noneSelected")}</span>
          </li>
        </ul>
        <h3 className="mt-2 mb-2 font-bold text-lg">
          {t("page_groups:selectVariantToPreview")}
        </h3>

        {/* Only show the radio buttons if the backdrops are different for lockscreen and desktop */}
        {group.backdrops.desktop !== group.backdrops.lockscreen && (
          <>
            <RadioButton
              className="mb-2"
              name="backdropType"
              checked={backdrop?._id === group.backdrops.lockscreen}
              onChange={(e) => {
                if (e.target.checked) {
                  onChangeBackdropType("lockscreen");
                }
              }}
            >
              {t("common_objects:lockscreen")}
            </RadioButton>
            <RadioButton
              name="backdropType"
              checked={backdrop?._id === group.backdrops.desktop}
              onChange={(e) => {
                if (e.target.checked) {
                  onChangeBackdropType("desktop");
                }
              }}
            >
              {t("common_objects:desktop")}
            </RadioButton>
          </>
        )}
        <select
          className="input px-2 py-1 mt-2"
          value={resolution}
          onChange={(e) => onChangeResolution(e.target.value)}
        >
          {group.formats.map((format) => (
            <option value={format.resolution} key={format.resolution}>
              {format.resolution}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default GroupsPageLightBoxMetaSection;
