import { FC, useState } from "react";

import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { default as CreatableSelect } from "react-select/creatable";

import { addResolution } from "../api/groups";

import Button, { ButtonTypes } from "./Button";
import { centeredAndSizedByContent } from "./modalCustomStyles";
import selectCustomStyles from "./selectCustomStyles";

const AddResolutionModal: FC<{
  allResolutions: string[];
  alreadyAddedResolutions: string[];
  groupId: string;
  groupName: string;
  onRequestClose: (addedResolutions: string[] | null) => void;
}> = ({
  allResolutions,
  alreadyAddedResolutions,
  groupId,
  groupName,
  onRequestClose,
}) => {
  const { t } = useTranslation(["page_groups", "common_actions"]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resolutions, setResolutions] = useState<string[]>([]);
  return (
    <Modal
      isOpen={true}
      onRequestClose={() => onRequestClose(null)}
      style={{
        ...centeredAndSizedByContent,
        content: {
          ...centeredAndSizedByContent.content,
        },
      }}
    >
      <form className="w-96 flex flex-col" onSubmit={(e) => e.preventDefault()}>
        <h2 className="font-bold text-lg">
          {t("page_groups:addResolutionModalTitle", { groupName })}
        </h2>
        <p className="text-sm mb-2">
          {t("page_groups:addResolutionModalExplainerText")}
        </p>
        <label htmlFor="resolutions">{t("page_groups:resolution")}</label>
        <CreatableSelect
          id="resolutions"
          value={resolutions.map((r) => ({ value: r, label: r }))}
          onChange={(value, action) => {
            setResolutions(value.map((i) => i.value));
          }}
          styles={selectCustomStyles}
          isMulti={true}
          isValidNewOption={(inputValue) => {
            return !!/^\d+x\d+$/.exec(inputValue);
          }}
          isOptionDisabled={(option) =>
            alreadyAddedResolutions.includes(option.value)
          }
          options={allResolutions.map((res) => ({ label: res, value: res }))}
        />
        <div className="self-end mt-2">
          <Button
            type="button"
            className="mr-2"
            disabled={isLoading}
            onClick={() => onRequestClose(null)}
          >
            {t("common_actions:cancel")}
          </Button>
          <Button
            type="button"
            buttonType={ButtonTypes.positive}
            loading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              try {
                const promises = [];
                for (let i = 0; i < resolutions.length; i++) {
                  promises.push(
                    addResolution({
                      id: groupId,
                      resolution: resolutions[i],
                    })
                  );
                }
                await Promise.allSettled(promises);
                onRequestClose(resolutions);
              } catch (e) {
                // TODO: handle this.
                console.log("error", e);
              } finally {
                setIsLoading(false);
              }
            }}
          >
            {t("common_actions:add")}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddResolutionModal;
