import { PositionMode } from "./ImagePositionSelect";

export type XYValues = [number, number];

export type ObjectPosition = {
  origin: XYValues;
  scale: XYValues;
  zIndex: number;
};

export enum CanvasObjectTypes {
  Image,
  ObjectHandle,
}

export enum HandlePositions {
  TOP_LEFT = "tl",
  TOP_MID = "tm",
  TOP_RIGHT = "tr",
  LEFT_MID = "lm",
  RIGHT_MID = "rm",
  BOTTOM_LEFT = "bl",
  BOTTOM_MID = "bm",
  BOTTOM_RIGHT = "br",
}

export type CanvasObject = {
  id: string;
  type: CanvasObjectTypes;
};

export type CanvasImage = {
  type: CanvasObjectTypes.Image;
  imageElement: HTMLImageElement | null;
} & CanvasObject;

export type CanvasObjectHandle = {
  type: CanvasObjectTypes.ObjectHandle;
  handlePosition: HandlePositions;
  handleSize: number;
  forObjectId: string;
  position: ObjectPosition;
} & CanvasObject;

export function calculateConstrainedImagePosition(
  image: CanvasImage,
  positionMode: PositionMode,
  containerSize: XYValues
): Omit<ObjectPosition, "zIndex"> {
  let imageElement = image.imageElement;
  if (!imageElement)
    throw new Error(
      "Image without image element provided to calculateConstrainedImagePosition"
    );
  if (positionMode === "custom")
    throw new Error(
      "Unconstrained image given to calculateConstrainedImagePosition"
    );

  const [containerWidth, containerHeight] = containerSize;
  const containerAspectRatio = containerWidth / containerHeight;

  const imageNaturalAspectRatio =
    imageElement.naturalWidth / imageElement.naturalHeight;

  let outputPosition: [number, number] = [0, 0];
  let outputDimensions = [containerWidth, containerHeight];

  if (
    (positionMode === "cover" &&
      imageNaturalAspectRatio > containerAspectRatio) ||
    (positionMode === "contain" &&
      imageNaturalAspectRatio < containerAspectRatio)
  ) {
    // e.g. landscape image on portait screen. Cover = constrain by height
    outputDimensions[0] = outputDimensions[1] * imageNaturalAspectRatio;
    outputPosition[0] = -0.5 * (outputDimensions[0] - containerWidth);
  } else {
    // e.g. portrait image on landscape screen. Cover = Width full, height overflowing
    outputDimensions[1] = outputDimensions[0] / imageNaturalAspectRatio;
    outputPosition[1] = -0.5 * (outputDimensions[1] - containerHeight);
  }
  let scale: [number, number] = [
    outputDimensions[0] / imageElement.naturalWidth,
    outputDimensions[1] / imageElement.naturalHeight,
  ];
  return {
    origin: outputPosition,
    scale: scale,
  };
}

export function drawCanvasObject(
  object: CanvasObject,
  context: CanvasRenderingContext2D,
  origin: XYValues,
  scale: XYValues
) {
  switch (object.type) {
    case CanvasObjectTypes.Image: {
      let image = object as CanvasImage;
      if (!image.imageElement) return;
      context.drawImage(
        image.imageElement,
        origin[0],
        origin[1],
        scale[0] * image.imageElement.naturalWidth,
        scale[1] * image.imageElement.naturalHeight
      );
      break;
    }
    default:
      throw new Error("Unknown canvas object type");
  }
}

export function drawObjectSelectionDecorators(
  object: CanvasObject,
  context: CanvasRenderingContext2D,
  origin: XYValues,
  scale: XYValues,
  dragHandleSize: number
) {
  switch (object.type) {
    case CanvasObjectTypes.Image: {
      let image = object as CanvasImage;
      if (!image.imageElement) return;
      context.save(); // Translated to start of backdrop.
      context.strokeStyle = "hotpink";

      let dims = [
        scale[0] * image.imageElement.naturalWidth,
        scale[1] * image.imageElement.naturalHeight,
      ];

      // Draw image bounds.
      context.strokeRect(origin[0], origin[1], dims[0], dims[1]);

      const handleSize = dragHandleSize;
      const handlePositions = [
        // Corners
        [origin[0], origin[1]], // TL
        [origin[0] + dims[0], origin[1]], // TR
        [
          // BR
          origin[0] + dims[0],
          origin[1] + dims[1],
        ],
        [origin[0], origin[1] + dims[1]], // BL
        // Side midpoints.
        [origin[0] + 0.5 * dims[0], origin[1]], // TM
        [
          // RM
          origin[0] + dims[0],
          origin[1] + 0.5 * dims[1],
        ],
        [
          // BM
          origin[0] + 0.5 * dims[0],
          origin[1] + dims[1],
        ],
        [origin[0], origin[1] + 0.5 * dims[1]], // LM
      ];
      handlePositions.forEach((handlePosition) => {
        context.save();
        context.translate(handlePosition[0], handlePosition[1]);
        context.fillStyle = "white";
        context.strokeStyle = "black";
        context.fillRect(
          Math.ceil(-0.5 * handleSize),
          Math.ceil(-0.5 * handleSize),
          handleSize,
          handleSize
        );
        context.fillRect(
          Math.ceil(-0.5 * handleSize),
          Math.ceil(-0.5 * handleSize),
          handleSize,
          handleSize
        );
        context.strokeRect(
          Math.ceil(-0.5 * handleSize),
          Math.ceil(-0.5 * handleSize),
          handleSize,
          handleSize
        );
        context.restore();
      });

      context.restore(); // Translated to start of backdrop.
      break;
    }
    default:
      throw new Error("Unknown canvas object type");
  }
}

export function getObjectHandles(
  object: CanvasObject,
  origin: XYValues,
  scale: XYValues,
  handleSize: number
): CanvasObjectHandle[] {
  let handles: CanvasObjectHandle[] = [];
  switch (object.type) {
    case CanvasObjectTypes.Image: {
      let image = object as CanvasImage;
      if (!image.imageElement) return [];
      let dims = [
        scale[0] * image.imageElement.naturalWidth,
        scale[1] * image.imageElement.naturalHeight,
      ];
      const handlePositions: { [pos in HandlePositions]: [number, number] } = {
        [HandlePositions.TOP_LEFT]: [origin[0], origin[1]],
        [HandlePositions.TOP_RIGHT]: [origin[0] + dims[0], origin[1]],
        [HandlePositions.BOTTOM_RIGHT]: [
          origin[0] + dims[0],
          origin[1] + dims[1],
        ],
        [HandlePositions.BOTTOM_LEFT]: [origin[0], origin[1] + dims[1]],
        [HandlePositions.TOP_MID]: [origin[0] + 0.5 * dims[0], origin[1]],
        [HandlePositions.RIGHT_MID]: [
          origin[0] + dims[0],
          origin[1] + 0.5 * dims[1],
        ],
        [HandlePositions.BOTTOM_MID]: [
          origin[0] + 0.5 * dims[0],
          origin[1] + dims[1],
        ],
        [HandlePositions.LEFT_MID]: [origin[0], origin[1] + 0.5 * dims[1]],
      };

      Object.keys(handlePositions).forEach((handlePositionName) => {
        let handlePosition: [number, number] =
          handlePositions[handlePositionName as HandlePositions];
        let handle: CanvasObjectHandle = {
          type: CanvasObjectTypes.ObjectHandle,
          handleSize,
          id: `${object.id}-handle-${handlePositionName}`,
          position: {
            origin: handlePosition,
            scale: scale,
            zIndex: Infinity,
          },
          forObjectId: object.id,
          handlePosition: handlePositionName as HandlePositions,
        };
        handles.push(handle);
      });
      break;
    }
    default:
      throw new Error("Unknown canvas object type");
  }
  return handles;
}

export function getObjectBounds(
  object: CanvasObject,
  origin: XYValues,
  scale: XYValues
): {
  x: number;
  y: number;
  width: number;
  height: number;
} | null {
  switch (object.type) {
    case CanvasObjectTypes.Image: {
      let image = object as CanvasImage;
      if (!image.imageElement) return null;
      else
        return {
          x: origin[0],
          y: origin[1],
          width: scale[0] * image.imageElement.naturalWidth,
          height: scale[1] * image.imageElement.naturalHeight,
        };
    }
    case CanvasObjectTypes.ObjectHandle: {
      let handle = object as CanvasObjectHandle;
      return {
        x: origin[0] + Math.ceil(-0.5 * handle.handleSize),
        y: origin[1] + Math.ceil(-0.5 * handle.handleSize),
        width: handle.handleSize,
        height: handle.handleSize,
      };
    }
    default: {
      return null;
    }
  }
}
