import { HandlePositions } from "./CanvasObject";

export const resolutionStringToXYArray: (
  resolution: string
) => [number, number] = (res) => {
  let [x, y] = res.split("x");
  return [parseInt(x), parseInt(y)];
};

/**
 * Given an amount moved, and the current size of the shape, this function will
 * calculate the diagonal component of the movement as a fraction of the
 * length of the existing shape's diagonal (the scale change), and the change
 * in width and height of the component. It's used to for constrained aspect
 * ratio resizes.
 */
export const getDiagonalComponentRatio = (
  amountMoved: { dx: number; dy: number },
  currentSize: { width: number; height: number },
  handlePosition: HandlePositions
) => {
  let currentDiaganalLength = Math.sqrt(
    Math.pow(currentSize.width, 2) + Math.pow(currentSize.height, 2)
  );
  // Angle between diagonal and horizontal
  let alpha = Math.atan(currentSize.height / currentSize.width);
  let diagonalComponentOfX = amountMoved.dx * Math.cos(alpha);
  let diagonalComponentOfY = amountMoved.dy * Math.sin(alpha);
  if (
    handlePosition === HandlePositions.TOP_RIGHT ||
    handlePosition === HandlePositions.TOP_LEFT
  ) {
    diagonalComponentOfY *= -1;
  }
  if (
    handlePosition === HandlePositions.BOTTOM_LEFT ||
    handlePosition === HandlePositions.TOP_LEFT
  ) {
    diagonalComponentOfX *= -1;
  }
  let totalDiagonalComponent = diagonalComponentOfX + diagonalComponentOfY;
  let proportionOfCurrentDiagonalMoved =
    totalDiagonalComponent / currentDiaganalLength;
  return {
    scaleChange: proportionOfCurrentDiagonalMoved,
    sizeChange: {
      width: totalDiagonalComponent * Math.cos(alpha),
      height: totalDiagonalComponent * Math.sin(alpha),
    },
  };
};
