import { FC, ReactNode } from "react";

import { IconType } from "react-icons";
import { IoIosWarning, IoIosClose } from "react-icons/io";
import { RiErrorWarningFill, RiInformationFill } from "react-icons/ri";

import classNames from "classnames";

export enum CalloutTypes {
  error,
  info,
  warning,
}

export const defaultIcons = {
  [CalloutTypes.error]: RiErrorWarningFill,
  [CalloutTypes.info]: RiInformationFill,
  [CalloutTypes.warning]: IoIosWarning,
};

export const bgColourClasses = {
  [CalloutTypes.error]: "bg-red-700",
  [CalloutTypes.info]: "bg-gray-400 dark:bg-gray-700",
  [CalloutTypes.warning]: "bg-yellow-500",
};

const Callout: FC<{
  icon?: IconType;
  noIcon?: boolean;
  dismissable?: boolean;
  onDismiss?: () => void;
  content?: ReactNode;
  type?: CalloutTypes;
  className?: string;
}> = ({
  icon,
  content,
  noIcon,
  dismissable = false,
  onDismiss,
  type = CalloutTypes.info,
  className,
  children,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-row text-white py-2 px-3 items-center relative",
        bgColourClasses[type],
        className
      )}
    >
      {!noIcon && (
        <div className="text-2xl mr-4">{icon || defaultIcons[type]({})}</div>
      )}
      <div className="text-sm">{content || children}</div>
      {dismissable && (
        <button className="absolute -top-1 -right-1 p-2" onClick={onDismiss}>
          <IoIosClose />
        </button>
      )}
    </div>
  );
};

export default Callout;
