import React, { Suspense } from "react";

import ReactDOM from "react-dom";
import Modal from "react-modal";
import { ToastProvider } from "react-toast-notifications";

import axios from "axios";
import "typeface-source-sans-pro";

import LoadingSpinner from "./Components/LoadingSpinner";

import App from "./App";
import { FreshChatWidget } from "./freshChat";
import "./i18n/i18n";
import reportWebVitals from "./reportWebVitals";
import "./tailwind.css";

axios.defaults.xsrfCookieName = "backdrops-xsrf";
axios.defaults.xsrfHeaderName = "x-xsrf-token";

// http://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement("#root");

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingSpinner />}>
      <ToastProvider>
        <App />
      </ToastProvider>
    </Suspense>
    <FreshChatWidget />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
