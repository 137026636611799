import { FC, MouseEvent, useState } from "react";

import { BiDotsHorizontal } from "react-icons/bi";
import { Popover } from "react-tiny-popover";

import classNames from "classnames";

export type MenuItem = {
  text: string;
  disabled?: boolean;
  show?: boolean;
  onClick: (e: MouseEvent<HTMLLIElement, globalThis.MouseEvent>) => void;
  shouldStayOpenAfterClick?: boolean;
};

const DropDownMenu: FC<{
  menuItems: MenuItem[];
  className?: string;
  menuItemClassName?: string;
  align?: "end" | "start" | "center";
}> = ({ className, align = "end", menuItems, menuItemClassName }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Popover
      isOpen={isOpen}
      positions={["bottom", "top", "left", "right"]}
      align={align}
      content={
        <div className="bg-white dark:bg-gray-900 shadow-lg border border-gray-300 dark:border-gray-600 rounded-sm">
          <ul>
            {menuItems
              .filter((item) => item.show !== false)
              .map((menuItem, i) => (
                <li
                  key={i}
                  className={classNames(
                    "px-2 py-1 transition-colors text-right",
                    !menuItem.disabled
                      ? "hover:bg-gray-300 dark:hover:bg-gray-600  cursor-pointer"
                      : menuItem.disabled && "text-gray-400 cursor-not-allowed",
                    menuItemClassName
                  )}
                  onClick={(e) => {
                    if (menuItem.disabled) return;
                    menuItem.onClick(e);
                    if (!menuItem.shouldStayOpenAfterClick) setIsOpen(false);
                  }}
                >
                  {menuItem.text}
                </li>
              ))}
          </ul>
        </div>
      }
      onClickOutside={() => setIsOpen(false)}
      reposition={false}
    >
      <div
        className={classNames(
          "rounded-sm w-6 h-6 text-2xl flex items-center justify-center cursor-pointer",
          className
        )}
      >
        <BiDotsHorizontal
          onClick={() => {
            setIsOpen((o) => !o);
          }}
        />
      </div>
    </Popover>
  );
};

export default DropDownMenu;
