import React, { FC, useState } from "react";

import Modal from "react-modal";
import { useHistory, useRouteMatch } from "react-router-dom";

import useQuery from "../Hooks/useQuery";

import SaveAndPublishForm from "../Components/SaveAndPublishForm";
import { centeredAndSizedByContent } from "../Components/modalCustomStyles";
import NewBackdropForm, { NewBackdropMeta } from "../Forms/NewBackdropForm";

import BackdropEditor from "../BackdropEditor";
import { BackdropEditorState } from "../backdropEditorReducer";

const EditBackdropPage: FC<{}> = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const queryParams = useQuery();
  const history = useHistory();

  const [newBackdropMeta, setNewBackdropMeta] =
    useState<NewBackdropMeta | null>(null);

  const [dataToPublish, setDataToPublish] = useState<null | {
    backdropsData: BackdropEditorState["backdrops"];
    objectLibrary: BackdropEditorState["objectLibrary"];
  }>(null);

  const showNewBackdropForm = params.id === "new" && newBackdropMeta === null;

  return (
    <div className="h-full">
      <Modal isOpen={showNewBackdropForm} style={centeredAndSizedByContent}>
        <NewBackdropForm
          initialTargetGroupIdentifier={queryParams.get("groupIdentifier")}
          onComplete={(newBd) => {
            setNewBackdropMeta(newBd);
          }}
        />
      </Modal>
      <Modal isOpen={!!dataToPublish}>
        {dataToPublish && (
          <SaveAndPublishForm
            backdropsData={dataToPublish.backdropsData}
            objectLibrary={dataToPublish.objectLibrary}
            newBackdropMeta={newBackdropMeta!}
            onCancel={() => {
              setDataToPublish(null);
            }}
            onComplete={() => {
              if (!!queryParams.get("groupIdentifier")) {
                history.push("/groups");
              } else {
                history.push("/backdrops");
              }
            }}
          />
        )}
      </Modal>
      <BackdropEditor
        resolutions={newBackdropMeta?.resolutions}
        onPublish={(data) => {
          setDataToPublish(data);
        }}
      />
    </div>
  );
};

export default EditBackdropPage;
