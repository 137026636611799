import React from "react";
import { FC, useState } from "react";

import { useTranslation } from "react-i18next";
import { PieChart } from "react-minimal-pie-chart";

import classNames from "classnames";

import { Backdrop } from "../api/backdrops";
import { EndpointSummary } from "../api/endpoints";
import { Group } from "../api/groups";

import BackdropPreviewImage from "./BackdropPreviewImage";
import GroupActionMenu from "./GroupActionMenu";

const donutColours = {
  current: "rgb(5, 150, 105)",
  previous: "rgb(252, 211, 77)", // yellow-300
  older: "rgb(220, 38, 38)",
  none: "rgb(88, 88, 88)",
};

const GroupListItem: FC<{
  group: Group;
  endpointSummary: EndpointSummary;
  backdrops: Backdrop[];
  onGroupDeleted: (groupId: string) => void;
  onGroupModified: (group: Group) => void;
  onThumbnailClick: (
    backdropId: string,
    resolution: string,
    groupId: string
  ) => void;
  allResolutions: string[];
  swapDesktopAndLockscreen?: boolean;
}> = ({
  group,
  endpointSummary,
  backdrops,
  onGroupDeleted,
  onGroupModified,
  allResolutions,
  swapDesktopAndLockscreen = false,
  onThumbnailClick,
}) => {
  const { t } = useTranslation(["page_groups"]);
  const [selectedResolution, setSelectedResolution] = useState<string>(
    Object.keys(endpointSummary.resolutions).reduce(
      (mostPopular, resolution) => {
        if (!mostPopular) return resolution;
        if (
          endpointSummary.resolutions[resolution].total >
          endpointSummary.resolutions[mostPopular].total
        )
          return resolution;
        else return mostPopular;
      },
      ""
    ) ||
      group.formats[0].resolution ||
      ""
  );

  const findBackdropById = (backdropId?: string | null) =>
    backdropId ? backdrops.find((bd) => bd._id === backdropId) : null;

  return (
    <>
      <div className="w-32 pr-4 pb-2">
        <div className="relative h-full w-full cursor-pointer">
          <BackdropPreviewImage
            backdrop={
              swapDesktopAndLockscreen
                ? findBackdropById(group.backdrops.desktop)
                : findBackdropById(group.backdrops.lockscreen)
            }
            onClick={() =>
              onThumbnailClick(
                (swapDesktopAndLockscreen
                  ? group.backdrops.desktop
                  : group.backdrops.lockscreen) || "",
                selectedResolution,
                group._id
              )
            }
            backdropType={swapDesktopAndLockscreen ? "desktop" : "lockscreen"}
            resolution={selectedResolution}
            className="shadow -top-2 opacity-40"
            alt={"temp"}
          />
          <BackdropPreviewImage
            backdrop={
              swapDesktopAndLockscreen
                ? findBackdropById(group.backdrops.lockscreen)
                : findBackdropById(group.backdrops.desktop)
            }
            onClick={() =>
              onThumbnailClick(
                (swapDesktopAndLockscreen
                  ? group.backdrops.desktop
                  : group.backdrops.lockscreen) || "",
                selectedResolution,
                group._id
              )
            }
            backdropType={swapDesktopAndLockscreen ? "lockscreen" : "desktop"}
            resolution={selectedResolution}
            className="absolute shadow top-2 left-2 w-full"
            alt={"temp"}
          />
        </div>
      </div>
      <div>{group.identifier}</div>
      <div>{endpointSummary.total}</div>
      {(["desktop", "lockscreen"] as Array<"desktop" | "lockscreen">).map(
        (backdropType) => (
          <div className="flex flex-row items-center" key={backdropType}>
            <PieChart
              lineWidth={50}
              className="h-10 w-10 mr-4"
              startAngle={-90}
              data={Object.keys(endpointSummary?.downloads[backdropType]).map(
                (status) => ({
                  title: t("page_groups:status", { context: status })!,
                  color:
                    donutColours[
                      status as keyof EndpointSummary["downloads"]["desktop"]
                    ],
                  value:
                    endpointSummary?.downloads[backdropType][
                      status as keyof EndpointSummary["downloads"]["desktop"]
                    ],
                })
              )}
            />
            <div className="flex flex-col flex-1 text-xs">
              <ul>
                {Object.keys(endpointSummary?.downloads[backdropType]).map(
                  (status) => (
                    <li
                      className="flex items-center whitespace-nowrap"
                      key={`${backdropType}-${status}`}
                    >
                      <div
                        className="rounded-full w-2 h-2 mr-1"
                        style={{
                          backgroundColor:
                            donutColours[
                              status as keyof EndpointSummary["downloads"]["desktop"]
                            ],
                        }}
                      ></div>
                      {
                        endpointSummary?.downloads[backdropType][
                          status as keyof EndpointSummary["downloads"]["desktop"]
                        ]
                      }{" "}
                      {/* t("page_groups:status", { context: "current" }) */}
                      {/* t("page_groups:status", { context: "previous" }) */}
                      {/* t("page_groups:status", { context: "older" }) */}
                      {/* t("page_groups:status", { context: "none" }) */}
                      {t("page_groups:status", { context: status })}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        )
      )}
      <div>
        {(group.formats || [])
          .sort((formatA, formatB) => {
            return (
              endpointSummary?.resolutions[formatB.resolution]?.total ||
              0 - endpointSummary?.resolutions[formatA.resolution]?.total ||
              0
            );
          })
          .map((format, i) => {
            const resolution = format.resolution;
            const resolutionData = endpointSummary?.resolutions[resolution];
            return (
              <span
                key={resolution}
                onClick={() => {
                  if (selectedResolution !== resolution) {
                    setSelectedResolution(resolution);
                  }
                }}
                className={classNames(
                  "text-xs p-1 text-white rounded-sm m-1 ml-0 mt-0 cursor-pointer bg-blue-300 inline-block",
                  // darker background if selected
                  selectedResolution === resolution
                    ? "bg-blue-800"
                    : "bg-blue-300"
                )}
              >
                {resolution} ({resolutionData?.total || 0})
              </span>
            );
          })}
      </div>
      <div className="justify-self-end">
        <GroupActionMenu
          group={group}
          onGroupDeleted={onGroupDeleted}
          onGroupModified={onGroupModified}
          endpointSummary={endpointSummary}
          allResolutions={allResolutions}
          selectedResolution={selectedResolution}
        />
      </div>
      <div className="col-span-full border-b border-gray-200 dark:border-gray-600 my-4"></div>
    </>
  );
};

export default React.memo(GroupListItem);
