import { CSSProperties, FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";

import { Backdrop, deleteBackdrop, getBackdrops } from "../api/backdrops";
import { getGroups, Group } from "../api/groups";

import { remPixels } from "../Hooks/useRem";

import BackdropPreviewImage from "../Components/BackdropPreviewImage";
import Button, { ButtonTypes } from "../Components/Button";
import ConfirmationModal from "../Components/ConfirmationModal";
import DropDownMenu from "../Components/DropdownMenu";
import LoadingSpinner from "../Components/LoadingSpinner";
import PublishExistingBackdropModal from "../Components/PublishExistingBackdropModal";

import { resolutionStringToXYArray } from "../utility";

const backdropWidthRem = 10;

const BackdropRow: FC<{
  style: CSSProperties;
  index: number;
  data: {
    backdrops: Backdrop[];
    setShowDeleteModalForId: (id: string) => void;
    setShowPublishModalForBackdrop: (backdrop: Backdrop) => void;
  };
}> = ({ style, index, data }) => {
  const { backdrops, setShowPublishModalForBackdrop, setShowDeleteModalForId } =
    data;
  const bd = backdrops[index];
  return (
    <div key={bd._id} style={style} className="flex flex-row">
      <div className="flex flex-col justify-center w-40 flex-shrink-0">
        <h2 className="font-bold">{bd.name}</h2>
        <h2 className="text-sm">
          {new Date(bd.createdAt).toLocaleDateString()}
          {" at "}
          {new Date(bd.createdAt).toLocaleTimeString()}
        </h2>
      </div>
      <div className="flex flex-row items-center flex-1 overflow-x-auto">
        {bd.formats.map((format) => (
          <div
            key={`${bd._id}-${format.resolution}-backdrop`}
            style={{
              width: `${backdropWidthRem}rem`,
              flexShrink: 0,
            }}
          >
            <BackdropPreviewImage
              key={`${bd._id}-${format.resolution}-preview`}
              className="m-2"
              alt={`${bd.name} @${format.resolution}`}
              resolution={format.resolution}
              backdrop={bd}
            />
          </div>
        ))}
      </div>
      <DropDownMenu
        className="justify-self-end self-center"
        menuItems={[
          {
            text: "Delete",
            shouldStayOpenAfterClick: false,
            onClick: () => setShowDeleteModalForId(bd._id),
          },
          {
            text: "Publish to group(s)",
            shouldStayOpenAfterClick: false,
            onClick: () => setShowPublishModalForBackdrop(bd),
          },
        ]}
      />
      <div className="col-span-full border-b border-gray-200 dark:border-gray-600 my-4"></div>
    </div>
  );
};

const BackdropsPage: FC<{}> = () => {
  const { t } = useTranslation(["page_backdrops"]);
  const [backdrops, setBackdrops] = useState<Backdrop[] | null>(null);
  const [groups, setGroups] = useState<Group[] | null>(null);

  const [showDeleteModalForId, setShowDeleteModalForId] = useState<
    string | null
  >(null);
  const [showPublishModalForBackdrop, setShowPublishModalForBackdrop] =
    useState<Backdrop | null>(null);

  const { addToast } = useToasts();
  const history = useHistory();

  const getRowHeight: (index: number) => number = (index) => {
    const backdrop = backdrops && backdrops[index];
    if (!backdrop) return 0;
    // Talest format is the one with the narrowest (smallest) aspect ratio
    // (assuming we're fixing the width)
    const tallestFormatAspectRatio = backdrop.formats.reduce(
      (currentTallest, next) => {
        const [x, y] = resolutionStringToXYArray(next.resolution);
        const aspectRatio = x / y;
        if (aspectRatio < currentTallest) return aspectRatio;
        return currentTallest;
      },
      Infinity
    );

    const heightInRem = backdropWidthRem / tallestFormatAspectRatio;
    const heightInPx = remPixels * (heightInRem + 1);
    return heightInPx;
  };

  useEffect(() => {
    // TODO: error handling.
    getBackdrops().then((bds) => {
      setBackdrops(bds);
    });

    getGroups().then((groups) => {
      setGroups(groups);
    });
  }, []);

  return (
    <>
      {backdrops === null || groups === null ? (
        <LoadingSpinner />
      ) : (
        <div className="h-full p-6 max-w-full">
          {!!showPublishModalForBackdrop && (
            <PublishExistingBackdropModal
              onCancel={() => setShowPublishModalForBackdrop(null)}
              onPublishResult={() => {
                // TODO: deal with failures.
                addToast(t("page_backdrops:onPublishSuccess"), {
                  appearance: "success",
                  autoDismiss: true,
                });
                setShowPublishModalForBackdrop(null);
              }}
              backdrop={showPublishModalForBackdrop}
              groups={groups}
            />
          )}
          {!!showDeleteModalForId && (
            <ConfirmationModal
              isOpen={true}
              message="Are you sure you want to delete this?"
              isIrreversible={true}
              onCancel={() => setShowDeleteModalForId(null)}
              onConfirm={async () => {
                deleteBackdrop(showDeleteModalForId);
                setBackdrops((prev) =>
                  prev!.filter((bd) => bd._id !== showDeleteModalForId)
                );
                setShowDeleteModalForId(null);
                addToast("Backdrop deleted", {
                  appearance: "success",
                  autoDismiss: true,
                });
              }}
            />
          )}
          <div
            className="flex flex-col h-full max-w-full"
            style={{
              gridTemplateColumns: "repeat(3, auto)",
            }}
          >
            <Button
              className="self-end mb-4 -mt-2"
              buttonType={ButtonTypes.positive}
              onClick={() => {
                history.push("/backdrops/editor/new/");
              }}
            >
              {t("page_backdrops:createNewBackdrop")}
            </Button>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  className="rounded-sm"
                  height={height}
                  width={width}
                  itemCount={backdrops.length}
                  itemSize={getRowHeight}
                  itemData={{
                    backdrops: backdrops,
                    setShowDeleteModalForId,
                    setShowPublishModalForBackdrop,
                  }}
                >
                  {BackdropRow}
                </List>
              )}
            </AutoSizer>
          </div>
        </div>
      )}
    </>
  );
};

export default BackdropsPage;
