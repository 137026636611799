import { FC, useState } from "react";

import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

import classNames from "classnames";

import useSession from "../Hooks/useSession";

import EnableOTPForm from "../Forms/EnableOTPForm";
import ChangePasswordModal from "../Pages/ChangePasswordModal";

import DropDownMenu from "./DropdownMenu";
import UserAvatar from "./UserAvatar";
import { centeredAndSizedByContent } from "./modalCustomStyles";

const LoggedInUserDetails: FC<{
  className?: string;
}> = ({ className }) => {
  const history = useHistory();
  const { session, updateSession } = useSession(true);
  const [showOTPForm, setShowOTPForm] = useState<boolean>(false);
  const [showChangePasswordModal, setShowChangePasswordModal] =
    useState<boolean>(false);

  const { t } = useTranslation(["common_user"]);

  if (!session?.user) return <></>;
  const otpEnabled = session.user.credentials.otp.enabled;
  return (
    <div
      className={classNames(
        "flex flex-row p-1 pl-2 border border-gray-200 dark:border-gray-600 rounded-md items-center",
        className
      )}
    >
      {showChangePasswordModal && (
        <ChangePasswordModal
          onRequestClose={() => setShowChangePasswordModal(false)}
        />
      )}
      <Modal
        style={centeredAndSizedByContent}
        isOpen={showOTPForm}
        onRequestClose={() => setShowOTPForm(false)}
      >
        <EnableOTPForm
          otpIsEnabled={otpEnabled}
          onCloseRequest={(result) => {
            if (result.changeMade && result.otpEnabled !== undefined) {
              updateSession({
                ...session,
                user: {
                  ...session.user,
                  credentials: {
                    ...session.user.credentials,
                    otp: { enabled: result.otpEnabled },
                  },
                },
              });
            }
            setShowOTPForm(false);
          }}
        />
      </Modal>
      <UserAvatar
        email={session.user.email}
        className="mr-2"
        id={session.user._id}
      />
      <div className="flex flex-col">
        <div>{session.user.name}</div>
        <div className="text-xs -mt-1">{session.account.name}</div>
      </div>
      <DropDownMenu
        className="ml-2"
        menuItems={[
          {
            text: otpEnabled
              ? t("common_user:disable2fa")
              : t("common_user:enable2fa"),
            onClick: () => {
              setShowOTPForm(true);
            },
          },
          {
            text: t("common_user:changePassword"),
            show: !session.user.credentials.azure,
            onClick: () => {
              setShowChangePasswordModal(true);
            },
          },
          {
            text: t("common_user:logout"),
            onClick: () => {
              history.push("/logout");
            },
          },
        ]}
      />
    </div>
  );
};

export default LoggedInUserDetails;
