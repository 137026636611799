import { useEffect, useRef } from "react";

const useIntervalWhenVisible = (
  intervalFunction: () => void,
  delay: number,
  fireImmediately?: boolean
) => {
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    const fireIfVisible = (isFirstTime: boolean) => {
      if (document.visibilityState === "visible") {
        // Fire if it's just a normal interval trigger, or if it's the first
        // time and we've been asked to fire immediately.
        if (!isFirstTime || fireImmediately) intervalFunction();

        // Set up the interval - this will be first time only but we can just
        // check the interval ref for safety.
        if (!intervalRef.current) {
          intervalRef.current = window.setInterval(() => {
            fireIfVisible(false);
          }, delay);
        }
      } else {
        // Window isn't visible, clear the current interval
        if (intervalRef.current) {
          window.clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        // Register an event listener to fire when we come back
        const wentInvisibleAt = Date.now();
        document.addEventListener(
          "visibilitychange",
          () => {
            if (document.visibilityState === "visible") {
              // Fire if we were invisible for longer than the delay.
              if (Date.now() - wentInvisibleAt >= delay) intervalFunction();
              // Set up the regular interval again.
              intervalRef.current = window.setInterval(() => {
                fireIfVisible(false);
              }, delay);
            }
          },
          { passive: true, once: true }
        );
      }
    };

    fireIfVisible(true);

    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [intervalFunction, delay, fireImmediately]);
};

export default useIntervalWhenVisible;
