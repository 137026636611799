import { FC, useState } from "react";

import Gravatar from "react-gravatar";

import classNames from "classnames";

const UserAvatar: FC<{
  id: string;
  email: string;
  className?: string;
  showMicrosoftLogo?: boolean;
}> = ({ id, email, showMicrosoftLogo, className }) => {
  // Determines whether we can serve a profile photo (e.g. from Azure).
  // Null when loading, false if errored and should fall back to gravatar
  const [nativeProfileSuccess, setNativeProfileSuccess] = useState<
    boolean | null
  >(null);

  return (
    <div
      className={classNames(
        "w-8 h-8 flex items-center justify-center relative",
        className
      )}
    >
      {nativeProfileSuccess !== false ? (
        <img
          loading="lazy"
          className={classNames(
            "opacity-0 transition-opacity w-full h-full rounded-full",
            nativeProfileSuccess && "opacity-100"
          )}
          alt={`${email}'s avatar`}
          src={`/users/${id}/profile-image`}
          onLoad={() => setNativeProfileSuccess(true)}
          onError={() => setNativeProfileSuccess(false)}
        />
      ) : (
        // size: 30
        <Gravatar email={email} size={32} className="rounded-full" />
      )}
      {showMicrosoftLogo && (
        <img
          alt="Microsoft logo"
          src="/microsoft/ms_logo.svg"
          className="-bottom-1 -right-1 w-4 h-4 absolute z-10"
        />
      )}
    </div>
  );
};

export default UserAvatar;
