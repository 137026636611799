import React, { FC, useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { IoIosWarning } from "react-icons/io";
import { RiCheckFill } from "react-icons/ri";
import Modal from "react-modal";

import { Backdrop } from "../api/backdrops";
import { Group, updateGroup } from "../api/groups";

import Button, { ButtonTypes } from "./Button";
import Checkbox from "./Checkbox";
import { centeredAndSizedByContent } from "./modalCustomStyles";

const PublishExistingBackdropModal: FC<{
  onCancel: () => void;
  onPublishResult: () => void;
  backdrop: Backdrop;
  groups: Group[];
}> = ({ onCancel, onPublishResult, backdrop, groups }) => {
  const { t } = useTranslation([
    "modal_publishbackdrop",
    "common_actions",
    "common_objects",
  ]);

  const [onlyAllFulfilledLockscreens, setOnlyAllFulfilledLockscreens] =
    useState<boolean>(true);

  const [isPublishing, setIsPublishing] = useState<boolean>(false);

  const [publishTargets, setPublishTargets] = useState<{
    [groupId: string]: {
      lockscreen: boolean;
      desktop: boolean;
    };
  }>({});

  // TODO: error handling if any fail.
  const onPublish = useCallback(
    async (e) => {
      e.preventDefault();
      setIsPublishing(true);
      const promises: Promise<Group>[] = [];
      Object.keys(publishTargets).forEach((groupId) => {
        const newBackdropsObject: Partial<Group["backdrops"]> = {};
        if (publishTargets[groupId].desktop)
          newBackdropsObject.desktop = backdrop._id;
        if (publishTargets[groupId].lockscreen)
          newBackdropsObject.lockscreen = backdrop._id;
        promises.push(updateGroup(groupId, { backdrops: newBackdropsObject }));
      });
      await Promise.allSettled(promises);
      setIsPublishing(false);
      onPublishResult();
    },
    [backdrop._id, publishTargets, onPublishResult]
  );

  const backdropResolutions = backdrop.formats.map(
    (format) => format.resolution
  );

  const filteredGruops = onlyAllFulfilledLockscreens
    ? groups.filter((group) =>
        group.formats.every((format) =>
          backdropResolutions.includes(format.resolution)
        )
      )
    : groups;

  const missingResolutionsByGroup = useMemo(
    () =>
      onlyAllFulfilledLockscreens
        ? {}
        : groups.reduce((acc: { [groupId: string]: string[] }, group) => {
            const groupResolutions = group.formats.map((f) => f.resolution);
            const missingResolutions = groupResolutions.filter(
              (res) => !backdropResolutions.includes(res)
            );
            if (missingResolutions.length) {
              acc[group._id] = missingResolutions;
            }
            return acc;
          }, {}),
    [backdropResolutions, groups, onlyAllFulfilledLockscreens]
  );

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        if (!isPublishing) onCancel();
      }}
      style={centeredAndSizedByContent}
    >
      <div className="flex flex-col max-h-screen">
        <h2 className="text-lg font-bold mb-2">
          {t("modal_publishbackdrop:title", { backdropName: backdrop.name })}
        </h2>
        <div className="flex my-2">
          <Checkbox
            multiLine={true}
            checked={onlyAllFulfilledLockscreens}
            onChange={(e) => setOnlyAllFulfilledLockscreens(e.target.checked)}
          >
            <span className="block max-w-sm">
              {t("modal_publishbackdrop:onlyMatchingResolutions")}
            </span>
          </Checkbox>
        </div>
        <div
          className="grid gap-y-2 gap-x-8 mt-2 items-center overflow-y-auto flex-shrink"
          style={{
            gridTemplateColumns: "repeat(3, auto)",
          }}
        >
          <div className="contents font-bold">
            <div className="sticky top-0 bg-white dark:bg-gray-900">Group</div>
            <div className="sticky top-0 bg-white dark:bg-gray-900 justify-self-center">
              {t("common_objects:desktop")}
            </div>
            <div className="sticky top-0 bg-white dark:bg-gray-900 justify-self-center">
              {t("common_objects:lockscreen")}
            </div>
          </div>
          {filteredGruops.map((group) => (
            <React.Fragment key={`checkboxes-${group._id}`}>
              <div className="leading-4 flex flex-col">
                <div className="flex">
                  <span>{group.identifier}</span>
                  {!!missingResolutionsByGroup[group._id] && (
                    <IoIosWarning className="ml-2 text-yellow-500" />
                  )}
                </div>
                {!!missingResolutionsByGroup[group._id] && (
                  <div className="text-xs flex flex-col">
                    <div className="flex items-center mt-1">
                      <span className="font-semibold">
                        {t("modal_publishbackdrop:unsupportedResolutions")}:
                      </span>
                    </div>
                    <span>
                      {missingResolutionsByGroup[group._id].join(", ")}
                    </span>
                  </div>
                )}
              </div>
              {group.backdrops.desktop === backdrop._id ? (
                <div className="flex flex-row uppercase text-green-700 font-bold justify-self-center">
                  <RiCheckFill className="mr-1 -ml-2" />
                  <span className="text-xs">
                    {t("modal_publishbackdrop:live")}
                  </span>
                </div>
              ) : (
                <Checkbox
                  className="justify-self-center"
                  checked={!!publishTargets[group._id]?.desktop}
                  onChange={(e) => {
                    setPublishTargets((prev) => ({
                      ...prev,
                      [group._id]: {
                        desktop: e.target.checked,
                        lockscreen: prev[group._id]?.lockscreen || false,
                      },
                    }));
                  }}
                ></Checkbox>
              )}
              {group.backdrops.lockscreen === backdrop._id ? (
                <div className="flex flex-row uppercase text-green-700 font-bold justify-self-center">
                  <RiCheckFill className="mr-1 -ml-2" />
                  <span className="text-xs">
                    {t("modal_publishbackdrop:live")}
                  </span>
                </div>
              ) : (
                <Checkbox
                  className="justify-self-center"
                  checked={!!publishTargets[group._id]?.lockscreen}
                  onChange={(e) => {
                    setPublishTargets((prev) => ({
                      ...prev,
                      [group._id]: {
                        lockscreen: e.target.checked,
                        desktop: prev[group._id]?.desktop || false,
                      },
                    }));
                  }}
                ></Checkbox>
              )}
              <div className="col-span-3 border-b border-gray-200 dark:border-gray-600"></div>
            </React.Fragment>
          ))}
        </div>
        <div className="flex mt-4 justify-end">
          <Button
            disabled={isPublishing}
            buttonType={ButtonTypes.neutral}
            onClick={onCancel}
            className="mr-2"
          >
            {t("common_actions:cancel")}
          </Button>
          <Button
            buttonType={ButtonTypes.positive}
            loading={isPublishing}
            onClick={onPublish}
          >
            {t("common_actions:publish")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PublishExistingBackdropModal;
