import React, { FC, useReducer } from "react";

// const dayNames = [
//   "sunday",
//   "monday",
//   "tuesday",
//   "wednesday",
//   "thursday",
//   "friday",
//   "saturdy",
// ];

type CalendarState = {
  year: number;
  month: number;
  firstDayOfWeek: string;
  days: { day: number; date: number }[];
};

const CalendarReducer: (
  state: CalendarState,
  action: { type: string; payload: any }
) => CalendarState = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const firstDay = new Date();
firstDay.setDate(1);
// Passing 0 sends us to the last day of the month.
let numDays = new Date(
  firstDay.getUTCFullYear(),
  firstDay.getUTCMonth(),
  0
).getDate();
let dayCounter = firstDay.getDay();

const defaultState: CalendarState = {
  year: firstDay.getUTCFullYear(),
  month: firstDay.getUTCMonth(),
  firstDayOfWeek: "monday",
  days: new Array(numDays).fill(null).map((empty, i) => {
    let day = {
      day: dayCounter,
      date: i + 1,
    };
    if (dayCounter === 6) dayCounter = 0;
    else dayCounter++;
    return day;
  }),
};

const Calendar: FC<{}> = () => {
  const [state /* dispatch */] = useReducer(CalendarReducer, defaultState);

  return (
    <div className="grid grid-cols-7 w-full">
      {state.days.map((day) => (
        <div
          style={{
            gridColumn: day.day,
          }}
        >
          {day.date}
        </div>
      ))}
    </div>
  );
};

export default Calendar;
