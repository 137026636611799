import { FC } from "react";

import { AiOutlineLoading } from "react-icons/ai";

import classNames from "classnames";

const LoadingSpinner: FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-center h-full w-full",
        className
      )}
    >
      <AiOutlineLoading className="animate-spin" />
    </div>
  );
};

export default LoadingSpinner;
