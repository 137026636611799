import { useEffect } from "react";

const useBeforeUnload: (options: { when: boolean; message: string }) => void =
  ({ when, message }) => {
    useEffect(() => {
      const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = message;
        return message;
      };

      if (when) {
        window.addEventListener("beforeunload", handleBeforeUnload);
      }

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    });
  };

export default useBeforeUnload;
