import React, { FC, Fragment, useEffect, useState } from "react";

import { RiInstallLine, RiUninstallLine } from "react-icons/ri";

import classNames from "classnames";

import { getClientSecret } from "../api/accounts";
import {
  getInstallers,
  Installer,
  semVerToString,
  semVerToValue,
} from "../api/installers";

import useSession from "../Hooks/useSession";

import Button, { ButtonTypes } from "../Components/Button";
import LoadingSpinner from "../Components/LoadingSpinner";
import UploadAppsModal from "../Components/UploadAppsModal";

const AppsPage: FC<{}> = () => {
  useEffect(() => {});

  const { session } = useSession();
  const isAdmin = session?.account.name === "Pixelnebula";
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [installers, setInstallers] = useState<Installer[] | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [clientSecretLoading, setClientSecretLoading] =
    useState<boolean>(false);

  const [loadingInstallers, setLoadingInstallers] = useState<boolean>(true);

  useEffect(() => {
    if (!showUploadModal) {
      setLoadingInstallers(true);
      getInstallers().then((installers) => {
        setInstallers(installers);
        setLoadingInstallers(false);
      });
    }
  }, [showUploadModal]);

  const revealClientSecret = async () => {
    setClientSecretLoading(true);
    try {
      const secret = await getClientSecret();
      setClientSecret(secret);
    } catch (e) {
      // TODO: error handling.
    } finally {
      setClientSecretLoading(false);
    }
  };

  const platforms: string[] = [];
  const architectures: string[] = [];
  installers?.forEach((installer) => {
    if (!platforms.includes(installer.platform)) {
      platforms.push(installer.platform);
    }
    if (!architectures.includes(installer.arch)) {
      architectures.push(installer.arch);
    }
  });

  // TODO: i18n.

  return (
    <div className="p-6">
      {isAdmin && showUploadModal && (
        <UploadAppsModal
          onCancel={() => {
            setShowUploadModal(false);
          }}
          onSuccess={() => {
            setShowUploadModal(false);
          }}
        ></UploadAppsModal>
      )}
      <div className="flex flex-row max-w-4xl">
        <div className="flex flex-col pr-4 flex-1">
          <h2 className="text-lg font-bold">Client apps</h2>
          <p>
            This page contains the download links and credentials for client
            apps that should be installed on your organisation's workstations to
            enable Backdrops functionality
          </p>
          <p className="mt-4">
            For a full installation guide please see our help center or our PDF
            guide
          </p>
          <h3 className="mt-4 font-bold">Client credentials</h3>
          <pre
            className="grid gap-2 mt-4 font-mono text-sm px-2 py-1 rounded-sm border text-white bg-gray-600 border-gray-500 dark:bg-gray-800 dark:border-gray-700"
            style={{ gridTemplateColumns: "repeat(2, min-content)" }}
          >
            <span className="font-bold justify-self-end">Client Id:</span>{" "}
            <span>{session?.account.client?.id}</span>
            <span className="font-bold justify-self-end">
              Client Secret:
            </span>{" "}
            <span>
              {clientSecret ? (
                clientSecret
              ) : (
                <Button
                  className="text-xs -mt-2"
                  loading={clientSecretLoading}
                  onClick={revealClientSecret}
                >
                  Reveal
                </Button>
              )}
            </span>
          </pre>
        </div>
        <div className="flex flex-col bg-gray-600 border border-gray-500 rounded-sm text-white mt-6 px-4 py-2">
          <h2 className="font-bold text-lg">Download installers</h2>
          {loadingInstallers ? (
            <LoadingSpinner />
          ) : (
            <div
              className="grid px-4 py-2 flex-1"
              style={{
                gridTemplateColumns: `4rem repeat(${platforms.length}, auto)`,
                gridTemplateRows: `2rem repeat(${architectures.length}, auto)`,
              }}
            >
              {/* Empty top left header cell */}
              <div></div>
              {/* Platform names */}
              {platforms.map((platform) => (
                <div className="font-bold" key={`${platform}-header`}>
                  {platform}
                </div>
              ))}
              {architectures.map((arch) => (
                <Fragment key={`${arch}-row`}>
                  {/* Architecture name */}
                  <div className="font-bold">{arch}</div>
                  {platforms.map((platform) => {
                    const matchingInstallers = installers!
                      .filter(
                        (ins) => ins.platform === platform && ins.arch === arch
                      )
                      .sort(
                        (a, b) =>
                          semVerToValue(b.semVer) - semVerToValue(a.semVer)
                      );

                    return (
                      <div key={`${platform}-${arch}-link`}>
                        {matchingInstallers.length === 0 ? (
                          <span className="text-center block w-full">-</span>
                        ) : (
                          <div className="flex flex-col">
                            {matchingInstallers.map((installer, i) => (
                              <a
                                className={classNames(
                                  "flex items-center",
                                  i && "opacity-50"
                                )}
                                href={`installers/${installer._id}`}
                                download={`pixelnebula_backdrops_${
                                  installer.platform
                                }-${installer.arch}-${semVerToString(
                                  installer.semVer
                                )}.${installer.extension}`}
                                key={`${installer._id}`}
                              >
                                <RiInstallLine className="mr-1" />{" "}
                                <span className="underline">
                                  {semVerToString(installer.semVer)}
                                </span>
                              </a>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Fragment>
              ))}
            </div>
          )}
          {isAdmin && (
            <Button
              type="button"
              className="flex flex-row items-center self-center"
              buttonType={ButtonTypes.positive}
              onClick={() => {
                setShowUploadModal(true);
              }}
            >
              <RiUninstallLine className="mr-1" />
              Upload installer
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppsPage;
