import { FC, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { createUser, updateUser, User } from "../api/users";

import Button, { ButtonTypes } from "../Components/Button";
import Callout, { CalloutTypes } from "../Components/Callout";

import FormFieldError from "./FormFieldError";

type EditUserFormData = {
  name: string;
  email: string;
  // This is here so we can add server errors and RHF types line up.
  server: string;
};

const CreateEditUserForm: FC<{
  user?: User | null;
  onCancel: () => void;
  onSuccess: (user: User) => void;
}> = ({ user, onCancel, onSuccess }) => {
  const { register, handleSubmit, formState, setError, clearErrors, errors } =
    useForm<EditUserFormData>({
      defaultValues: !!user ? { name: user.name, email: user.email } : {},
    });
  const { t } = useTranslation([
    "common_user",
    "common_objects",
    "common_actions",
    "page_users",
    "server_messages",
  ]);

  const [loading, setLoading] = useState<boolean>(false);

  const { dirtyFields } = formState;

  const isCreatingUser = !user;

  const onSubmit = handleSubmit(async (data) => {
    if (loading) return;
    setLoading(true);
    try {
      if (!!user) {
        // Check if ditry fields have actually changed
        let updatedUserFields: Partial<User> = {};
        let hasChange = false;
        Object.keys(dirtyFields).forEach((dirtyField) => {
          // not sure if this is ever false, but it's a boolean.
          if (
            user[dirtyField as keyof User] !==
            data[dirtyField as keyof EditUserFormData]
          ) {
            // @ts-ignore
            updatedUserFields[dirtyField as keyof User] =
              data[dirtyField as keyof EditUserFormData];
            hasChange = true;
          }
        });
        if (!hasChange) {
          setLoading(false);
          onCancel();
          return;
        }
        const updatedUser = await updateUser(user._id, updatedUserFields);
        setLoading(false);
        onSuccess(updatedUser);
      } else {
        const createdUser = await createUser(data);
        setLoading(false);
        onSuccess(createdUser);
      }
    } catch (error) {
      setError("server", {
        message: t("server_messages:error", {
          context: error.message,
          details: error.tid,
        }),
      });
      setLoading(false);
    }
  });

  return (
    <form
      className="flex flex-col p-2 w-96"
      style={{ minWidth: "20rem" }}
      name="editUser"
      onSubmit={onSubmit}
      noValidate
    >
      <h2 className="font-bold text-lg mb-2">
        {isCreatingUser ? t("page_users:addUser") : t("page_users:editUser")}
      </h2>
      {isCreatingUser && (
        <p className="mb-2">{t("page_users:addUserExplainer")}</p>
      )}
      <label htmlFor="userform-name">{t("common_user:name")}</label>
      <input
        name="name"
        type="text"
        ref={register({ required: true })}
        id="userform-name"
        onChange={() => clearErrors("server")}
        className={classNames(
          "input px-2 py-1 mb-2",
          errors.name && "input-error"
        )}
      />
      <FormFieldError
        condition={!!errors.name}
        i18nOptions={{
          context: "requiredField",
        }}
      />
      <label htmlFor="userform-email">{t("common_user:email")}</label>
      <input
        name="email"
        type="email"
        ref={register({ required: isCreatingUser })}
        id="userform-email"
        onChange={() => clearErrors("server")}
        className={classNames(
          "input px-2 py-1 mb-2 disabled:opacity-40",
          errors.email && "input-error"
        )}
        disabled={!isCreatingUser}
      />
      <FormFieldError
        condition={!!errors.email}
        i18nOptions={{
          context: "requiredField",
        }}
      />
      {!isCreatingUser && (
        <Callout type={CalloutTypes.info}>
          <span>{t("page_users:contactSupportForEmailChange")}</span>
        </Callout>
      )}
      {!!errors.server && (
        <Callout
          className="mt-4"
          type={CalloutTypes.error}
          content={errors.server.message}
        />
      )}
      <div className="flex flex-row justify-end mt-4">
        <Button
          type="button"
          buttonType={ButtonTypes.neutral}
          onClick={onCancel}
          disabled={loading}
          className="mr-2"
        >
          {t("common_actions:cancel")}
        </Button>
        <Button
          type="submit"
          loading={loading}
          disabled={!!Object.keys(errors).length}
          buttonType={ButtonTypes.positive}
        >
          {!isCreatingUser
            ? t("common_actions:saveChanges")
            : t("common_actions:create")}
        </Button>
      </div>
    </form>
  );
};

export default CreateEditUserForm;
