import axios from "axios";

import { Backdrop } from "./backdrops";
import { processApiError } from "./utility-functions";

type UploadImageResponseType = {
  data: Backdrop;
};

export const uploadImage = async (
  id: string,
  resolution: string,
  data: Blob,
  progressCallback: (progress: number) => void
) => {
  const formData = new FormData();
  formData.append("image", data);
  try {
    const result = await axios.post<UploadImageResponseType>(
      `/api/backdrops/${id}/images/${resolution}`,
      formData,
      {
        onUploadProgress: (progress: ProgressEvent) => {
          progressCallback(progress.loaded / progress.total);
        },
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    return result.data.data;
  } catch (e) {
    throw processApiError(e);
  }
};
