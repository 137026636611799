import { FC, useState } from "react";

import { useTranslation } from "react-i18next";
import Modal from "react-modal";

import Button, { ButtonTypes } from "./Button";
import Callout, { CalloutTypes } from "./Callout";
import { centeredAndSizedByContent } from "./modalCustomStyles";

const ConfirmationModal: FC<{
  isOpen: boolean;
  onConfirm: (() => void) | (() => Promise<void>);
  onCancel: (() => void) | (() => Promise<void>);
  heading?: string;
  message: string;
  isIrreversible?: boolean;
  buttonType?: ButtonTypes;
}> = ({
  isOpen,
  heading,
  message,
  isIrreversible,
  onConfirm,
  onCancel,
  buttonType,
}) => {
  const { t } = useTranslation(["modal_confirmation", "common_actions"]);
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  return (
    <Modal isOpen={isOpen} style={centeredAndSizedByContent}>
      <div className="flex flex-col w-96">
        <h3 className="font-bold text-lg mb-2">{heading}</h3>
        <p>{message}</p>
        {isIrreversible && (
          <Callout type={CalloutTypes.warning} className="mt-2">
            {t("modal_confirmation:irreversibleWarning")}
          </Callout>
        )}
        <div className="flex flex-row mt-4">
          <Button
            onClick={async () => {
              setCancelLoading(true);
              await onCancel();
              setCancelLoading(false);
            }}
            loading={cancelLoading}
            className="button flex-1 mr-1"
          >
            {t("common_actions:cancel")}
          </Button>
          <Button
            buttonType={buttonType}
            className="flex-1 ml-1"
            loading={confirmLoading}
            onClick={async () => {
              setConfirmLoading(true);
              await onConfirm();
              setConfirmLoading(false);
            }}
          >
            {t("common_actions:confirm")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
