import { FC, useRef, useState } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

import { logout, verifyOTP } from "../api/auth";

import useServerErrorToast from "../Hooks/useServerErrorToast";
import useSession from "../Hooks/useSession";

import Button from "../Components/Button";

const OTPChallengeForm: FC<{}> = () => {
  const [OTPCode, setOTPCode] = useState<string>("");
  const [verifyingCode, setVerifyingCode] = useState<boolean>(false);
  const { clearSession, updateSession } = useSession();
  const { t } = useTranslation([
    "common_forms",
    "common_user",
    "server_messages",
    "page_otp_challenge",
  ]);
  const { addServerErrorToast } = useServerErrorToast();
  const recaptchaRef = useRef<ReCAPTCHA>();

  return (
    <div className="h-full flex flex-col items-center justify-center">
      <form
        noValidate
        className="flex flex-col w-72 p-4 border border-black dark:border-gray-600 rounded-sm"
      >
        <h1 className="font-bold mb-2">
          {t("page_otp_challenge:otpRequiredHeader")}
        </h1>
        <label htmlFor="otp-code">{t("common_user:OTPCode")}</label>
        <input
          type="text"
          inputMode="numeric"
          autoComplete="none"
          className="input mb-4 px-2 py-1"
          id="otp-code"
          name="otpCode"
          required
          tabIndex={1}
          value={OTPCode || ""}
          onChange={(e) => setOTPCode(e.target.value)}
        />

        <Button
          loading={verifyingCode}
          disabled={OTPCode.length !== 6}
          onClick={async (e) => {
            e.preventDefault();
            setVerifyingCode(true);
            try {
              const recaptcha = await recaptchaRef.current!.executeAsync();
              const newSession = await verifyOTP({
                token: OTPCode,
                recaptcha: recaptcha!,
              });
              updateSession(newSession);
            } catch (e) {
              addServerErrorToast(e);
              if (e.code === 401) {
                clearSession();
              } else {
                setOTPCode("");
                recaptchaRef.current!.reset();
              }
              setVerifyingCode(false);
            }
          }}
        >
          {t("common_forms:submit")}
        </Button>
        <button
          className="text-sm mt-4 -mb-2"
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            await logout();
            clearSession();
          }}
        >
          {t("page_otp_challenge:returnToLogin")}
        </button>
        <ReCAPTCHA
          sitekey="6LepuFAaAAAAAMNbE4TSjxjwgcEcetp1g6d9yf2L"
          size="invisible"
          badge="bottomleft"
          // @ts-ignore
          ref={recaptchaRef}
        />
      </form>
    </div>
  );
};

export default OTPChallengeForm;
