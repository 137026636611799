import { useEffect, useState } from "react";

import { getSession, Session } from "../api/auth";
import { ApiError } from "../api/utility-functions";

import { clearSessionData } from "../ServiceWorker/utils";

let cachedSession: Session | null | false = false;
let requestInFlight = false;

export const clearSession = () => {
  cachedSession = null;
  document.dispatchEvent(new CustomEvent("pxn-session-changed"));
  clearSessionData();
};

export const updateSession = (newSession: Session | null) => {
  cachedSession = newSession;
  document.dispatchEvent(new CustomEvent("pxn-session-changed"));
};

const useSession: (watch?: boolean) => {
  loading: boolean;
  session: Session | null;
  clearSession: () => void;
  updateSession: (newSession: Session) => void;
} = (watch) => {
  const [session, setSession] = useState<Session | null>(cachedSession || null);
  const [loading, setLoading] = useState<boolean>(cachedSession === false);

  useEffect(() => {
    let sessionChangeListener: () => void = () => {
      setSession(cachedSession || null);
      setLoading(false);
    };
    if (watch) {
      document.addEventListener("pxn-session-changed", sessionChangeListener, {
        passive: true,
      });
    }

    if (cachedSession !== false) {
      setSession(cachedSession);
      setLoading(false);
      return;
    }

    const requestSession = async () => {
      try {
        requestInFlight = true;
        let session = await getSession();
        // no need to set the session as this will be done by the event listener.
        updateSession(session);
      } catch (e) {
        const apiError: ApiError = e;
        if (apiError.code === 401) {
          updateSession(null);
        } else {
          // TODO: toast probably.
        }
      } finally {
        requestInFlight = false;
        setLoading(false);
      }
    };

    if (!requestInFlight) {
      requestSession();
    } else if (!watch) {
      document.addEventListener("pxn-session-changed", sessionChangeListener, {
        once: true,
        passive: true,
      });
    }

    return () => {
      if (watch) {
        document.removeEventListener(
          "pxn-session-changed",
          sessionChangeListener
        );
      }
    };
  }, [watch]);

  return { loading, session, updateSession, clearSession };
};

export default useSession;
