import React, {
  Dispatch,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";

import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { SketchPicker } from "react-color";
import { useHotkeys } from "react-hotkeys-hook";
import { MdArrowBack } from "react-icons/md";
import {
  RiCloseFill,
  RiImageAddFill,
  RiZoomInFill,
  RiZoomOutFill,
} from "react-icons/ri";
import { Prompt, useHistory } from "react-router-dom";
import { Popover, ArrowContainer } from "react-tiny-popover";

import classNames from "classnames";

import useBeforeUnload from "./Hooks/useBeforeUnload";
import useTemporaryCssRule from "./Hooks/useTemporaryCssRule";

import Button, { ButtonTypes } from "./Components/Button";

import BackdropMockup, { TranslationTypes } from "./BackdropMockup";
import { CanvasObjectTypes, HandlePositions } from "./CanvasObject";
import ImagePositionSelect from "./ImagePositionSelect";
import ImageSelect from "./ImageSelect";
import {
  BackdropEditorActionTypes as ActionTypes,
  BackdropEditorActions as Actions,
  backdropEditorReducer,
  backdropEditorInitialState,
  BackdropEditorActionTypes,
  BackdropEditorState,
} from "./backdropEditorReducer";

// Used to make memoized onPan and onZoom handler.
const translationDispatcher = (
  dispatch: Dispatch<Actions>,
  translationName: "PAN" | "ZOOM",
  { type, amount }: { type: TranslationTypes; amount: any }
) => {
  if (type === TranslationTypes.absolute) {
    dispatch({
      type:
        translationName === "PAN" ? ActionTypes.SET_PAN : ActionTypes.SET_ZOOM,
      payload: amount,
    });
  } else {
    dispatch({
      type: translationName === "PAN" ? ActionTypes.PAN : ActionTypes.ZOOM,
      payload: amount,
    });
  }
};

function greatestCommmonMultiple(a: number, b: number): number {
  return b ? greatestCommmonMultiple(b, a % b) : a;
}

const getHumanReadableAspectRatio: (resolution: string) => string = (
  resolution
) => {
  const [xStr, yStr] = resolution.split("x");
  const x = parseInt(xStr);
  const y = parseInt(yStr);

  const gcm = greatestCommmonMultiple(x, y);
  return `${x / gcm}:${y / gcm}`;
};

export enum TransformTypes {
  translate,
  scale,
}
const BackdropEditor: FC<{
  resolutions?: string[];
  onPublish?: (data: {
    backdropsData: BackdropEditorState["backdrops"];
    objectLibrary: BackdropEditorState["objectLibrary"];
  }) => void;
  onSave?: (data: {
    backdropsData: BackdropEditorState["backdrops"];
    objectLibrary: BackdropEditorState["objectLibrary"];
  }) => void;
}> = ({ resolutions, onPublish, onSave }) => {
  const [state, dispatch] = useReducer(
    backdropEditorReducer,
    backdropEditorInitialState
  );

  useTemporaryCssRule(`
    .pxn-fc-widget-customisation {
      transform: translateY(-2.25em);
    }
  `);

  // Update the state when the provided resolutions change
  useEffect(() => {
    dispatch({
      type: BackdropEditorActionTypes.SET_RESOLUTIONS,
      payload: resolutions || [],
    });
  }, [resolutions]);

  const [showZoomPopup, setShowZoomPopup] = useState<boolean>(false);
  const clearShowZoomPopup = useRef<number | null>(null);

  useBeforeUnload({ when: true, message: "Changes will be lost" });

  const onPan = useMemo(
    () => translationDispatcher.bind(null, dispatch, "PAN"),
    []
  );
  const onZoom = useMemo(
    () => (payload: { type: TranslationTypes; amount: any }) => {
      translationDispatcher(dispatch, "ZOOM", payload);
      if (clearShowZoomPopup.current !== null) {
        window.clearTimeout(clearShowZoomPopup.current);
      }
      clearShowZoomPopup.current = window.setTimeout(() => {
        clearShowZoomPopup.current = null;
        setShowZoomPopup(false);
      }, 2500);
      setShowZoomPopup(true);
    },
    []
  );
  const onObjectSelected = useMemo(
    () => (objectId: string | null) =>
      dispatch({ type: ActionTypes.SELECT_OBJECT, payload: objectId }),
    []
  );
  const onSetZoomRange = useCallback(
    (zoomRange: { min: number; max: number }) => {
      dispatch({ type: ActionTypes.SET_ZOOM_RANGE, payload: zoomRange });
    },
    []
  );

  const [colorPopoverOpen, setColorPopoverOpen] = useState<boolean>(false);
  useHotkeys("esc", () => setColorPopoverOpen(false));
  const history = useHistory();

  const onObjectTransform = useMemo(
    () =>
      (
        id: string,
        transformType: TransformTypes,
        amount: { dx: number; dy: number },
        details?: {
          handlePosition: HandlePositions | null;
          constrainAspectRatio: boolean;
        }
      ) => {
        switch (transformType) {
          case TransformTypes.translate:
            dispatch({
              type: ActionTypes.TRANSLATE_OBJECT,
              payload: { id, amount },
            });
            break;
          case TransformTypes.scale:
            dispatch({
              type: ActionTypes.SCALE_OBJECT,
              payload: { id, amount, details: details! },
            });
            break;
          default:
            throw new Error(`Unknown transform type: ${transformType}`);
        }
      },
    []
  );

  const selectedBackdrop = state.backdrops.find(
    (ls) => ls.resolution === state.selectedResolution
  );

  return (
    <div className="h-full grid mainGrid">
      <Prompt
        when={state.hasChangesSinceSave}
        message={"All edits will be lost"}
      />
      {selectedBackdrop ? (
        <BackdropMockup
          className="preview bg-black"
          objectLibrary={state.objectLibrary}
          objects={selectedBackdrop?.objects}
          backgroundColor={selectedBackdrop?.backgroundColor || "#000"}
          zoom={state.preview.zoom.current}
          pan={state.preview.pan}
          onPan={onPan}
          onZoom={onZoom}
          onSetZoomRange={onSetZoomRange}
          onObjectSelected={onObjectSelected}
          onObjectTransform={onObjectTransform}
          resolution={selectedBackdrop?.resolution}
        />
      ) : (
        // TODO: put something a bit nicer here.
        <div></div>
      )}
      <div className="preview relative pointer-events-none ">
        <div className="absolute bottom-0 right-0 flex flex-row">
          <div
            className={classNames([
              "bg-gray-500 flex flex-row items-center justify-center",
              "text-center w-14 mr-2 rounded-sm text-white",
              "transition-opacity",
              showZoomPopup ? "opacity-100" : "opacity-0",
            ])}
          >
            {Math.round((state.preview.zoom.current || 1) * 100)}%
          </div>

          <div className="bg-gray-700 px-4 py-2 zoom-slider rounded-tl-sm flex flex-row">
            <RiZoomOutFill className="text-white mr-1 -ml-2" />
            <input
              type="range"
              className="pointer-events-auto"
              id="zoomSlider"
              name="zoom"
              max={state.preview.zoom.max}
              min={state.preview.zoom.min}
              step="0.01"
              value={state.preview.zoom.current || 0}
              onChange={(e) => {
                onZoom({
                  type: TranslationTypes.absolute,
                  amount: parseFloat(e.target.value),
                });
              }}
            />
            <RiZoomInFill className="text-white -mr-2 ml-1" />
          </div>
        </div>
      </div>

      <button
        type="button"
        className="fixed top-2 left-0 px-2 py-2 bg-gray-900 text-white flex flex-row"
        onClick={() => {
          history.goBack();
        }}
      >
        <MdArrowBack className="mr-2" />
        <span className="leading-4">Back</span>
      </button>

      <div className="tools bg-gray-900 p-4 text-white text-sm overflow-y-scroll">
        <div
          className="grid grid-cols-2 gap-x-2 gap-y-2 h-full max-h-full"
          style={{
            gridTemplateRows: "auto auto auto 1fr",
          }}
        >
          <label htmlFor="resolution">Resolution</label>
          <select
            className="py-1 input"
            name="resolution"
            onChange={(e) => {
              dispatch({
                type: ActionTypes.SELECT_RESOLUTION,
                payload: e.target.value,
              });
            }}
            value={state.selectedResolution}
          >
            {state.backdrops.map((bd) => (
              <option key={bd.resolution} value={bd.resolution}>
                {bd.resolution} ({getHumanReadableAspectRatio(bd.resolution)})
              </option>
            ))}
          </select>
          <label>Background colour</label>
          <Popover
            containerClassName="text-black"
            positions={["bottom", "top"]}
            padding={2}
            isOpen={colorPopoverOpen}
            onClickOutside={() => setColorPopoverOpen(false)}
            content={({ position, childRect, popoverRect }) => (
              <ArrowContainer
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                arrowColor={"white"}
                arrowSize={10}
                arrowStyle={{ opacity: 0.7 }}
              >
                <SketchPicker
                  color={selectedBackdrop?.backgroundColor || "#000"}
                  onChange={(color) => {
                    dispatch({
                      type: ActionTypes.SET_BACKGROUND_COLOR,
                      payload: color,
                    });
                  }}
                />
              </ArrowContainer>
            )}
          >
            <div
              className="w-4 h-4 input-border"
              style={{
                backgroundColor: selectedBackdrop?.backgroundColor || "black",
              }}
              onClick={() => setColorPopoverOpen((prev) => !prev)}
            ></div>
          </Popover>
          <div className="col-span-2 -mx-4 px-4 py-2 text-xs text-center uppercase bg-gray-800">
            <div className="flex flex-row items-center justify-between">
              <span>Images</span>
              <button
                className="flex flex-row col-span-2 items-center justify-center px-2 py-1 bg-green-900"
                disabled={!selectedBackdrop}
                onClick={() => {
                  dispatch({
                    type: ActionTypes.ADD_IMAGE,
                    payload: null,
                  });
                }}
              >
                <span className="mr-2 text-xs">Add image</span>
                <RiImageAddFill className="text-sm" />
              </button>
            </div>
          </div>
          <DragDropContext
            onDragEnd={(result) => {
              let { draggableId, source, destination } = result;
              // No destination means dropped in an invalid place.
              if (!destination || destination.index === source.index) return;
              let numItems = state.objectLibrary.filter(
                (o) => o.type === CanvasObjectTypes.Image
              ).length;
              dispatch({
                type: ActionTypes.REORDER_IMAGE,
                payload: {
                  id: draggableId,
                  oldPosition: numItems - source.index,
                  newPosition: numItems - destination.index,
                },
              });
            }}
          >
            <Droppable droppableId="image-list">
              {(provided: DroppableProvided) => {
                return (
                  <div
                    className="col-span-2 overflow-x-auto"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {state.objectLibrary
                      .filter(
                        (object) => object.type === CanvasObjectTypes.Image
                      )
                      .sort(
                        (a, b) =>
                          selectedBackdrop!.objects[b.id].position.zIndex -
                          selectedBackdrop!.objects[a.id].position.zIndex
                      )
                      .map((image, i) => (
                        <Draggable
                          draggableId={image.id}
                          index={i}
                          key={"images-" + image.id}
                        >
                          {(provided: DraggableProvided) => (
                            <div
                              className="flex flex-col col-span-2 bg-gray-900 px-4 -mx-4"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <button
                                className="text-xs flex flex-row items-center self-end p-2 pl-8 pb-1 -m-2 -mb-2 z-10"
                                onClick={() => {
                                  dispatch({
                                    type: ActionTypes.REMOVE_OBJECT,
                                    payload: image.id,
                                  });
                                }}
                              >
                                <RiCloseFill className="" />
                              </button>
                              <ImageSelect
                                onChange={(imageElement) => {
                                  // Reset to cover if we change the image.
                                  dispatch({
                                    type: ActionTypes.SET_IMAGE_DATA,
                                    payload: { id: image.id, imageElement },
                                  });
                                }}
                                name={"image-" + image.id}
                                className="py-2"
                              />
                              <ImagePositionSelect
                                name={`image-${image.id}-position`}
                                mode={
                                  selectedBackdrop?.objects[image.id]
                                    .positionMode
                                }
                                onModeChange={(newMode) => {
                                  dispatch({
                                    type: ActionTypes.SET_IMAGE_POSITION_MODE,
                                    payload: { id: image.id, mode: newMode },
                                  });
                                }}
                              />
                              <div className="border border-gray-800 border-r-0 border-l-0 border-t-0 col-span-2" />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
          {onPublish && (
            <Button
              buttonType={ButtonTypes.positive}
              className="col-span-2"
              onClick={() => {
                dispatch({
                  type: ActionTypes.MARK_CHANGES_SAVED,
                  payload: null,
                });
                onPublish!({
                  backdropsData: state.backdrops,
                  objectLibrary: state.objectLibrary,
                });
              }}
            >
              Save and publish
            </Button>
          )}
          {onSave && (
            <Button
              buttonType={ButtonTypes.positive}
              className="col-span-2"
              onClick={() => {
                onPublish!({
                  backdropsData: state.backdrops,
                  objectLibrary: state.objectLibrary,
                });
              }}
            >
              Save without publishing
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BackdropEditor;
